import React from "react";
import { Audio } from "react-loader-spinner";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import UploadBar from "../post-input/UploadBar";
import "./Loading.css";

const Loading = ({ className, isWaitingForAuth, isUploadingVideo, progress }) => {
  return (
    <StyledLoading
      className={className}
      style={className === "mini" ? { width: "80%" } : null}
      data-testid="loading"
    >
      <Audio color="grey" ariaLabel="loading" />
      {isWaitingForAuth && <h2>Waiting for authentication</h2>}
      {isUploadingVideo && (
        <UploadBar
          progress={progress}
          style={{
            minWidth: 400,
          }}
        />
      )}
    </StyledLoading>
  );
};
Loading.propTypes = {
  className: PropTypes.string,
  isWaitingForAuth: PropTypes.any,
  isUploadingVideo: PropTypes.bool,
  progress: PropTypes.number,
};
const StyledLoading = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  background: #171717;
  background: rgba(23, 23, 23, 1);
  background: hsla(0, 0%, 9%, 1);
  position: relative;
`;
export default Loading;
