import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { Modal } from "react-bootstrap";

const StudioCard = ({ images }) => {
  const [remainingCount, setRemainingCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (images.length > 5) setRemainingCount(images.length - 5);
  }, []);
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {images.map((ele, index) => (
          <ModalImage src={ele} key={index} />
        ))}
      </Modal>
      {images.length ? (
        <Container>
          <MainImageContainer>
            <MainImage
              onClick={() => setShowModal(true)}
              src={images.length ? images[0] : null}
              alt="Main image"
            />
          </MainImageContainer>
          <OtherImagesContainer>
            {images.slice(1, 4).length
              ? images.slice(1, 4).map((ele) => (
                  <SmallerImageContainer key={Date.now()}>
                    <SmallerImage onClick={() => setShowModal(true)} src={ele} alt="studio image" />
                  </SmallerImageContainer>
                ))
              : null}
            {remainingCount >= 0 ? (
              <LastImageContainer>
                <LastImage
                  onClick={() => setShowModal(true)}
                  src={images[4] !== undefined ? images[4] : null}
                />
                {remainingCount > 0 ? (
                  <RemainingContainer>
                    <Remaining>{remainingCount} More...</Remaining>
                  </RemainingContainer>
                ) : null}

                <ButtonContainer>
                  <Button onClick={() => setShowModal(true)}>View all</Button>
                </ButtonContainer>
              </LastImageContainer>
            ) : null}
          </OtherImagesContainer>
        </Container>
      ) : (
        <TextContainer>
          <Text>No studio images</Text>
        </TextContainer>
      )}
    </>
  );
};

StudioCard.propTypes = {
  images: PropTypes.array,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  margin-left: 10%;
  display: flex;
  justify-content: center;
`;

const ModalImage = styled.img`
  height: 60vh;
`;
const MainImageContainer = styled.div`
  flex: 2;
  border-radius: 1rem;
`;
const MainImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  cursor: pointer;
`;
const OtherImagesContainer = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
const SmallerImageContainer = styled.div`
  width: 40%;
  height: 48%;
  border-radius: 1rem;
`;
const SmallerImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  cursor: pointer;
`;
const LastImageContainer = styled.div`
  width: 40%;
  height: 48%;
  border-radius: 1rem;
  position: relative;
`;
const LastImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  cursor: pointer;
`;
const RemainingContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
`;
const Remaining = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: white;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 5%;
  left: 30%;
`;

const Button = styled.button`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  border-radius: 10px;
  padding: 5px 15px;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  width: max-content;
  opacity: 0.9;
`;
const TextContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: center;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 30px;
`;
export default StudioCard;
