// Libraries
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
// Components
import Modal from "react-bootstrap/Modal";
import DropBox from "./DropBox";
import PostPreview from "./PostPreview";

const PostModal = ({ show, onHide, setShowModal }) => {
    const [file, setFile] = useState({
        src: "",
    });
    const [cover, setCover] = useState({
        src: "",
    });

    const onHideHandler = () => {
        onHide();
    };

    return (
        <StyledModal
            show={show}
            onHide={onHideHandler}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Header style={{ backgroundColor: "#171717", color: "white" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create New Post
                </Modal.Title>
            </Header>
            <Modal.Body style={{ backgroundColor: "#171717" }}>
                {!(file.src.length && cover.src.length) ? (
                    <DropBox setFile={setFile} setCover={setCover} />
                ) : (
                    <PostPreview
                        file={file}
                        cover={cover}
                        setShowModal={setShowModal}
                        onHide={onHide}
                        setFile={setFile}
                        setCover={setCover}
                    ></PostPreview>
                )}
            </Modal.Body>
        </StyledModal>
    );
};
PostModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    setShowModal: PropTypes.func,
};

const StyledModal = styled(Modal)``;

const Header = styled(Modal.Header)`
    justify-content: center;
    border: none;
`;
export default PostModal;
