// Libraries

import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Search.css";
import { useDispatch, useSelector } from "react-redux";
import { setSearchList, setSearchResult } from "../../redux/services/search/searchSlice";
import { useSearchArtistMutation } from "../../redux/services/search/searchApi";

import { useGenresMutation, useSkillsMutation } from "../../redux/services/utils/utilsApi";

import { useCreateRoomMutation, useGetRoomsMutation } from "../../redux/services/chat/chatApi";
import { selectUser } from "../../redux/services/user/userSlice";
import { setUserChats } from "../../redux/services/chat/chatSlice";
// Assets
const SearchIconLogo = `/icons/navbar/SearchIcon.svg`;
const SearchBoxStyles = {
  borderTop: "1px solid #737b8f",
  borderRight: "1px solid #737b8f",
  borderLeft: "1px solid #737b8f",
  borderRadius: "1rem 1rem 0rem 0rem",
};

const Search = ({ placeholder, messaging, onHide, noSuggestions = false, isForChat = false }) => {
  const [createRoom] = useCreateRoomMutation();
  const [getRooms] = useGetRoomsMutation();
  const [getGenres] = useGenresMutation();
  const [getSkills] = useSkillsMutation();

  const { user } = useSelector(selectUser);

  const [isVisible, setIsVisible] = useState(true);

  const [genres, setGenres] = useState([]);
  const [skills, setSkills] = useState([]);

  const navigate = useNavigate();
  const nameRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [filter] = useState("");
  const data = useSelector((state) => state.search.allArtists);
  const searchList = useSelector((state) => state.search.searchList);
  const chats = useSelector((state) => state.chat.userChats);

  const [searchApi] = useSearchArtistMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (search) {
      searchApi({ searchQuery: search, filter }).then((res) => {
        dispatch(setSearchResult(res.data));
        if (messaging) {
          return null;
        } else {
          navigate("/search-list");
        }
      });
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    if (value.length <= 5) {
      const filteredList = data?.filter((d) => {
        const fullName = `${d?.firstName} ${d?.lastName}`;
        return fullName.toLowerCase().includes(value.toLowerCase());
      });

      const skillList = skills?.filter((d) => {
        return d.toLowerCase().includes(value.toLowerCase());
      });

      const genresList = genres?.filter((d) => {
        return d.toLowerCase().includes(value.toLowerCase());
      });

      const combinedList = filteredList.concat(skillList).concat(genresList);

      let filteredCombinedList = combinedList;

      if (isForChat) {
        filteredCombinedList = combinedList.filter((item) => {
          return !chats.some((chat) => chat?.roomUsers.some((user) => user?.id === item?.user?.id));
        });
      }

      dispatch(setSearchList(filteredCombinedList));
      console.log(filteredCombinedList);
      console.log(chats);
    }
  };

  useEffect(() => {
    getGenres().then((res) => {
      setGenres(res.data.genres);
    });
    getSkills().then((res) => {
      setSkills(res.data.skills);
    });
  }, []);

  const handleClick = (input) => {
    const searchName = input.firstName + " " + input.lastName;
    if (search) {
      setSearch(nameRef.current.value);
      searchApi({
        searchQuery: searchName?.length ? searchName : search,
      }).then((res) => {
        dispatch(setSearchResult(res.data));
        if (messaging) {
          setIsVisible(false);
          createRoom({ OtherUser: input.user.id });
          setTimeout(() => {
            getRooms({ userId: user.pk }).then((rooms) => {
              dispatch(setUserChats(rooms.data["User Rooms"]));
              onHide();
            });
          }, 1000);
        } else {
          navigate("/search-list");
        }
      });
    }
  };

  return (
    <>
      <div className="main-search-container">
        <SearchBox
          style={search ? SearchBoxStyles : null}
          onSubmit={handleSubmit}
          as={motion.form}
          whileFocus={{ scale: 1.05 }}
          id="search-box"
        >
          <div className="search-bar">
            <SearchBar
              type="search"
              placeholder={placeholder ? t(placeholder) : `${t("search")}...`}
              value={search}
              onChange={handleChange}
              ref={nameRef}
            />

            <SearchIcon
              onClick={handleClick}
              as={motion.div}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.3 }}
            >
              <img src={SearchIconLogo} alt="Search Icon" />
            </SearchIcon>
          </div>
        </SearchBox>
        {isVisible && !noSuggestions && (
          <div className="search-container">
            {search && searchList !== undefined ? (
              <div className="dropdown-list">
                {searchList.length > 5
                  ? searchList.slice(0, 5).map((s) => (
                      <div key={s.user} onClick={() => handleClick(s)} className="dropdown-item">
                        <h2>{`${s.firstName || s} ${s.lastName || "(UserType)"}`} ...</h2>
                        <hr />
                      </div>
                    ))
                  : searchList.map((s) => (
                      <div key={s.user} onClick={() => handleClick(s)} className="dropdown-item">
                        <h2>{`${s.firstName || s} ${s.lastName || ""}`}</h2>
                        <hr />
                      </div>
                    ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};
const SearchBox = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  border: 1px solid #737b8f;
  border-radius: 1rem;
  background-color: transparent;
  padding-left: 1vw;
  min-height: 50px;
  min-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  border: 1px solid #737b8f;
  border-radius: 1rem;
  background-color: transparent;
  padding-left: 1vw;
  min-height: 50px;
  min-width: 350px;
`;
const SearchBar = styled.input`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.color};
  outline: none;
  @media (min-width: 280px) {
    width: 90%;
  }
  @media (min-width: 641px) {
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: 95%;
  }
`;
const SearchIcon = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 5%;
`;

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  messaging: PropTypes.bool,
  onHide: PropTypes.func,
  noSuggestions: PropTypes.bool,
  isForChat: PropTypes.bool,
};

export default Search;
