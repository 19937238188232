import React from "react";
import styled from "styled-components";
import NewChatModal from "./NewChatModal";
import { PropTypes } from "prop-types";

const MessagesHeading = ({ setActiveIndex }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Box style={{ paddingBottom: 25 }}>
        <Heading>Messages</Heading>
      </Box>
      <Box
        style={{ flexDirection: "row-reverse", paddingBottom: 15 }}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Text>CHAT +</Text>
      </Box>
      <NewChatModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        setActiveIndex={setActiveIndex}
      />
    </>
  );
};

MessagesHeading.propTypes = {
  setActiveIndex: PropTypes.func,
};

export default MessagesHeading;

const Heading = styled.h1`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

const Text = styled.button`
  font-size: 14px;
  text-transform: uppercase;
  padding: 6px 16px;
  background-color: transparent;
  color: #fff;
  border: none;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
