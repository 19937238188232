import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import BookingCardOwner from "../../../../components/cards/booking/BookingCardOwner";
import { useDeleteBookingMutation } from "../../../../redux/services/booking/bookingApi";

const StudioBookings = () => {
    const mybookings = useSelector((state) => state.user.userStudioBookings);
    const [deleteBooking] = useDeleteBookingMutation();
    const handleDelete = (id) => {
        deleteBooking({
            bookingId: id,
        }).then((res) => {
            if (res.data.Responce) {
                alert("Successfully deleted");
            }
        });
    };
    return (
        <StudiosContainer>
            {mybookings.length ? (
                mybookings.map((booking) => (
                    <BookingCardOwner
                        key={booking.id}
                        start={booking.start}
                        end={booking.end}
                        studioRoom={booking.studioRoom}
                        onClick={() => handleDelete(booking.id)}
                    />
                ))
            ) : (
                <TextContainer>
                    <Text>No bookings yet</Text>
                </TextContainer>
            )}
        </StudiosContainer>
    );
};

const StudiosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
`;
const TextContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
    text-align: center;
`;

export default StudioBookings;
