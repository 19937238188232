// Libraries
import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { isEmail } from "validator";
import { PropTypes } from "prop-types";
// import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
// Stylings
import "react-toastify/dist/ReactToastify.css";
// Services
import {
  setIsUserSignUpDone,
  // setUser,
} from "../../../../redux/services/user/userSlice";
import {
  useSignupMutation,
  useResendEmailActivationLinkMutation,
} from "../../../../redux/services/user/userApi";
// Components
import { ToastContainer, toast } from "react-toastify";
import MuzeInput from "./../../../../components/input/MuzeInput";
import MuzeButton from "../../../../components/muzeBtn/MuzeButton";
import LineWrapper from "../../../../components/lineWrapper/LineWrapper";
// Assets
import Loading from "../../../../components/loading/Loading";
import BoxContainer from "../../../../components/boxContainer/BoxContainer";
// import { IconsContainer } from "../../../signin/Signin";
import { handleValidation } from "../../../../services/Helpers";
import TermsAR from "./TermsAR";
import TermsEN from "./TermsEN";
import { Button } from "react-bootstrap";

// const FBLogo = `/icons/common/FB-Logo.svg`;
// const GoogleLogo = `/icons/common/Google-Logo.svg`;
const EyeLogo = `/icons/common/eye.svg`;

const AccountInfo = ({ switchPage }) => {
  // navigate hook for routing
  const navigate = useNavigate();
  // dispatch hook for dispatching actions to the redux store
  const dispatch = useDispatch();

  // Waiting for authentication
  const [isActive, setIsActive] = useState(true);
  // translation hook
  const { t } = useTranslation();
  // form state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTermsConditions: false,
  });
  // boolean state for showing the password in the ui
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [count, setCount] = useState(60);
  const [isCounting, setIsCounting] = useState(true); // State to control countdown

  // To set terms and conditions from arabic to english
  const [termsToEnglish, setTermsToEnglish] = useState(false);

  // Form Errors
  const [errors, setErrors] = useState([]);
  // Signup Endpoint
  const [
    signup,
    {
      data: signupData,
      isSuccess: isSignupSuccess,
      isError: isSignupError,
      isLoading: isSignupLoading,
    },
  ] = useSignupMutation();
  const [resendActivationLink] = useResendEmailActivationLinkMutation();
  // function to handle the submit of the form and calling the backend endpoint
  const handleSubmit = async (event) => {
    event.preventDefault();
    // check for form errors
    assignErrors();
    // send to backend register
    if (!errors.length) {
      await signup(formData).then((res) => {
        res.data !== undefined ? setIsActive(res.data.isActive) : setIsActive(isActive);
      });
    }
  };

  // function to handle modal close
  const handleClose = () => {
    setShowModal(false);
  };
  // function to check if the form fields have erros and reflects them in the ui
  const assignErrors = () => {
    const { firstName, lastName, email, password, confirmPassword } = formData;
    if (!errors.includes("firstName") && firstName.length < 2) {
      errors.push("firstName");
    }
    if (!errors.includes("lastName") && lastName.length < 2) {
      errors.push("lastName");
    }
    if (!errors.includes("email") && !isEmail(email)) {
      errors.push("email");
    }
    if (!errors.includes("password") && password.length < 8) {
      errors.push("password");
    }
    if (
      !errors.includes("confirmPassword") &&
      (confirmPassword !== password || confirmPassword === "")
    ) {
      errors.push("confirmPassword");
    }
    errors.map((field) => {
      return (document.getElementById(field).style.borderColor = "red");
    });
  };
  // function to check if the form fields errors are resolved and reflect in the ui
  const removeErrors = () => {
    if (errors.includes("firstName") && formData.firstName.length > 2) {
      errors.splice(errors.indexOf("firstName"), 1);
      document.getElementById("firstName").style.borderColor = "white";
    }
    if (errors.includes("lastName") && formData.lastName.length > 2) {
      errors.splice(errors.indexOf("lastName"), 1);
      document.getElementById("lastName").style.borderColor = "white";
    }
    if (errors.includes("email") && isEmail(formData.email)) {
      errors.splice(errors.indexOf("email"), 1);
      document.getElementById("email").style.borderColor = "white";
    }
    if (errors.includes("password") && formData.password.length > 8) {
      errors.splice(errors.indexOf("password"), 1);
      document.getElementById("password").style.borderColor = "white";
    }
    if (errors.includes("confirmPassword") && formData.confirmPassword === formData.password) {
      errors.splice(errors.indexOf("confirmPassword"), 1);
      document.getElementById("confirmPassword").style.borderColor = "white";
    }
  };

  const resendActivationLinkHandler = () => {
    if (!isCounting) {
      setCount(60);
      setIsCounting(true);
      resendActivationLink({ email: formData.email });
    }
  };

  // function to handle field values change and reflects them in the state
  const handleFormDataChange = (event) => {
    if (event.target.type === "checkbox") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });

      handleValidation(event, formData);
    }
  };
  // initializing the errors to empty array when the component is mounted
  useEffect(() => {
    setErrors([]);
  }, []);
  // when the formdata fields changes execute the remove errors function to check if any error is resolved
  useEffect(() => {
    removeErrors();
  }, [formData]);
  // listening to signupsuccess state
  useEffect(() => {
    if (isSignupSuccess && signupData.profileId) {
      dispatch(setIsUserSignUpDone(signupData.signUpDone));
      // dispatch(setUser(signupData?.user));
      // navigate("/waiting-auth");
      setAuthModal(true);
      // switchPage();
    }
  }, [isSignupSuccess]);

  useEffect(() => {
    if (isSignupError) {
      toast.error(
        "An Account already exists with the following email, please try a different email in order to sign in"
      );
    }
  }, [isSignupError]);

  useEffect(() => {
    if (isCounting) {
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isCounting]);

  useEffect(() => {
    if (count === 0) {
      setIsCounting(false);
    }
  }, [count]);

  return isSignupLoading ? (
    <Loading />
  ) : (
    <PersonalInfoForm onSubmit={handleSubmit}>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
          closeButton
        >
          <Modal.Title>Terms and conditions</Modal.Title>

          {termsToEnglish && (
            <Button onClick={() => setTermsToEnglish(!termsToEnglish)}>Arabic</Button>
          )}
          {!termsToEnglish && (
            <Button onClick={() => setTermsToEnglish(!termsToEnglish)}>English</Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <p>{termsToEnglish ? <TermsEN /> : <TermsAR />}</p>
        </Modal.Body>
      </Modal>
      <Modal
        show={authModal}
        onHide={() => setAuthModal(false)}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body style={{ backgroundColor: "#171717" }} className="customBackground">
          <StyledBoxContainer>
            <Text style={{ textAlign: "center", fontSize: "32px" }}>
              Check your email for the activation Link
            </Text>
            <AuthContainer>
              <MuzeButton disabled={!isCounting} onClick={resendActivationLinkHandler}>
                Resend Link
              </MuzeButton>
              <Counter>{count}s</Counter>
            </AuthContainer>
          </StyledBoxContainer>
        </Modal.Body>
      </Modal>

      <Wrapper>
        <Title>{t("getStarted")}</Title>
        <AnchorContainer className="anchor-container">
          <Text>{t("alreadyHaveAnAccount")}</Text>
          <SignupAnchor
            onClick={() => {
              navigate("/signin");
            }}
          >
            {t("signIn")}
          </SignupAnchor>
        </AnchorContainer>
        <InputContainer>
          <NameContainer>
            <MuzeInput
              type={"text"}
              id="firstName"
              name="firstName"
              autoComplete="off"
              placeholder={t("firstName")}
              onChange={handleFormDataChange}
              required
            />
            <MuzeInput
              type={"text"}
              id="lastName"
              name="lastName"
              autoComplete="off"
              placeholder={t("lastName")}
              onChange={handleFormDataChange}
              required
            />
          </NameContainer>
          <PasswordContainer>
            <MuzeInput
              type={"email"}
              id="email"
              name="email"
              autoComplete="off"
              placeholder={t("email")}
              onChange={handleFormDataChange}
              required
            />
          </PasswordContainer>
          <PasswordContainer>
            <MuzeInput
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              autoComplete="off"
              placeholder={t("password")}
              onChange={handleFormDataChange}
              required
            />
            <Eye onClick={() => setShowPassword(!showPassword)} />
          </PasswordContainer>

          <PasswordContainer>
            <MuzeInput
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              autoComplete="off"
              placeholder={t("confirmPassword")}
              onChange={handleFormDataChange}
              required
            />
            <Eye onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
          </PasswordContainer>
          <TermsAndConditions>
            <Checkbox
              type={"checkbox"}
              name={"acceptTermsConditions"}
              value={formData.acceptTermsConditions}
              onChange={handleFormDataChange}
              required
            />
            <TermsContainer>
              <label htmlFor="acceptTermsConditions">
                <Trans i18nKey="acceptTermsAndConditions">
                  I read and accepted the
                  <TacAnchor onClick={() => setShowModal(true)}>Terms</TacAnchor>
                  and
                  <TacAnchor onClick={() => setShowModal(true)}>Conditions</TacAnchor>
                </Trans>
              </label>
            </TermsContainer>
          </TermsAndConditions>
        </InputContainer>
        <StyledMuzeButton name="signUp" type="submit">
          {t("signUp")}
        </StyledMuzeButton>
        <LineWrapper>{/* {t("orSignUpWith")} */}</LineWrapper>
        {/* <IconsContainer>
            <IconWrapper
                className={"signin-icon-wrapper"}
                as={motion.div}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
            >
                <img src={GoogleLogo} alt="Google" />
            </IconWrapper>
            <IconWrapper
                className={"signin-icon-wrapper"}
                as={motion.div}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
            >
                <img src={FBLogo} alt="Facebook" />
            </IconWrapper>
        </IconsContainer> */}
      </Wrapper>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        limit={3}
        rtl={document.dir === "rtl"}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        toastStyle={{ backgroundColor: "black" }}
      />
    </PersonalInfoForm>
  );
};

AccountInfo.propTypes = {
  switchPage: PropTypes.func,
};

const Wrapper = styled(BoxContainer)`
  margin-left: auto;
  margin-right: 30px;
  padding: 20px 10px;
  min-height: 650px;
  margin-bottom: 40px !important;
  @media (min-width: 280px) {
    margin: 0;
    width: 90%;
  }
  @media (min-width: 641px) {
    width: 80%;
  }
  @media (min-width: 1024px) {
    width: 50%;
    margin-left: auto;
    margin-right: 30px;
  }
  &::before {
    content: "";
    background-image: url("./icons/signup/singer.png");
    background-repeat: no-repeat;
    width: 120%;
    background-position: bottom;
    height: 100%;
    position: absolute;
    bottom: -45px;
    left: -85%;
    z-index: 1;
    @media (max-width: 1025px) {
      content: none;
    }
  }
`;

const PersonalInfoForm = styled.form`
  max-width: 100vw;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-image: url("./icons/signup/Profile-Prefrences-Background.jpg");
  background-position: 50%;
  padding: 130px 0 0px;
  position: relative;
  z-index: 1;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 52px;
  line-height: 66px;
  text-align: center;
  color: #ffffff;

  @media (min-width: 280px) {
    font-weight: 500;
    font-size: 36px;
    line-height: 50px;
  }
  /* targeting laptops */
  @media (min-width: 1024px) {
    font-weight: 700;
    font-size: 52px;
    line-height: 66px;
  }
`;
const AnchorContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;
const SignupAnchor = styled.div`
  margin-left: 0.5vw;
  padding: 2px;
  text-decoration: underline;
  color: #e37598;
  cursor: pointer;
  /* targeting laptops */
  @media (min-width: 1024px) {
    margin-left: 0.2vw;
    padding: 1%;
  }
`;
const InputContainer = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  /* targeting mobile devices */
  @media (min-width: 280px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  /* targeting tablets and ipad */
  @media (min-width: 641px) {
    flex-direction: row;
    width: 68%;
  }

  /* targeting laptops */
  @media (min-width: 1024px) {
    width: 75%;
  }
`;
const TermsContainer = styled.div``;
const TermsAndConditions = styled.div`
  display: flex;
  flex-direction: row;
  width: 103%;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.75px;
  margin-top: 1vh;
  color: #f2f2f2;
  z-index: 99;
  /* targeting mobile devices */
  @media (min-width: 280px) {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    width: 70%;
  }

  /* targeting tablets and ipad */
  @media (min-width: 641px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    width: 50vw;
  }

  /* targeting laptops */
  @media (min-width: 1024px) {
    width: 100%;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.75px;
    margin-top: 1%;
    margin-left: 28%;
  }
`;
const Checkbox = styled.input`
  /* targeting mobile devices */
  @media (min-width: 280px) {
    margin-right: 3vw;
  }

  /* targeting tablets and ipad */
  @media (min-width: 641px) {
    margin-right: 1.5%;
  }
`;
const TacAnchor = styled.a`
  color: #2d9cdb;
  text-decoration: none;
  cursor: pointer;
`;
const StyledMuzeButton = styled(MuzeButton)`
  margin-top: 25px;
  width: 30%;

  /* targeting mobile devices */
  @media (min-width: 280px) {
    width: 40%;
  }

  /* targeting tablets and ipad */
  @media (min-width: 641px) {
    width: 50%;
  }

  /* targeting laptops */
  @media (min-width: 1024px) {
    width: 30%;
  }
`;
// const IconWrapper = styled.div`
//   background: #ffffff;
//   width: 45px;
//   height: 45px;
//   margin-right: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 8px;
//   cursor: pointer;
// `;
const Text = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.color};
`;
export const PasswordContainer = styled.div`
  width: 103%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const Eye = styled.img.attrs({ src: EyeLogo })`
  position: absolute;
  cursor: pointer;
  z-index: 9;
  @media (min-width: 240px) {
    left: ${() => (document.dir === "ltr" ? "75%" : "20%")};
  }
  @media (min-width: 641px) {
    left: ${() => (document.dir === "ltr" ? "75%" : "25%")};
  }
  @media (min-width: 1024px) {
    left: ${() => (document.dir === "ltr" ? "80%" : "20%")};
  }
`;

const AuthContainer = styled.div`
  display: flex;
  margin: 50px 20px;
  align-items: center;
  justify-content: center;
  gap: 50px;
`;
const Counter = styled.div`
  color: ${({ theme }) => theme.color};
  font-size: 20px;
`;

const StyledBoxContainer = styled(BoxContainer)`
  margin-right: auto;
  padding: 20px 10px;
  @media (min-width: 1024px) {
    padding: 40px 50px;
  }
`;

export default AccountInfo;
