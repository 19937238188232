/* eslint-disable */
import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import "../booking/Slots.css";
import { useDispatch, useSelector } from "react-redux";
import { useGetSlotsMutation } from "../../redux/services/booking/bookingApi";

import {
    setCurrentDate,
    setGridData,
} from "../../redux/services/booking/bookingSlice";
import SlotsGrid from "./SlotsGrid";

const Slots = ({ handleLoading }) => {
    const [slots, setSlots] = useState([
        "08-09",
        "09-10",
        "10-11",
        "11-12",
        "12-13",
        "13-14",
        "14-15",
        "15-16",
        "16-17",
        "17-18",
        "18-19",
        "19-20",
        "20-21",
        "21-22",
        "22-23",
        "23-24",
        "24-01",
        "01-02",
        "02-03",
        "03-04",
    ]);
    const getDayName = (date) => {
        const date_day = date.slice(0, 2);
        const date_month = date.slice(3, 5);
        const date_year = date.slice(6);
        const day = new Date(date_month + "/" + date_day + "/" + date_year);
        const day_name = day.toDateString().slice(0, 3);
        return day_name;
    };

    const getDay = (date) => {
        const date_day = date.slice(0, 2);
        return date_day;
    };

    const getDayAfter = (date, num) => {
        if (parseInt(date.slice(0, 2)) + num >= 31) {
            return -1;
        } else {
            const date_day_custom = parseInt(date.slice(0, 2)) + num;
            const date_month = date.slice(3, 5);
            const date_year = date.slice(6);
            if (date_day_custom < 10) {
                return (
                    "0" +
                    (parseInt(date.slice(0, 2)) + num) +
                    "/" +
                    date_month +
                    "/" +
                    date_year
                );
            } else {
                return (
                    parseInt(date.slice(0, 2)) +
                    num +
                    "/" +
                    date_month +
                    "/" +
                    date_year
                );
            }
        }
    };
    const getDayBefore = (date, num) => {
        if (parseInt(date.slice(0, 2)) - num < 1) {
            return -1;
        } else {
            const date_day_custom = parseInt(date.slice(0, 2)) - num;
            const date_month = date.slice(3, 5);
            const date_year = date.slice(6);
            if (date_day_custom < 10) {
                return (
                    "0" +
                    (parseInt(date.slice(0, 2)) - num) +
                    "/" +
                    date_month +
                    "/" +
                    date_year
                );
            } else {
                return (
                    parseInt(date.slice(0, 2)) +
                    num +
                    "/" +
                    date_month +
                    "/" +
                    date_year
                );
            }
        }
    };

    const getCurrentDate = () => {
        return daysRange[currentIndex];
    };

    const { date } = useSelector((state) => state.booking.bookingSearchData);

    const daysRange = [
        getDayBefore(date, 2),
        getDayBefore(date, 1),
        date,
        getDayAfter(date, 1),
        getDayAfter(date, 2),
    ];

    const days = new Array();
    let counter = 0;
    daysRange.forEach((d) => {
        if (d != -1) {
            days.push({
                index: counter,
                dataDay: getDay(d),
                dataDayName: getDayName(d),
            });
            counter++;
        }
    });
    const dispatch = useDispatch();
    const firstElementIndex = daysRange.findIndex((d) => d == date);
    const [active, setActive] = useState(firstElementIndex);
    const [getSlots, response] = useGetSlotsMutation();
    const [currentIndex, setCurrentIndex] = useState(firstElementIndex);
    const currentStudioId = useSelector(
        (state) => state.booking.currentStudioId
    );

    useEffect(() => {
        const currentDate = getCurrentDate();
        getSlots({
            studioRoomId: currentStudioId,
            searchDate: currentDate,
            getStartTimeOnly: true,
        }).then((res) => {
            dispatch(setGridData(res.data["Bookings"]));
            dispatch(setCurrentDate(currentDate));
        });
    }, [currentStudioId]);
    useEffect(() => {
        const currentDate = getCurrentDate();
        getSlots({
            studioRoomId: currentStudioId,
            searchDate: currentDate,
            getStartTimeOnly: true,
        }).then((res) => {
            dispatch(setGridData(res.data["Booked Slots"]));
            dispatch(setCurrentDate(currentDate));
        });
    }, []);
    const handleSelect = (selectedIndex) => {
        setCurrentIndex(selectedIndex);
        const currentDate = getCurrentDate();
        getSlots({
            studioRoomId: currentStudioId,
            searchDate: currentDate,
            getStartTimeOnly: true,
        }).then((res) => {
            dispatch(setGridData(res.data["Booked Slots"]));
            dispatch(setCurrentDate(currentDate));
        });
        setActive(selectedIndex);
    };
    return (
        <div className="adjust">
            <Carousel
                interval={null}
                onSelect={handleSelect}
                onSlide={handleSelect}
                activeIndex={active}
            >
                {days.map((day) => (
                    <Carousel.Item>
                        <div className="date-container">
                            <div className="single-date">
                                <Text style={{ marginBottom: "-15%" }}>
                                    {day.dataDay}
                                </Text>
                                <Day> {day.dataDayName} </Day>
                            </div>
                        </div>
                        <Carousel.Caption style={{ visibility: "hidden" }}>
                            <Text>{day.dataDay}</Text>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
            <hr />
            <div>
                <SlotsGrid handleLoading={handleLoading} slots={slots} />
            </div>
        </div>
    );
};

const Button = styled.button`
    background: rgb(25, 25, 25);
    background: linear-gradient(
        90deg,
        rgba(25, 25, 25, 1) 27%,
        rgba(36, 36, 36, 1) 97%
    );
    border-radius: 10px;
    padding: 10px 20px;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
`;

const Day = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 35px;
    margin: 0% 0%;
`;

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
    margin: 0% 0% 0% 0%;
`;

export default Slots;
