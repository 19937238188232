// Libraries

import React, { useRef } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import "./Filter.css";

// Assets
const SearchIconLogo = `/icons/navbar/SearchIcon.svg`;

const FilterCollab = ({
  original,
  input,
  handleInputChange,
  searchKeyWord,
  searchKeyWords = [],
}) => {
  const nameRef = useRef();
  const { t } = useTranslation();

  const handleChange = (event) => {
    input = original;
    const searchKey = searchKeyWord;
    const searchKeys = searchKeyWords || [searchKey];
    const filteredData = input?.filter((ele) => {
      return searchKeys.some((key) => {
        return (
          ele[`${key}`]?.toLowerCase().includes(event?.target.value.toLowerCase()) ||
          (ele[`${key}`] === null && event?.target.value.length === 0)
        );
      });
    });
    handleInputChange(filteredData);
  };

  return (
    <>
      <div className="main-search-container">
        <SearchBox as={motion.form} whileFocus={{ scale: 1.05 }} id="Filter__SearchBar">
          <div className="search-bar">
            <SearchBar
              type={"search"}
              placeholder={`${t("search by keyword")}...`}
              onChange={(e) => handleChange(e)}
              ref={nameRef}
            />

            <SearchIcon as={motion.div} whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.3 }}>
              <img src={SearchIconLogo} alt="Search Icon" />
            </SearchIcon>
          </div>
        </SearchBox>
      </div>
    </>
  );
};

FilterCollab.propTypes = {
  original: PropTypes.array,
  input: PropTypes.string,
  handleInputChange: PropTypes.func,
  searchKeyWord: PropTypes.string,
  searchKeyWords: PropTypes.array,
};

const SearchBox = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  border: 1px solid #737b8f;
  border-radius: 1rem;
  background-color: transparent;
  padding-left: 1vw;
  min-height: 50px;
  min-width: 350px;
`;
const SearchBar = styled.input`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.color};
  outline: none;
  @media (min-width: 280px) {
    width: 90%;
  }
  @media (min-width: 641px) {
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: 95%;
  }
`;
const SearchIcon = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 5%;
`;

FilterCollab.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FilterCollab;
