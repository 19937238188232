import styled from "styled-components";
import React, { useState, useEffect } from "react";
import PanelHeader from "./parts/PanelHeader";
import PanelBody from "./parts/PanelBody";
import PanelFooter from "./parts/PanelFooter";

import { getRefreshToken } from "../../../../services/cookieManager";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/services/user/userSlice";

const Panel = () => {
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const uuid = useSelector((state) => state.chat.uuid);
  const { user } = useSelector(selectUser);

  useEffect(() => {
    setMessages([]); // Reset messages when uuid changes
    const newSocket = new WebSocket(`wss://muzenetworktest.azurewebsites.net/ws/chat/${uuid}/`);
    setSocket(newSocket);

    newSocket.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    newSocket.addEventListener("error", (error) => {
      console.error("WebSocket error:", error);
    });

    return () => {
      newSocket.close();
    };
  }, [uuid]);

  const sendMessage = (message) => {
    if (socket) {
      socket.send(
        JSON.stringify({
          message,
          name: user.firstName + " " + user.lastName,
          refreshToken: getRefreshToken(),
        })
      );
    }
  };

  return (
    <Container>
      <PanelHeader />
      <PanelBody messages={messages} />
      <PanelFooter sendMessage={sendMessage} />
    </Container>
  );
};

export default Panel;

const Container = styled.div`
  flex: 2;
  color: #fff;
  background-color: #0e0e0e99;
  border-radius: 20px;
  min-height: 750px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
