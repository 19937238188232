import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// configuring the i18n for locales, check i18n docs for more info
i18next
    .use(initReactI18next)
    .use(HttpApi) // registering the backend plugin for lazy file loading
    .use(LanguageDetector) // detecting the default user preference
    .init({
        interpolation: {
            escapeValue: false,
        },
        lng: "en",
        // Allows "en-US" and "en-UK" to be implcitly supported when "en"
        // is supported
        nonExplicitSupportedLngs: true,
        supportedLngs: ["en", "ar"],
        fallbackLng: "en",
        react: {
            useSuspense: false,
        },
    });
export default i18next;
