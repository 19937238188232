// Libraries
import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
// Assets
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/services/user/userSlice";

const Home = `./icons/side-menu/dark-home.svg`;
const Explore = `./icons/side-menu/dark-explore.svg`;
const Collab = `./icons/side-menu/dark-collab.svg`;
const MobileSideMenu = () => {
    const navigate = useNavigate();
    const { user } = useSelector(selectUser);
    const { pathname } = useLocation();
    // styling for select tab in sidemenu
    const selectedStyle = (route) => {
        if (pathname === route) {
            return {
                background: "#3392FF",
                border: "2px solid #63A0E5",
                boxShadow:
                    "0px 20px 60px rgba(0, 119, 255, 0.3), inset 0px 10px 23px rgba(36, 80, 130, 0.4)",
            };
        }
    };
    return (
        <Container>
            <Link
                style={selectedStyle("/home")}
                onClick={() => navigate("/home")}
            >
                <LinkIcon src={Home} alt="Home" />
                <Title>Home</Title>
            </Link>
            <Link
                style={selectedStyle("/explore")}
                onClick={() => navigate("/explore")}
            >
                <LinkIcon src={Explore} alt="Explore" />
                <Title>Explore</Title>
            </Link>
            <Link
                style={selectedStyle("/collaborations")}
                onClick={() => navigate("/collaborations")}
            >
                <LinkIcon src={Collab} alt="Collab" />
                <Title>Collab</Title>
            </Link>
            <Link
                style={selectedStyle("/profile")}
                onClick={() => navigate("/profile")}
            >
                <ProfileImg
                    src={
                        user.userImage.blobUrl
                            ? user.userImage.blobUrl
                            : "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492"
                    }
                />
                <Title>Profile</Title>
            </Link>
        </Container>
    );
};

const Container = styled.nav`
    z-index: 999;
    background-color: #333;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(47px);
    border-radius: 22px 23px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    color: ${({ theme }) => theme.color};
    padding: 10px;
    @media (max-width: 567px) {
        flex-wrap: wrap;
        height: max-content;
    }
`;
const Link = styled.div`
    padding: 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    gap: 12px;
    max-width: 170px;
    @media (max-width: 567px) {
        width: 100%;
        padding: 5px;
        max-width: 100px;
    }
`;
const LinkIcon = styled.img`
    cursor: pointer;
    width: 25px;
    @media (max-width: 567px) {
        width: 20px;
    }
`;
const Title = styled.div`
    cursor: pointer;
`;
const ProfileImg = styled.img`
    cursor: pointer;
    border-radius: 6px;
    max-height: 40px;
    object-fit: contain;
`;
export default MobileSideMenu;
