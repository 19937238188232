const DarkBackgroundImg = `/icons/background-dark-img.png`;
const LightBackgroundImg = `/icons/background-light-img.png`;

const NavbarDarkBackground = `/icons/navbar/navbar-dark-background.png`;
const NavbarLightBackground = `/icons/navbar/navbar-light-background.png`;

export const NavbarDarkLogo = `/icons/navbar/navbar-dark-logo.png`;
export const NavbarLightLogo = `/icons/navbar/navbar-light-logo.png`;

const FooterDarkLogo = `/icons/footer/footer-dark-logo.svg`;
const FooterLightLogo = `/icons/footer/footer-light-logo.svg`;
const FbDarkLogo = `/icons/footer/fb-dark-logo.svg`;
const FbLightLogo = `/icons/footer/fb-light-logo.svg`;
const IgDarkLogo = `/icons/footer/ig-dark-logo.svg`;
const IgLightLogo = `/icons/footer/ig-light-logo.svg`;
const TwitterDarkLogo = `/icons/footer/twitter-dark-logo.svg`;
const TwitterLightLogo = `/icons/footer/twitter-light-logo.svg`;

const DarkHome = `/icons/side-menu/dark-home.svg`;
const LightHome = `/icons/side-menu/light-home.svg`;

const DarkCollab = `/icons/side-menu/dark-collab.svg`;
const LightCollab = `/icons/side-menu/light-collab.svg`;

const DarkBooking = `/icons/side-menu/dark-booking.svg`;
const LightBooking = `/icons/side-menu/light-booking.svg`;

const DarkExplore = `/icons/side-menu/dark-explore.svg`;
const LightExplore = `/icons/side-menu/light-explore.svg`;

export const darkTheme = {
    name: "dark",
    background: "#171717",
    color: "#FFFFFF",
    secondaryColor: "rgba(240, 243, 247, 0.52)",
    backgroundImage: DarkBackgroundImg,
    navbarBackgroundImg: NavbarDarkBackground,
    navbarLogo: NavbarDarkLogo,
    navbarBackgroundColor: "rgba(32, 32, 32, 0.4)",
    navbarSigninBtnBorder: "1px solid #FFFFFF",
    navbarTextColor: "#D8E1EB",
    navbarDropDownMenuBorderColor: "#242424",
    footerBackground: "#1B1B1B",
    footerLogo: FooterDarkLogo,
    footerFbLogo: FbDarkLogo,
    footerTwitterLogo: TwitterDarkLogo,
    footerIgLogo: IgDarkLogo,
    themeTogglerBg: "#030d1a",
    themeTogglerColor: "#F0F3F7",
    home: DarkHome,
    collab: DarkCollab,
    explore: DarkExplore,
    booking: DarkBooking,
    collabCardBackground: "rgba(13, 13, 13, 0.5)",
    collabCardBorderColor: "#1B1B1D",
    musicianCardBackground: " rgba(13, 13, 13, 0.5)",
    secondaryButtonColor: "rgba(255, 255, 255, 0.55)",
    collabTitleBackground: "#161616",
    settingsFieldColor: "rgba(255, 255, 255, 0.6)",
};

export const lightTheme = {
    name: "light",
    background: "#FFFF",
    color: "#0D0D0D",
    backgroundImage: LightBackgroundImg,
    navbarBackgroundImg: NavbarLightBackground,
    navbarBackgroundColor: "rgba(223, 223, 223, 0.4)",
    navbarLogo: NavbarLightLogo,
    navbarSigninBtnBorder: "1px solid black",
    navbarTextColor: "#1D2938",
    navbarDropDownMenuBorderColor: "#DBDBDB",
    footerBackground: "#E4E4E4",
    footerLogo: FooterLightLogo,
    footerFbLogo: FbLightLogo,
    footerTwitterLogo: TwitterLightLogo,
    footerIgLogo: IgLightLogo,
    themeTogglerBg: "#F0F3F7",
    themeTogglerColor: "#7D96B3",
    home: LightHome,
    collab: LightCollab,
    explore: LightExplore,
    booking: LightBooking,
    collabCardBackground: " rgba(255, 255, 255, 0.5)",
    collabCardBorderColor: "#E2E2E4",
    musicianCardBackground: "rgba(255, 255, 255, 0.5)",
    secondaryButtonColor: " rgba(13, 13, 13, 0.55)",
    collabTitleBackground: "#FFFFFF",
};
