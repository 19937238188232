import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MuzeButton from "../../components/muzeBtn/MuzeButton";
import { useCollaborationCreateMutation } from "../../redux/services/collaboration/collaborationApi";
import DropDown from "../../components/dropdown/DropDown";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./PostCollaboration.css";
import Loading from "../../components/loading/Loading";
import { useGetAllArtistsMutation } from "../../redux/services/search/searchApi";
import { useGenresMutation } from "../../redux/services/utils/utilsApi";
import { setAllArtists } from "../../redux/services/search/searchSlice";
import { setGenresList } from "../../redux/services/utils/utilsSlice";
// Animtated Guide
import { Steps } from "intro.js-react";
const isGuest = localStorage.getItem("Guest");
const PostCollabortaion = () => {
  // Translation hook
  const { t } = useTranslation();
  // Animated Guide
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const onExit = () => {
    setInitialStep(0);
    setStepsEnabled(false);
  };
  const steps = [
    {
      element: ".Collab__submit-form",
      title: "Collab Post!",
      intro:
        "You’re on your final step of creating a collab post. Fill in the fields and submit it when you’re ready!",
    },
  ];

  // dispatch hook for dispatching action to the redux store
  const dispatch = useDispatch();
  const [inputsData, setInputData] = useState({
    applicationDeadline: "",
    description: "",
    experienceLevel: "",
    location: "",
    lookingForSkill: [],
    genre: [],
    title: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [sendCollabData] = useCollaborationCreateMutation();

  const ltrBG =
    "linear-gradient(252.51deg, rgba(255, 255, 255, 0.7) 15.97%, rgba(173, 173, 173, 0.658) 100%)";

  const rtlBG =
    " linear-gradient(104.76deg, rgba(255, 255, 255, 0.7) -2.57%, rgba(173, 173, 173, 0.658) 92.68%)";

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      background: "transparent",
      cursor: "pointer",
    }),
    option: (styles, state) => {
      return {
        ...styles,
        color: "white",
        backgroundColor: state.isSelected ? "#D4A9E4" : "transparent",
        cursor: "pointer",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        color: "white",
        cursor: "pointer",
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        background: document.dir === "ltr" ? ltrBG : rtlBG,
        border: "1px solid rgba(255, 255, 255, 0.8)",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        backdropFilter: "blur(10px)",
        cursor: "pointer",
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        color: "white",
      };
    },
  };

  const [getArtists] = useGetAllArtistsMutation();
  const [getGenres] = useGenresMutation();
  const genres = useSelector((state) => state.utils.genresList);
  const skills = useSelector((state) => state.utils.skillsList);

  const onChange = (e) => {
    const { name, value } = e?.target;
    setInputData({ ...inputsData, [name]: value });
    for (const key in inputsData) {
      if (key.length) {
        document.getElementById(`${key}`)?.classList.remove("invalid");
        document.getElementById(`${key}`)?.classList.add("valid");
      }
    }
  };

  const chechMissingInputs = () => {
    let isMissing = false;
    if (!inputsData.title) {
      document.getElementById("title").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.experienceLevel) {
      document.getElementById("experienceLevel").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.location) {
      document.getElementById("location").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.lookingForSkill?.length) {
      document.getElementById("lookingForSkill").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.genre?.length) {
      document.getElementById("genre").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.applicationDeadline) {
      document.getElementById("applicationDeadline").setAttribute("class", "invalid");
      isMissing = true;
    } else if (!inputsData.description) {
      document.getElementById("description").setAttribute("class", "invalid collab");
      isMissing = true;
    } else {
      console.log("No error found ");
      isMissing = false;
    }
    return isMissing;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!chechMissingInputs()) {
      sendCollabData(inputsData).then((response) => {
        if (!response?.error) {
          toast.success(t("Collaboration created"));
          setInputData({
            applicationDeadline: "",
            description: "",
            experienceLevel: "",
            location: "",
            lookingForSkill: [],
            genre: [],
            minimumQualification: "",
            title: "",
          });
        }
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArtists().then((res) => {
      dispatch(setAllArtists(res.data));
    });
    getGenres().then((res) => setGenresList(res?.data?.genres));
  }, []);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {isGuest && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
          options={{ doneLabel: "Okay" }}
        />
      )}

      <Container>
        <Content>
          <form className={"Collab__submit-form"}>
            <Section>
              <p>Title:</p>
              <SectionInput
                name="title"
                id="title"
                value={inputsData.title}
                type={"text"}
                onChange={onChange}
                maxLength={45}
                placeholder="Max 45 character"
                required
              />
            </Section>

            <Section>
              <p>Experience Level:</p>
              <SectionInput
                name="experienceLevel"
                id="experienceLevel"
                value={inputsData.experienceLevel}
                type={"text"}
                onChange={onChange}
                required
              />
            </Section>
            <Section>
              <p>Location:</p>
              <SectionInput
                name="location"
                id="location"
                value={inputsData.location}
                type={"text"}
                onChange={onChange}
                required
              />
            </Section>
            <Section>
              <p>Looking For:</p>
              <StyledDropDown
                allowSelectAll={false}
                setState={setInputData}
                selectStyles={colourStyles}
                name={"lookingForSkill"}
                id="lookingForSkill"
                value={inputsData.lookingForSkill}
                isMulti={true}
                options={skills?.map((value) => {
                  return { value, label: t(value) };
                })}
                optionsSelected={inputsData?.lookingForSkill}
                required
                className="muze_dropdown"
              />
            </Section>
            <Section>
              <p>Genres:</p>
              <StyledDropDown
                selectStyles={colourStyles}
                allowSelectAll={true}
                setState={setInputData}
                name={"genre"}
                id="genre"
                isMulti
                options={genres?.map((value) => {
                  return { value, label: t(value) };
                })}
                optionsSelected={inputsData?.genre}
                required
                className="muze_dropdown"
              />
            </Section>
            <Section>
              <p>Application Deadline:</p>
              <SectionInput
                name="applicationDeadline"
                id="applicationDeadline"
                value={inputsData.applicationDeadline}
                type={"date"}
                min={new Date().toISOString().split("T")[0]}
                onChange={onChange}
                required
              />
            </Section>
            <SectionTitle>Collab description:</SectionTitle>
            <Section>
              <CollabDescription
                name="description"
                id="description"
                value={inputsData.description}
                onChange={onChange}
                required
              />
            </Section>
            <Section>
              <MuzeButton onClick={(e) => onSubmit(e)}>Submit</MuzeButton>
            </Section>
          </form>
        </Content>
      </Container>
    </>
  );
};

const StyledDropDown = styled(DropDown)`
  /* margin-top: 1%;
  margin-bottom: 3%;
  /* width: 100%; */
  /* padding: 0 20px 0 0; * */
  /* targeting mobile devices */
  width: 320px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-bottom: 5%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-left: 5%;
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.color};
  margin-top: 30px;
  p {
    min-width: 250px;
  }
`;
const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: ${({ theme }) => theme.color};
  margin-top: 30px;
  align-self: flex-start;
`;
const SectionInput = styled.input`
  all: unset;
  border: 1px solid ${({ theme }) => theme.color};
  border-radius: 5px;
  font-weight: 300;
  width: 300px;
  padding: 8px;
  font-size: 20px;
  color: ${({ theme }) => theme.color};
  position: relative;
  overflow: hidden;
  ::-webkit-calendar-picker-indicator {
    ${
      "" /* filter: invert(1);
    cursor: pointer; */
    }
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    transform: scale(12);
  }
`;
const CollabDescription = styled.textarea`
  width: 90%;
  border: 1px solid ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.color};
  padding: 8px;
  border-radius: 6px;
  border: 1px solid;

  background-color: #17171700;
`;
export default PostCollabortaion;
