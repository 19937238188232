// Libraries
import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const locationPin = `/icons/collaborations/location-pin.png`;
const UserBg = `/icons/collaborations/collab-user-circle.svg`;

const CollabCard = ({
  item,
  className,
  firstName,
  lastName,
  title,
  location,
  ActionButton,
  profileImage,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  const navigate = useNavigate();

  return (
    <Container
      as={motion.div}
      whileHover={{ scale: 1.01 }}
      className={className}
      onClick={() => {
        navigate(`/collaboration/view/${item.pk}`, {
          state: item,
        });
      }}
    >
      <CollabContent>
        {!isMobile ? (
          <CollabUser>
            {profileImage ? (
              <>
                <img
                  src={profileImage}
                  style={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "50%",
                  }}
                  alt="Profile"
                />
              </>
            ) : (
              <>
                <img src={UserBg} alt="Circle" />
                <UserInitial>{firstName != null ? firstName[0] : null}</UserInitial>
              </>
            )}
          </CollabUser>
        ) : null}
        <CollabDetails>
          <CollabDesc>{title}</CollabDesc>
          <UserName>{lastName ? firstName + " " + lastName : firstName}</UserName>
          <LocationWrapper>
            <div>
              <img src={locationPin} />
            </div>
            <Location>{location}</Location>
          </LocationWrapper>
        </CollabDetails>
      </CollabContent>

      {ActionButton}
    </Container>
  );
};

CollabCard.propTypes = {
  item: PropTypes.object,
  profileImage: PropTypes.string,
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  ActionButton: PropTypes.object,
};
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.collabCardBackground};
  border: 1px solid;
  border-color: ${({ theme }) => theme.collabCardBorderColor};
  border-radius: 10px;
  cursor: pointer;
  gap: 1rem;
  height: 20vh;
  padding: 10px;
  @media (min-width: 240px) {
    padding: 4%;
  }
  @media (min-width: 1024px) {
    padding: 2%;
  }
`;
const CollabContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color};
  gap: 30px;
`;
const CollabUser = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
`;
const UserInitial = styled.div`
  width: 100%;
  position: absolute;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 50px;
  color: #ffffff;
  display: flex;
  justify-content: center;
`;
const CollabDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.3rem;
`;
const UserName = styled.div`
  font-weight: 400;
  font-size: 15px;
  text-align: right;
  color: ${({ theme }) => theme.color};
`;
const CollabDesc = styled.div`
  font-weight: 600;
  font-size: 25px;
  color: ${({ theme }) => theme.color};
`;
const LocationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
`;
const Location = styled.div`
  font-weight: 400;
  font-size: 15px;
  white-space: no-wrap;
  color: ${({ theme }) => theme.color};
`;

export default CollabCard;
