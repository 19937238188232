// Libraries
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { toast, ToastContainer } from "react-toastify";
// Components
import VideoPlayer from "./../videoPlayer/VideoPlayer";
import MuzeButton from "../muzeBtn/MuzeButton";
// Services
import { uploadBlob } from "./../../services/BlobServices";
import { useCreateMutation } from "../../redux/services/post/postApi";
import Loading from "../loading/Loading";
import { Button } from "../../features/signin/Signin";

const PostPreview = ({ file, cover, setShowModal, setStep, onHide, setFile, setCover }) => {
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [createPost, { isSuccess: isCreatePostSuccess, isError: isCreatePostError }] =
    useCreateMutation();

  const handlePost = async () => {
    setIsLoading(true);

    let postProgress = 0;
    let coverProgress = 0;

    const updateProgress = (progressType, progress) => {
      if (progressType === "post") {
        postProgress = progress;
      } else if (progressType === "cover") {
        coverProgress = progress;
      }

      const totalProgress = (postProgress + coverProgress) / 2;
      // Update loading percentage
      setProgress(totalProgress.toFixed(2));
    };

    const postBlob = await uploadBlob(file?.src, "posts", (progress) => {
      updateProgress("post", progress);
    });
    const coverBlob = await uploadBlob(cover?.src, "posts", (progress) => {
      updateProgress("cover", progress);
    });

    setIsLoading(false);
    setProgress(100); // Ensure progress reaches 100% after both uploads are done

    if (postBlob && coverBlob) {
      createPost({
        body: caption,
        videoUrl: [
          {
            name: postBlob.blobName,
            url: postBlob.blobUrl,
          },
        ],
        postCoverUrl: coverBlob.blobUrl,
        imageUrl: [
          {
            name: coverBlob.blobName,
            url: coverBlob.blobUrl,
          },
        ],
        description,
      })
        .then((res) => {
          setIsLoading(false);
          onHide();
          window.location.reload();
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Error Creating Post");
    }
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  useEffect(() => {
    if (isCreatePostSuccess) {
      setShowModal(false);
      setStep(0);
      toast.success("Created Post Successfully");
    }
  }, [isCreatePostSuccess]);
  useEffect(() => {
    if (isCreatePostError) {
      toast.error("Error Creating Post");
    }
  }, [isCreatePostError]);

  const handleBack = () => {
    setFile({ ...file, src: "" });
    setCover({ ...cover, src: "" });
  };

  return isLoading ? (
    <>
      <StyledLoading isUploadingVideo={true} progress={progress} />
    </>
  ) : (
    <Container>
      <ButtonContainer>
        <BackButton onClick={handleBack}>Back</BackButton>
      </ButtonContainer>
      <PreviewContainer>
        <VideoWrapper>
          <StyledVideoPlayer src={file?.src}></StyledVideoPlayer>
        </VideoWrapper>
        <ContentContainer>
          <CaptionContainer>
            <ProfileInfoContainer>
              <ProfileName>Title</ProfileName>
            </ProfileInfoContainer>
            <CaptionInput
              placeholder="Write a title..."
              maxLength={50}
              onChange={handleCaptionChange}
            ></CaptionInput>
          </CaptionContainer>
          <CaptionContainer>
            <ProfileInfoContainer>
              <ProfileName>Caption</ProfileName>
            </ProfileInfoContainer>
            <CaptionInput
              placeholder="Write a caption..."
              maxLength={200}
              onChange={handleDescriptionChange}
            ></CaptionInput>
          </CaptionContainer>
          <StyledMuzeButton onClick={handlePost}>Post</StyledMuzeButton>
        </ContentContainer>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          limit={3}
          rtl={document.dir === "rtl"}
          pauseOnFocusLoss
          draggable={true}
          pauseOnHover
          toastStyle={{ backgroundColor: "black" }}
        />
      </PreviewContainer>
    </Container>
  );
};
PostPreview.propTypes = {
  file: PropTypes.object,
  cover: PropTypes.object,
  setShowModal: PropTypes.func,
  setStep: PropTypes.func,
  onHide: PropTypes.func,
  setFile: PropTypes.func,
  setCover: PropTypes.func,
};
const Container = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const BackButton = styled(Button)`
  width: 20%;
  text-align: center;
  margin: -1rem 1rem 1rem 1rem;
`;
const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  @media (min-width: 240px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
const VideoWrapper = styled.div`
  flex: 2;
`;
const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  @media (min-width: 240px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 100%;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  @media (min-width: 240px) {
    height: 30vh;
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: 40%;
    height: 70vh;
  }
`;
const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20vh;
  background: rgba(35, 35, 35, 0.75);
  border: 1px solid #000000;
  border-radius: 8px;
`;
const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30%;
`;
const ProfileName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #d8e1eb;
`;
const CaptionInput = styled.textarea`
  width: 100%;
  height: 70%;
  background: transparent;
  border: none;
  color: white;
  outline: none;
  padding: 10px;
`;
const StyledMuzeButton = styled(MuzeButton)`
  width: 40%;
  height: 8%;
`;
const StyledLoading = styled(Loading)`
  width: 100%;
  height: 100%;
`;
export default PostPreview;
