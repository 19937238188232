import React from "react";
import styled from "styled-components";

const Careers = () => {
  return (
    <Container>
      <Title>Career Opportunities at Muze</Title>
      <Paragraph>
        <ParagraphSection>Thank you for your interest in joining the Muze team.</ParagraphSection>
        <ParagraphSection>
          While we&apos;re excited about the potential for growth and innovation ahead, we&apos;re
          currently in the early stages of our journey and are not actively hiring.
        </ParagraphSection>
        <ParagraphSection>
          However, we encourage you to stay connected with us and follow our progress as we continue
          to develop our platform and expand our team in the future.
        </ParagraphSection>
        <ParagraphSection>
          In the meantime, feel free to explore our website and learn more about our services.
        </ParagraphSection>
        <ParagraphSection>
          We appreciate your enthusiasm and look forward to potentially collaborating with you down
          the road.
        </ParagraphSection>
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;

const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  padding: 30px 0 20px;
`;

const ParagraphSection = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 18px;
  }
`;

export default Careers;
