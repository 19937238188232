import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setSearchedUserDetails } from "../../../../../redux/services/user/userSlice";
import { PropTypes } from "prop-types";
import { useGetSearchedUserDetailsMutation } from "../../../../../redux/services/user/userApi";

const PanelHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOnline, setIsOnline] = useState(false);

  const chatData = useSelector((state) => state.chat.chatData);
  const user = useSelector(selectUser);

  const [getSearchedUser] = useGetSearchedUserDetailsMutation();

  const handleNavigate = () => {
    getSearchedUser({
      profileId: chatData[0].user.id,
    }).then((res) => {
      if (chatData[0].user.id !== user?.pk) {
        dispatch(setSearchedUserDetails(res.data["Profile Details"]));
        navigate("/users");
      } else {
        navigate("/profile");
      }
    });
  };
  useEffect(() => {
    // Handle Online Status With Socket
    setIsOnline(true);
  }, []);
  return (
    <Box>
      <Wrapper onClick={handleNavigate}>
        {chatData[0] && (
          <>
            <Avatar Chat={chatData[0]} isOnline={isOnline} />
            <Title isOnline={isOnline} Chat={chatData[0]} />
          </>
        )}
      </Wrapper>
    </Box>
  );
};

// Mini Components
const Avatar = ({ Chat, isOnline }) => {
  const defaultProfile =
    "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492";
  return (
    <AvatarBox>
      <img
        src={Chat?.userImageUrl || defaultProfile}
        alt="avatar"
        style={{
          width: 35,
          height: 35,
          borderRadius: 50,
        }}
      />
      {isOnline ? <OnlineDot /> : <OfflineDot />}
    </AvatarBox>
  );
};

const Title = ({ isOnline, Chat }) => {
  return (
    <TitleBox>
      <TitleHeading>
        {Chat?.firstName} {Chat?.lastName}
      </TitleHeading>
      <TitleText>{isOnline ? "Online" : "Offline"}</TitleText>
    </TitleBox>
  );
};
// Mini Components
Avatar.propTypes = {
  Chat: PropTypes.object,
  isOnline: PropTypes.bool,
};

Title.propTypes = {
  Chat: PropTypes.object,
  isOnline: PropTypes.bool,
};
export default PanelHeader;

const Box = styled.div`
  background-color: #0d0d0d;
  padding: 27px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AvatarBox = styled.div`
    padding: 5px;
    padding-bottom: 0;
    border-radius: 50%;
    // border: 1px solid #b7b9ff;
    width: 35px
    height: 35px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      background: linear-gradient(332deg, rgba(230, 230, 242, 0), rgba(225, 225, 240, 0.41), rgb(207, 207, 230));
      width: 40px;
      height: 40px;
      z-index: -1;
      border-radius: 50%;
    }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

const TitleHeading = styled.h1`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`;

const TitleText = styled.h1`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
`;
const OnlineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #16e933;
  position: absolute;
  top: 0px;
  right: -2px;
  box-shadow: 0px 4px 10px 0px #17e933;
`;

const OfflineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc; // Choose a color for offline state
  position: absolute;
  top: 0px;
  right: -2px;
  box-shadow: 0px 4px 10px 0px #ccc; // Choose a color for offline state
`;
