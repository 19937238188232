import React, { useState, useEffect, useRef } from "react";
import { ProSidebar, Menu, MenuItem, SidebarHeader } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import "./SideMenu.scss";
import { selectTheme, toggle } from "../../redux/services/theme/themeSlice";
import { selectUser } from "../../redux/services/user/userSlice";
import { setComponentState } from "../../redux/services/booking/bookingSlice";

const SideMenuBtn = `/icons/side-menu/side-menu-btn.svg`;
const ExitIcon = `/icons/side-menu/exit.svg`;
const DarkHomeIcon = `/icons/side-menu/dark-home.svg`;
const LightHomeIcon = `/icons/side-menu/light-home.svg`;
const DarkHomeFilter = `/icons/common/dark-filter.svg`;
const DarkExploreIcon = `/icons/side-menu/dark-explore.svg`;
const LightExploreIcon = `/icons/side-menu/light-explore.svg`;
const DarkCollabIcon = `/icons/side-menu/dark-collab.svg`;
const LightCollabIcon = `/icons/side-menu/light-collab.svg`;
const DarkBookingIcon = `/icons/side-menu/dark-booking.svg`;
const LightBookingIcon = `/icons/side-menu/light-booking.svg`;
// const ViewAllFriendsIcon = `/icons/side-menu/view-all.svg`;
const SunIcon = `/icons/side-menu/sun.svg`;
const DarkMoonIcon = `/icons/side-menu/moon-dark.svg`;
const LightMoonIcon = `/icons/side-menu/moon-light.svg`;
const LightThemeIcon = `/icons/side-menu/light-theme-icon.svg`;
const DarkThemeIcon = `/icons/side-menu/dark-theme-icon.svg`;
const DarkWorldIcon = `/icons/side-menu/world-dark.svg`;
const LightWorldIcon = `/icons/side-menu/world-light.svg`;
const EnglandIcon = `/icons/side-menu/england.svg`;
const EgyptIcon = `/icons/side-menu/egypt.svg`;
const ProfileImagePlaceholder = "./icons/common/user.jpg";

const SideMenu = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useSelector(selectTheme);

  const { user } = useSelector(selectUser);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const sideMenuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isHomeHovered, setIsHomeHovered] = useState(false);
  const [isExploreHovered, setIsExploreHovered] = useState(false);
  const [isCollabHovered, setIsCollabHovered] = useState(false);
  const [isBookingsHovered, setIsBookingsHovered] = useState(false);
  const handleClickOutside = (event) => {
    if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };
  const menuIconClick = () => setMenuOpen(!menuOpen);
  const handleLanguageChange = (clicked) => {
    if (i18n.language === "en" && clicked === "arabic") {
      i18n.changeLanguage("ar");
      document.documentElement.setAttribute("lang", "ar");
    } else if (i18n.language === "ar" && clicked === "english") {
      i18n.changeLanguage("en");
      document.documentElement.setAttribute("lang", "en");
    }
    setMenuOpen(false);
  };
  const toggleHoverState = (setFunc) => {
    setFunc((prevState) => !prevState);
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 27) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleKeyDown);
    const cleanup = () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside, true);
    };
    return cleanup;
  }, []);
  useEffect(() => {
    document.documentElement.classList.add("theme-transition");
    document.documentElement.setAttribute("data-theme", theme);
    window.setTimeout(() => {
      document.documentElement.classList.remove("theme-transition");
    }, 1000);
  }, [theme]);
  const selectedStyle = (route) => {
    if (pathname === route) {
      return {
        background: "#3392FF",
        border: "2px solid #63A0E5",
        boxShadow:
          "0px 20px 60px rgba(0, 119, 255, 0.3), inset 0px 10px 23px rgba(36, 80, 130, 0.4)",
      };
    }
  };

  const languageOptionStyled = {
    borderBottom: "1px solid",
    paddingBottom: "2px",
    borderColor: "rgba(255, 255, 255, 0.3)",
  };

  return (
    <StyledSideMenu
      rtl={document.dir === "rtl"}
      breakPoint="md"
      ref={sideMenuRef}
      collapsed={!menuOpen}
    >
      <Container>
        <Menu iconShape="square">
          {!menuOpen ? (
            <MenuItem
              icon={
                <img
                  src={SideMenuBtn}
                  alt="SideMenuBtn"
                  style={{ height: "100%", width: "100%" }}
                />
              }
              onClick={() => menuIconClick()}
            ></MenuItem>
          ) : (
            <SidebarHeader>
              <Profile>
                <MyImg
                  name="profileImage"
                  src={user.userImage?.blobUrl ? user.userImage?.blobUrl : ProfileImagePlaceholder}
                />
                <ProfileInfo>
                  <Name>{user.firstName + " " + user.lastName}</Name>
                  {user?.userType.length ? (
                    user?.userType?.map((type) => {
                      return <Role key={type.id}>{type.name}</Role>;
                    })
                  ) : (
                    <Role>No roles</Role>
                  )}
                </ProfileInfo>
                <StyledExit onClick={menuIconClick} />
              </Profile>
            </SidebarHeader>
          )}

          <MenuItem
            icon={
              <>
                {isHomeHovered && !menuOpen ? (
                  <HoverTitle>Home</HoverTitle>
                ) : theme === "dark" ? (
                  <img src={DarkHomeIcon} alt="DarkHomeIcon" />
                ) : (
                  <img src={LightHomeIcon} alt="LightHomeIcon" />
                )}
              </>
            }
            style={selectedStyle("/home")}
            onClick={() => navigate("/home")}
            onMouseEnter={() => toggleHoverState(setIsHomeHovered)}
            onMouseLeave={() => toggleHoverState(setIsHomeHovered)}
          >
            <MenuItemContent>
              {t("home")}
              <img src={DarkHomeFilter} alt="DarkHomeFilter" />
            </MenuItemContent>
          </MenuItem>
          <MenuItem
            icon={
              <>
                {isExploreHovered && !menuOpen ? (
                  <HoverTitle>Explore</HoverTitle>
                ) : theme === "dark" ? (
                  <img src={DarkExploreIcon} alt="DarkExploreIcon" />
                ) : (
                  <img src={LightExploreIcon} alt="DarkExploreIcon" />
                )}
              </>
            }
            style={selectedStyle("/explore")}
            onClick={() => navigate("/explore")}
            onMouseEnter={() => toggleHoverState(setIsExploreHovered)}
            onMouseLeave={() => toggleHoverState(setIsExploreHovered)}
          >
            <MenuItemContent>{t("explore")}</MenuItemContent>
          </MenuItem>
          <MenuItem
            icon={
              <>
                {isCollabHovered && !menuOpen ? (
                  <HoverTitle>Collabs</HoverTitle>
                ) : theme === "dark" ? (
                  <img src={DarkCollabIcon} alt="DarkCollabIcon" />
                ) : (
                  <img src={LightCollabIcon} alt="LightCollabIcon" />
                )}
              </>
            }
            style={selectedStyle("/collaborations")}
            onClick={() => navigate("/collaborations")}
            onMouseEnter={() => toggleHoverState(setIsCollabHovered)}
            onMouseLeave={() => toggleHoverState(setIsCollabHovered)}
          >
            {t("collaborations")}
          </MenuItem>
          <MenuItem
            icon={
              <>
                {isBookingsHovered && !menuOpen ? (
                  <HoverTitle>Bookings</HoverTitle>
                ) : theme === "dark" ? (
                  <img src={DarkBookingIcon} alt="DarkBookingIcon" />
                ) : (
                  <img src={LightBookingIcon} alt="LightBookingIcon" />
                )}
              </>
            }
            style={selectedStyle("/bookings")}
            onClick={() => {
              dispatch(setComponentState("booking"));
              navigate("/bookings");
            }}
            onMouseEnter={() => toggleHoverState(setIsBookingsHovered)}
            onMouseLeave={() => toggleHoverState(setIsBookingsHovered)}
          >
            {t("bookings")}
          </MenuItem>
          {menuOpen ? (
            <>
              {/* <FriendsContainer>
                                <FriendsHeader>
                                    <FriendsTitle>{t("friends")}</FriendsTitle>
                                    <ViewAll
                                        onClick={() => navigate("/friends")}
                                    >
                                        <ViewAllTitle>
                                            {t("viewAll")}
                                        </ViewAllTitle>
                                        <img
                                            src={ViewAllFriendsIcon}
                                            alt="ViewAllFriendsIcon"
                                        />
                                    </ViewAll>
                                </FriendsHeader>
                            </FriendsContainer> */}
              <ThemeToggler>
                <LightTheme onClick={() => dispatch(toggle({ theme: "light" }))}>
                  <img src={SunIcon} alt="SunIcon" />
                  {t("light")}
                </LightTheme>
                <DarkTheme onClick={() => dispatch(toggle({ theme: "dark" }))}>
                  {theme === "dark" ? (
                    <img src={LightMoonIcon} alt="LightMoonIcon" />
                  ) : (
                    <img src={DarkMoonIcon} alt="DarkMoonIcon" />
                  )}
                  {t("dark")}
                </DarkTheme>
              </ThemeToggler>
              <LanguageToggler>
                <LanguageTitleWrapper>
                  {theme === "dark" ? (
                    <img src={DarkWorldIcon} alt="DarkWorldIcon" />
                  ) : (
                    <img src={LightWorldIcon} alt="LightWorldIcon" />
                  )}
                  <Language>{t("language")}:</Language>
                </LanguageTitleWrapper>
                <LanguageOptions>
                  <LanguageWrapper
                    style={i18n.language === "en" ? languageOptionStyled : null}
                    onClick={() => handleLanguageChange("english")}
                  >
                    <img src={EnglandIcon} alt="EnglandIcon" style={{ alignSelf: "center" }} />
                    {t("english")}
                  </LanguageWrapper>
                  <LanguageWrapper
                    style={i18n.language === "ar" ? languageOptionStyled : null}
                    onClick={() => handleLanguageChange("arabic")}
                  >
                    <img src={EgyptIcon} alt="EgyptIcon" style={{ alignSelf: "center" }} />
                    {t("arabic")}
                  </LanguageWrapper>
                </LanguageOptions>
              </LanguageToggler>
            </>
          ) : (
            <Icon>
              {theme === "dark" ? (
                <img
                  src={DarkThemeIcon}
                  alt="DarkThemeIcon"
                  onClick={() => dispatch(toggle({ theme: "light" }))}
                />
              ) : (
                <img
                  src={LightThemeIcon}
                  alt="LightThemeIcon"
                  onClick={() => dispatch(toggle({ theme: "dark" }))}
                />
              )}
            </Icon>
          )}
        </Menu>
      </Container>
    </StyledSideMenu>
  );
};
const StyledSideMenu = styled(ProSidebar)`
  height: 100vh;
  justify-content: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const MenuItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => (theme.name === "dark" ? "#FFFFFF" : "#405D80")};
`;
const Profile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
`;

const MyImg = styled.img.attrs({
  alt: "Profile Image",
})`
  margin-left: 3%;
  max-width: 30%;
  max-height: 10%;
  object-fit: contain;
  border-radius: 8px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const Name = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;
const Role = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #7d96b3;
`;
// const FriendsContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   margin-top: 5%;
// `;
// const FriendsHeader = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-around;
// `;
// const FriendsTitle = styled.div`
//   color: ${({ theme }) => {
//     if (theme.name === "dark") {
//       return "#EBF0F5";
//     }
//     return "#405E80";
//   }};
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 18px;
// `;
// const ViewAll = styled.div`
//   width: 30%;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-self: flex-end;
//   justify-content: space-between;
//   cursor: pointer;
// `;
// const ViewAllTitle = styled.div`
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 13px;
//   color: #f77599;
// `;
const StyledExit = styled.img.attrs({ src: ExitIcon })`
  justify-self: flex-end;
  cursor: pointer;
  transform: ${() => (document.dir === "rtl" ? "rotate(180deg)" : null)};
`;
const ThemeToggler = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  align-self: center;
  background: ${({ theme }) => theme.themeTogglerBg};
  border: 2px solid
    ${({ theme }) => {
      if (theme === "dark") {
        return "#EBEFF5";
      }
      return "#102540";
    }};
  box-shadow: 0px 0px 15px rgba(16, 37, 64, 0.7);
  border-radius: 25px;
  padding: 2%;
  margin-top: 10%;
`;
const LightTheme = styled.div`
  cursor: ${({ theme }) => {
    if (theme.name === "dark") {
      return "pointer";
    }
  }};
  color: ${({ theme }) => theme.themeTogglerColor};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  padding: 1%;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => (theme.name === "dark" ? "transparent" : "#cccccc")};
  background: ${({ theme }) => {
    if (theme.name === "light") {
      return "#FCFEFF";
    }
  }};
`;
const DarkTheme = styled.div`
  cursor: ${({ theme }) => {
    if (theme.name === "light") {
      return "pointer";
    }
  }};
  color: ${({ theme }) => theme.themeTogglerColor};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  padding: 1%;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => (theme.name === "dark" ? "#405D80" : "transparent")};
  background: ${({ theme }) => {
    if (theme.name === "dark") {
      return "#102540";
    }
  }};
`;
const LanguageToggler = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin-top: 5%;
`;
const LanguageTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 35%;
  align-items: center;
  justify-content: space-between;
`;
const Language = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: ${({ theme }) => theme.secondaryColor};
`;
const LanguageOptions = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: space-between;
`;
const LanguageWrapper = styled.div`
  cursor: pointer;
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color};
  width: 70%;
  margin-left: 5%;
`;
const Icon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10% 0 10% 0;
`;

const HoverTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
`;
export default SideMenu;
