// Libraries
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import TermsAR from "../../features/signup/screens/accountInfo/TermsAR";
import TermsEN from "../../features/signup/screens/accountInfo/TermsEN";
// Stylings
import "./footer.css";
// Components
import Collapse from "../collapse/CustomCollapse";
// Asseys
const InstagramIcon = `./icons/profile/instagram.png`;

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [servicesToggle, setServicesToggle] = useState(true);
  const [aboutToggle, setAboutToggle] = useState(true);
  const [helpToggle, setHelpToggle] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [termsToEnglish, setTermsToEnglish] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <StyledFooter>
      <InfoSection>
        <InfoSubSectionNews>
          <EmailLabel>{t("newsletterSubscribe")}</EmailLabel>
          <NewsLetterEmailContainer>
            <NewsEmail type={"email"} placeholder={t("email")} />
            <Img
              as={motion.img}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 0.7 }}
              src="/icons/common/emailAroow.png"
            />
          </NewsLetterEmailContainer>
        </InfoSubSectionNews>
        <FlexFullWidth>
          <InfoSubSection>
            <InfoElementTitle
              onClick={() => {
                setServicesToggle(!servicesToggle);
              }}
            >
              {t("services")}
            </InfoElementTitle>
            <Collapse open={!isTabletOrMobile ? true : servicesToggle}>
              <CollapseContainer>
                <InfoElement
                  onClick={() => {
                    navigate("/bookings");
                  }}
                >
                  {t("studioBooking")}
                </InfoElement>
                <InfoElement
                  onClick={() => {
                    navigate("/collaborations");
                  }}
                >
                  {t("collaborations")}
                </InfoElement>
                <InfoElement
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  {t("connectingMusicians")}
                </InfoElement>
                <InfoElement
                  onClick={() => {
                    navigate("/sponsoring");
                  }}
                >
                  {t("sponsoring")}
                </InfoElement>
              </CollapseContainer>
            </Collapse>
          </InfoSubSection>
          <InfoSubSection>
            <InfoElementTitle
              onClick={() => {
                setAboutToggle(!aboutToggle);
              }}
            >
              {t("about")}
            </InfoElementTitle>
            <Collapse open={!isTabletOrMobile ? true : aboutToggle}>
              <CollapseContainer>
                {/* <InfoElement
                  onClick={() => {
                    navigate("/our-story");
                  }}
                >
                  {t("ourStory")}
                </InfoElement> */}
                {/* <InfoElement>{t("team")}</InfoElement> */}
                <InfoElement
                  onClick={() => {
                    navigate("/careers");
                  }}
                >
                  {t("careers")}
                </InfoElement>
                <InfoElement
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  {t("contactUs")}
                </InfoElement>
              </CollapseContainer>
            </Collapse>
          </InfoSubSection>
          <InfoSubSection>
            <InfoElementTitle
              onClick={() => {
                setHelpToggle(!helpToggle);
              }}
            >
              {t("help")}
            </InfoElementTitle>
            <Collapse open={!isTabletOrMobile ? true : helpToggle}>
              <CollapseContainer>
                <InfoElement
                  onClick={() => {
                    navigate("/faqs");
                  }}
                >
                  FAQ
                </InfoElement>
              </CollapseContainer>
            </Collapse>
            <FooterLogo />
          </InfoSubSection>
        </FlexFullWidth>
      </InfoSection>
      <LinksSection>
        <Flex>
          <Link onClick={() => setShowModal(true)}>{t("termsAndConditions")}</Link>
          <AlignCenter>|</AlignCenter>
          <Link
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            {t("privacyPolicy")}
          </Link>
        </Flex>

        <SocialMedia>
          <IconLink>
            <Ig
              onClick={() => {
                window.open(
                  "https://www.instagram.com/muzenetwork/",
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            ></Ig>
          </IconLink>
        </SocialMedia>
      </LinksSection>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
          closeButton
        >
          <Modal.Title>Terms and conditions</Modal.Title>

          {termsToEnglish && (
            <Button onClick={() => setTermsToEnglish(!termsToEnglish)}>Arabic</Button>
          )}
          {!termsToEnglish && (
            <Button onClick={() => setTermsToEnglish(!termsToEnglish)}>English</Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <p>{termsToEnglish ? <TermsEN /> : <TermsAR />}</p>
        </Modal.Body>
      </Modal>
    </StyledFooter>
  );
};

const Img = styled.img`
  height: 42px;
`;
const Flex = styled.div`
  display: flex;
  gap: 10px;
`;
const FlexFullWidth = styled(Flex)`
  width: 80%;
  margin-left: 30px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
  }
`;
const AlignCenter = styled(Flex)`
  align-items: center;
`;
const StyledFooter = styled.div`
  background: ${({ theme }) => theme.footerBackground};
  width: 100vw;
  /* margin-top: auto; */
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 40px 0 20px;
  z-index: 0;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 1vh 0vw 1vh 0vw;
    z-index: 0;
  }
`;
const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6%;
  margin-right: 6%;
  padding: 0 0 15px;
  flex-wrap: nowwrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const InfoSubSection = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.231818px;
  margin: 1vw 1vh 1vw 1vh;
  width: 45%;
`;

const InfoSubSectionNews = styled(InfoSubSection)`
  width: 40%;
  padding-right: 10px;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    margin-bottom: 30px;
  }
  @media (max-width: 567px) {
    gap: 0;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

const EmailLabel = styled.div`
  color: ${({ theme }) => theme.color};
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 22px;
  letter-spacing: 0.2px;
  &:hover {
    cursor: default;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const NewsLetterEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0 0;
  @media (max-width: 768px) {
    margin: 0.3vh 0vh 0.3vh 0vh;
  }
`;
const NewsEmail = styled.input`
  outline: 0;
  background: transparent;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.204545px;
  color: ${({ theme }) => theme.color};
  padding: 0 4px 12px 10px;
  opacity: 0.8;
  border-width: 0 0 2px;
  border-color: ${({ theme }) => theme.color};
  width: calc(100% - 42px);
  margin-top: 1vh;
  &:focus {
    border-image: linear-gradient(87.56deg, #016299 4.33%, #f77599 93.97%);
    border-image-slice: 1;
  }
  &::placeholder {
    color: #fff;
  }
`;

const InfoElementTitle = styled.div`
  background: -webkit-linear-gradient(60deg, #016299, #f77599, #f77599);
  -webkit-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
  &:hover {
    /* targeting mobile devices */
    @media (min-width: 280px) {
      cursor: pointer;
    }
    /* targeting laptops */
    @media (min-width: 1024px) {
      cursor: default;
    }
  }
`;
const InfoElement = styled.div`
  padding: 5px 0;
  margin-top: 3px;
  font-size: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.color};
  &:hover {
    color: grey;
  }
`;
const CollapseContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* targeting laptops */
  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;
const LinksSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 6vw 1% 6vw;
  font-weight: 200;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.204545px;
  border-top: 1px solid #ccc;
  color: ${({ theme }) => theme.color};
  align-items: center;
  padding: 14px 5vw 10px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }
`;
const FooterLogo = styled.div`
  position: relative;
  left: -29px;
  margin-top: 2vh;
  width: 100%;
  height: 100%;
  content: url(${({ theme }) => theme.footerLogo});
`;
const Link = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.color};
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin: 1vh 0vw 1vh 1vw;
  }
`;
const IconLink = styled(Link)`
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: 1.5rem;
`;

const Ig = styled.div`
  //content: url(${({ theme }) => theme.footerIgLogo});
  content: url(${InstagramIcon});
  max-width: 100%;
  max-height: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export default Footer;
