// Libraries
import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
// Assets
import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";
import { setSearchedUserDetails } from "../../redux/services/user/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ArtistCardBg = `/icons/cards/artist-card-background.jpg`;
const DjBg = `/icons/cards/dj-bg.png`;

const ArtistCard = ({ id, artist, isEmailVisible = false }) => {
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();
  const userId = null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    getSearchedUser({
      profileId: id,
    }).then((res) => {
      if (res.data["Profile Details"].user === userId) {
        navigate("/profile");
      } else {
        dispatch(setSearchedUserDetails(res.data["Profile Details"]));
        navigate("/users");
        dispatch(setSearchedUserDetails(res.data["Profile Details"]));
      }
    });
  };

  const theMail = artist.user.email || "No Mail Address";

  return (
    <Container onClick={handleClick} style={{ direction: "ltr" }}>
      <ArtistType backgroundImage={artist.userImageUrl || DjBg} />
      <ArtistInfo>
        <TitleContainer>
          <ArtistName>
            {artist.firstName} {artist.lastName}
          </ArtistName>
          <ArtistRole>{artist.userType || ""}</ArtistRole>
        </TitleContainer>
        {isEmailVisible && (
          <ArtistName>
            <ArtistEmail
              href={`mailto:${artist.email || "The Mail Address"}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {theMail}
            </ArtistEmail>
          </ArtistName>
        )}
        <ArtistFollowers></ArtistFollowers>
      </ArtistInfo>
    </Container>
  );
};
ArtistCard.propTypes = {
  id: PropTypes.number,
  artist: PropTypes.object,
  isEmailVisible: PropTypes.bool,
};
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 30%;
  height: 152px;
  border-radius: 15px;
  transition: all ease 200ms;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
      0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }

  @media (max-width: 1280px) {
    min-width: 48%;
  }
  @media (max-width: 767px) {
    min-width: 99%;
  }
`;
const ArtistInfo = styled.div`
  background-image: url(${ArtistCardBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  width: 65%;
  height: 100%;
  // margin-left: -20px;
`;
const ArtistType = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  width: 35%;
  height: 100%;
  border-radius: 20px 20px 0 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20%;
`;
const ArtistName = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  padding-left: 10px;
`;
const ArtistEmail = styled.a`
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  color: #ffffff;
  padding-left: 10px;
`;
const ArtistRole = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.55);
`;
const ArtistFollowers = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.25);
  align-self: flex-end;
  margin-top: auto;
  margin-bottom: 7%;
  margin-right: 2%;
`;
export default ArtistCard;
