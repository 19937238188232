import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import "./BookingCard.css";

const BookingCard = ({ start, end, studioRoom }) => {
    return (
        <div className="main-booking-container">
            <div className="main-booking">
                <ul>
                    <li>
                        <Text>Date {start.slice(0, 10)}</Text>
                    </li>
                    <li>
                        <Text>Room {studioRoom}</Text>
                    </li>
                    <li>
                        <Text>Start {start.slice(11, 19)}</Text>
                    </li>
                    <li>
                        <Text>End {end.slice(11, 19)}</Text>
                    </li>
                </ul>
            </div>
            <div className="booking-line"></div>
        </div>
    );
};

BookingCard.propTypes = {
    start: PropTypes.array,
    end: PropTypes.array,
    studioRoom: PropTypes.string,
};

const Text = styled.p`
    font-weight: 900;
    font-size: 30px;
    color: white;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 52px;
    }
`;
export default BookingCard;
