// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// Components
import Switcher from "./../../components/switcher/Switcher";
import Portfolio from "./screens/Portfolio";
import Modal from "react-bootstrap/Modal";
// Services
import { setSearchedUserFollowers } from "../../redux/services/user/userSlice";
import MyCollaborations from "./screens/MyCollaborations";
import { useGetSearchedUserFollowersMutation } from "../../redux/services/user/userApi";
import { useCreateRoomMutation } from "../../redux/services/chat/chatApi";
import { setActiveChat } from "../../redux/services/chat/chatSlice";
import Genres from "./screens/Genres";
import Followers from "./screens/Followers";
import {
  useIsFollowingMutation,
  useRequestFollowMutation,
} from "../../redux/services/users/usersApi";
import Loading from "../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SoundcloudIcon = "./icons/profile/soundcloud.png";
const YoutubeIcon = "./icons/profile/youtube.png";
const SpotifyIcon = "./icons/profile/spotify.png";
const InstagramIcon = `./icons/profile/instagram.png`;

const ProfileImagePlaceholder = "./icons/common/user.jpg";
const CoverImagePlaceholder = "./icons/profile-background-cover.png";

const UsersProfile = () => {
  const userDetails = useSelector((state) => state.user.searchedUser);

  const navigate = useNavigate();
  const [createRoom] = useCreateRoomMutation();
  const [imageOpen, setImageOpen] = useState(false);
  const [modaleImage, setModaleImage] = useState();
  const Switchers = [
    {
      name: "Portfolio",
      component: <Portfolio></Portfolio>,
    },
    { name: "Genres", component: <Genres></Genres> },
    { name: "Collaborations", component: <MyCollaborations /> },
    // { name: "Following", component: <Following></Following> },
    { name: "Followers", component: <Followers></Followers> },
  ];
  // index of the section
  const [selectedSection, setSelectedSection] = useState(0);
  const [getFollowStatus] = useIsFollowingMutation();
  const [getFollowers] = useGetSearchedUserFollowersMutation();
  const [followRequest] = useRequestFollowMutation();
  const dispatch = useDispatch();
  const [isFollowing, setIsFollowing] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleFollowRequest = () => {
    followRequest({
      profileId: userDetails.user.id,
    })
      .then((res) => {
        res.data?.Response === "Profile Followed" ? setIsFollowing(true) : setIsFollowing(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Sign in to follow");
      });
  };

  const searchedUser = useSelector((state) => state.user.searchedUser);
  const handleMessageRequest = () => {
    const userId = searchedUser.user.id;
    createRoom({ OtherUser: userId }).then((data) => {
      dispatch(setActiveChat(data.data.uuid));
      navigate("/messaging");
    });
    // Join The Room
  };

  useEffect(() => {
    if (userDetails.user) {
      setIsLoading(true);
      getFollowStatus({
        profileId: userDetails.user.id,
      }).then((res) => {
        setIsFollowing(res?.data?.isFollowing);
      });
      getFollowers({
        profileId: userDetails.user.id,
      }).then((res) => {
        setIsLoading(false);
        dispatch(setSearchedUserFollowers(res.data));
      });
    }
  }, [isFollowing]);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Modal
        show={imageOpen}
        onHide={() => {
          setImageOpen(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {modaleImage ? (
          <ModalImage src={modaleImage} style={{ maxHeight: "80vh" }} />
        ) : (
          <ModalImage src="./icons/profile/profile-image.png" />
        )}
      </Modal>
      <PersonalSection>
        <CoverPhoto
          src={
            userDetails?.userCoverImageUrl ? userDetails?.userCoverImageUrl : CoverImagePlaceholder
          }
          onClick={() => {
            setImageOpen(true);
            setModaleImage(userDetails.userCoverImageUrl);
          }}
        />
        <AllContainer>
          <div>
            <ProfileInfoCard>
              <UserDescription>
                {userDetails ? (
                  <>
                    <Text>I am {userDetails.firstName} .</Text>
                  </>
                ) : (
                  <Text>I am {userDetails.firstName} .</Text>
                )}

                <Paragraph>{userDetails.bio ? userDetails.bio : "No bio yet"}</Paragraph>

                <EmailText>
                  {userDetails?.isEmailPrivate ? userDetails.user.email : "Email is hidden"}
                </EmailText>

                <SocialMediaSection>
                  {userDetails?.spotifyLink && (
                    <SocialMediaIcon
                      src={SpotifyIcon}
                      onClick={() => {
                        window.open(userDetails.spotifyLink, "_blank", "noopener,noreferrer");
                      }}
                    />
                  )}

                  {userDetails?.soundcloudLink && (
                    <SocialMediaIcon
                      src={SoundcloudIcon}
                      onClick={() => {
                        window.open(userDetails.soundcloudLink, "_blank", "noopener,noreferrer");
                      }}
                    />
                  )}

                  {userDetails?.youtubeLink && (
                    <SocialMediaIcon
                      src={YoutubeIcon}
                      onClick={() => {
                        window.open(userDetails.youtubeLink, "_blank", "noopener,noreferrer");
                      }}
                    />
                  )}

                  {userDetails?.instagramLink && (
                    <SocialMediaIcon
                      src={InstagramIcon}
                      onClick={() => {
                        window.open(userDetails.instagramLink, "_blank", "noopener,noreferrer");
                      }}
                    />
                  )}
                </SocialMediaSection>
              </UserDescription>
              <ButtonContainer>
                {isFollowing ? (
                  <Button onClick={handleFollowRequest}>Unfollow</Button>
                ) : (
                  <Button onClick={handleFollowRequest}>Follow</Button>
                )}
                <Button onClick={handleMessageRequest}>Message</Button>
              </ButtonContainer>
            </ProfileInfoCard>
          </div>
        </AllContainer>
      </PersonalSection>

      <NonProfileContainer>
        <ProfileImageContainer>
          <ProfileImage
            src={userDetails?.userImageUrl ? userDetails?.userImageUrl : ProfileImagePlaceholder}
            onClick={() => {
              setImageOpen(true);
              setModaleImage(
                userDetails.userImageUrl ? userDetails.userImageUrl : ProfileImagePlaceholder
              );
            }}
          ></ProfileImage>
        </ProfileImageContainer>
        <BorderConnect />
        <ProfileContentContainer>
          <StyledSwitcher
            selected={selectedSection}
            setSelected={setSelectedSection}
            switchers={Switchers}
          ></StyledSwitcher>
          {Switchers[selectedSection].component}
        </ProfileContentContainer>
      </NonProfileContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NonProfileContainer = styled.div`
  width: 100%;
  margin-top: -8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 30;
`;
const PersonalSection = styled.div`
  width: 90%;
  height: auto;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 3.5%;
  padding: 1rem;
  @media (max-width: 567px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 500px;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
`;

const AllContainer = styled.div`
  position: absolute;
`;

const ProfileInfoCard = styled.div`
  width: 40rem;
  height: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  z-index: 20;
  background: linear-gradient(
    326.78deg,
    rgba(1, 98, 153, 0.8) 22.45%,
    rgba(247, 117, 153, 0.8) 89.05%
  );
  border-radius: 20px;
  color: white;
  position: relative;
`;
const UserDescription = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Text = styled.p`
  font-weight: 900;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
`;
const Paragraph = styled.p`
  max-width: 90%;
  min-height: 10vh;
  margin-bottom: 2%;
`;
const EmailText = styled.p`
  margin-bottom: 10px;
`;
const SocialMediaSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-top: 1%;
`;
const SocialMediaIcon = styled.img`
  max-width: 30px;
  max-height: 30px;
  margin-top: auto;
  align-self: flex-end;
  cursor: pointer;
  border-radius: 50%;
  src: ${(props) => props.src};
`;
const ProfileImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  @media (max-width: 767px) {
    margin-right: 1rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
  }
`;
const ProfileImage = styled.img`
  /* src: ${(props) => props.src}; */
  height: 200px;
  cursor: pointer;
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  z-index: 2;
  @media (max-width: 767px) {
    max-width: 130px;
    max-height: 250px;
    bottom: 180px;
    right: 20px;
  }
`;

const BorderConnect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 8vh;
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 20px 0;
    width: 95%;
  }
`;
const ProfileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  min-height: 700px;
  overflow: hidden;
  height: 100%;
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 2px 0 0 2px;
  border-style: solid;
  @media (max-width: 991px) {
    padding: 20px 0;
    width: 95%;
  }
`;
const StyledSwitcher = styled(Switcher)`
  @media (min-width: 240px) {
    width: 95%;
  }
  @media (min-width: 1024px) {
    width: 90%;
    margin-top: 2%;
  }
`;
const ModalImage = styled.img`
  src: ${(props) => props.src};
`;

const ButtonContainer = styled.div`
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const Button = styled.button`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  border-radius: 10px;
  padding: 10px 25px;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  width: max-content;
`;

export default UsersProfile;
