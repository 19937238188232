// Libraries
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import storage from "redux-persist/lib/storage";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
// Reducers
import userReducer from "./services/user/userSlice";
import utilsReducer from "./services/utils/utilsSlice";
import themeReducer from "./services/theme/themeSlice";
// API'S
import { userApi } from "./services/user/userApi";
import { chatApi } from "./services/chat/chatApi";
import { utilsApi } from "./services/utils/utilsApi";
import { postApi } from "./services/post/postApi";
import { videoApi } from "./services/video/videoApi";
import { collaborationApi } from "./services/collaboration/collaborationApi";
import { bookingApi } from "./services/booking/bookingApi";
import bookingSlice from "./services/booking/bookingSlice";
import searchSlice from "./services/search/searchSlice";
import { searchApi } from "./services/search/searchApi";
import usersSlice from "./services/users/usersSlice";
import { usersApi } from "./services/users/usersApi";
import videoSlice from "./services/video/videoSlice";
import chatSlice from "./services/chat/chatSlice";

// combine the app reducers
const reducers = combineReducers({
    user: userReducer,
    chat: chatSlice,
    users: usersSlice,
    utils: utilsReducer,
    theme: themeReducer,
    booking: bookingSlice,
    search: searchSlice,
    video: videoSlice,
    // Add the generated reducer as a specific top-level slice
    [userApi.reducerPath]: userApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [videoApi.reducerPath]: videoApi.reducer,
    [collaborationApi.reducerPath]: collaborationApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [videoApi.reducerPath]: videoApi.reducer,
});

// redux-persist configuration
const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

// configuring the redux-store
const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat([
            userApi.middleware,
            chatApi.middleware,
            usersApi.middleware,
            utilsApi.middleware,
            videoApi.middleware,
            bookingApi.middleware,
            searchApi.middleware,
            videoApi.middleware,
            postApi.middleware,
            // postApi.middleware,
            collaborationApi.middleware,
        ]),
    // 👇 show the devTools only in development
    devTools: process.env.NODE_ENV !== "production",
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
