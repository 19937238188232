// Libraries
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { PropTypes } from "prop-types";
// Component
import Image from "./../image/Image";
// Assets
import {
    IconsBlobUrl,
    // DefaultImagey
} from "./../../services/BlobServices";

const DropIcon = `${IconsBlobUrl}common/Drop-Box-Icon.svg`;
const DropBoxRmvIcon = `${IconsBlobUrl}signup/drop-box-rmv-icon.png`;
const getColor = (props) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isFocused) {
        return "#2196f3";
    }
    return "#eeeeee";
};
const DropBox = ({ className, image, setImage }) => {
    const onDrop = useCallback((acceptedFile) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setImage({ src: e.target.result });
        };
        reader.readAsDataURL(acceptedFile[0]);
        return acceptedFile[0];
    }, []);
    const removeImage = () => {
        setImage({
            src: "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492",
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            "image/*": [".jpeg", ".jpg", ".png"],
        },
        onDrop,
        noClick: true,
        noKeyboard: true,
    });
    return (
        <Container className={className} data-testid="drop-box">
            <ImageContainer>
                <RemoveIcon onClick={removeImage} />
            </ImageContainer>
            <DropArea
                style={{ borderColor: (props) => getColor(props) }}
                {...getRootProps()}
                onClick={open}
            >
                <input {...getInputProps()} />
                {!image.src.length ? (
                    <DropBoxIcon />
                ) : (
                    <StyledImage image={image} />
                )}
            </DropArea>
            <ImageSpecs>JPG should be 1920 by 1080px.</ImageSpecs>
        </Container>
    );
};

DropBox.propTypes = {
    className: PropTypes.string,
    setImage: PropTypes.func,
    image: PropTypes.object,
};
const Container = styled.div`
    max-width: 50%;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    position: relative;
    justify-content: center;

    @media (min-width: 641) {
        width: 100%;
        align-items: center;
    }
`;
const ImageContainer = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
`;
const RemoveIcon = styled.img.attrs({ src: DropBoxRmvIcon })`
    width: fit-content;
    justify-self: flex-end;
    cursor: pointer;
`;
const DropArea = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
        height: 220px;
    }
`;
const DropBoxIcon = styled.img.attrs({ src: DropIcon })`
    max-width: 100%;
    max-height: 100%;
`;
const StyledImage = styled(Image)`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 16px;
    align-self: center;
`;
const ImageSpecs = styled.div`
    width: 220px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    margin-top: 7px;
    @media (max-width: 767px) {
        width: 100%;
    }
`;
export default DropBox;
