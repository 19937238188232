import { cookies } from "../App";
export const authToken = "AUTH_TOKEN";
export const refreshToken = "REFRESH_TOKEN";

// assigning cookie config
const cookieOptions = {
    path: "/",
    // httpOnly,
    sameSite: "strict",
};
export const setItem = (key, value) => {
    cookies.set(key, value, cookieOptions);
};
export const getItem = (key) => {
    return cookies.get(key);
};
export const removeItem = (key) => {
    cookies.remove(key, cookieOptions);
};
export const getAuthToken = () => {
    return cookies.get(authToken);
};
export const setAuthToken = (value) => {
    cookies.set(authToken, value, cookieOptions);
};
export const getRefreshToken = () => {
    return cookies.get(refreshToken);
};
export const setRefreshToken = (value) => {
    cookies.set(refreshToken, value, cookieOptions);
};
export const removeAuthToken = () => {
    cookies.remove(authToken);
};
export const removeRefreshToken = () => {
    cookies.remove(refreshToken);
};
