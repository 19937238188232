// Libraries
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
const BoxContainer = ({ className, style, children }) => {
    return (
        <Box className={className} style={style}>
            {children}
        </Box>
    );
};
BoxContainer.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
const Box = styled.div`
    background: linear-gradient(
        145.36deg,
        rgba(244, 244, 244, 0.4) -4.23%,
        rgba(244, 244, 244, 0.2) 102.58%
    );
    backdrop-filter: blur(20px);
    border-radius: 25px;
    border: #f4f4f4 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export default BoxContainer;
