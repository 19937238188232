import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";
const MuzeButton = ({ type, name, className, style, children, onClick }) => {
    return (
        <Button
            as={motion.button}
            type={type}
            name={name}
            style={style}
            className={className}
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            id="muze-button"
        >
            {children}
        </Button>
    );
};

MuzeButton.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
};

const Button = styled.button`
    background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
    border-radius: 10px;
    padding: 10px 25px;
    outline: none;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    width: max-content;
`;

export default MuzeButton;
