import React, { useState, useEffect } from "react";
import { useGetSearchedUserFollowersMutation } from "../../../redux/services/user/userApi";
import styled from "styled-components";
import ArtistCard from "../../../components/cards/ArtistCard";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";

const Followers = () => {
  const { user } = useSelector((state) => state.user.searchedUser);
  const [getFollowers] = useGetSearchedUserFollowersMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [userFollowers, setUserFollowers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getFollowers({
      profileId: user.id,
    }).then((res) => {
      setUserFollowers(res.data);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {userFollowers.length ? (
        <ContentContainer>
          <CardContainer>
            {userFollowers.map((follow) => (
              <ArtistCard
                key={follow.user}
                id={follow.user.id}
                artist={follow}
                setIsLoading={setIsLoading}
              />
            ))}
          </CardContainer>
        </ContentContainer>
      ) : (
        <Container>
          <Text>No followers yet</Text>
        </Container>
      )}
    </>
  );
};

const CardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
  /* overflow: auto; */
  gap: 25px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;
const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 20px;
`;
export default Followers;
