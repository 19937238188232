import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import "./BookingCard.css";

const BookingCardOwner = ({ start, end, studioRoom }) => {
    return (
        <div className="main-booking-container">
            <ul>
                <li>
                    <Text>Date {start.slice(0, 10)}</Text>
                </li>
                <li>
                    <Text>Room {studioRoom}</Text>
                </li>
                <li>
                    <Text>Start {start.slice(11, 19)}</Text>
                </li>
                <li>
                    <Text>End {end.slice(11, 19)}</Text>
                </li>
                <li>
                    <Button>Delete</Button>
                </li>
            </ul>
        </div>
    );
};
BookingCardOwner.propTypes = {
    start: PropTypes.array,
    end: PropTypes.array,
    studioRoom: PropTypes.string,
};
const Text = styled.p`
    font-weight: 900;
    font-size: 30px;
    color: white;
    @media (max-width: 767px) {
        font-size: 40px;
        line-height: 52px;
    }
`;
const Button = styled.button`
    background: rgb(25, 25, 25);
    background: linear-gradient(
        90deg,
        rgba(25, 25, 25, 1) 27%,
        rgba(36, 36, 36, 1) 97%
    );
    border-radius: 10px;
    padding: 10px 20px;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
`;
export default BookingCardOwner;
