import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
import "./Rooms.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStudioId } from "../../redux/services/booking/bookingSlice";
const Rooms = () => {
    const dispatch = useDispatch();
    const [active, setActive] = useState(0);
    const rooms = useSelector((state) => state.booking.filteredEventsStudios);
    const handleSelect = (selectedIndex) => {
        setActive(selectedIndex);
        dispatch(setCurrentStudioId(selectedIndex + 1));
    };
    useEffect(() => {
        dispatch(setCurrentStudioId(active + 1));
    }, []);
    return (
        <div>
            <Carousel
                interval={null}
                onSelect={handleSelect}
                onSlide={handleSelect}
                activeIndex={active}
            >
                {rooms.map((room) => (
                    <Carousel.Item key={new Date()}>
                        <div className="room-container">
                            <Text>{room.name}</Text>
                        </div>
                        <Carousel.Caption
                            style={{ visibility: "hidden" }}
                        ></Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
    margin: 0% 0% 0% 0%;
`;
export default Rooms;
