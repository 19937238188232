// Libraries
import React from "react";
import styled from "styled-components";
import PostCard from "../../../components/cards/PostCard";
import { useNavigate } from "react-router-dom";
// Components
import Loading from "../../../components/loading/Loading";
// Services
import { useMyPostsQuery } from "../../../redux/services/post/postApi";
// Assets

const PostImg = `./icons/common/post-card-img.svg`;

const Portfolio = () => {
  const navigate = useNavigate();
  const { data: myPosts = [], isLoading: isMyPostsQueryLoading } = useMyPostsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  if (isMyPostsQueryLoading) {
    return <Loading className="mini" />;
  }
  return (
    <Container>
      {myPosts?.posts?.length ? (
        myPosts?.posts?.map((post, index) => (
          <StyledPostCard
            key={post.id}
            cover={post.postCoverUrl.length ? post.postCoverUrl : PostImg}
            isProfile={true}
            onClickHandler={() => {
              navigate("/video", {
                state: post,
              });
            }}
            date={post.createdOn}
          />
        ))
      ) : (
        <Text>No Posts yet</Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 20px;
`;
const StyledPostCard = styled(PostCard)`
  max-width: 100%;
  max-height: 60vh;
  margin: 20px 10px;
`;
export default Portfolio;
