import React from "react";
import styled from "styled-components";
const Sponsoring = () => {
  return (
    <Container>
      <Title>Coming Soon</Title>
      <Paragraph>
        <ParagraphSection>Exciting Changes Are on the Horizon at Muze!</ParagraphSection>
        <ParagraphSection>
          We apologize for any inconvenience, this Feature is still under Development and will be
          launched soon.
        </ParagraphSection>
        <ParagraphSection>
          We can&apos;t wait to share our upcoming booking features with you.
        </ParagraphSection>
        <ParagraphSection>
          Our team has been working hard to bring you an exceptional booking experience that will
          enhance your recording process.
        </ParagraphSection>
        <ParagraphSection>
          Get ready for faster booking, a user-friendly interface, and much more.
        </ParagraphSection>
        <ParagraphSection>We are dedicated to making your journey unforgettable.</ParagraphSection>
        <ParagraphSection>Buckle up!</ParagraphSection>
      </Paragraph>
    </Container>
  );
};
const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;
const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;
const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  padding: 30px 0 20px;
`;
const ParagraphSection = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 18px;
  }
`;
export default Sponsoring;
