import React from "react";
import ReactSelect from "react-select";
import Option from "./Option";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/services/theme/themeSlice";

const DropDown = ({
    selectStyles,
    options,
    isMulti,
    setState,
    allowSelectAll,
    closeMenuOnSelect,
    name,
    className,
    placeholder,
    styles,
    optionsSelected, // for setting userTypes
    isUserTypes,
}) => {
    const { t } = useTranslation();
    const { theme } = useSelector(selectTheme);

    const handleChange = (selected) => {
        if (selected.constructor === Array) {
            // multi value
            setState((prevState) => {
                return {
                    ...prevState,
                    [name]: selected?.map((item) => {
                        return item.value;
                    }),
                };
            });
        } else {
            // single value
            setState((prevState) => {
                if (typeof prevState === "object") {
                    return {
                        ...prevState,
                        [name]: selected.value,
                    };
                } else {
                    return selected.value;
                }
            });
        }
    };

    // Define custom styles for the dropdown options
    const customStyles = {
        option: (provided) => ({
            ...provided,
            backgroundColor: theme === "dark" ? "#202020" : "#f8f8f8",
            color: theme === "dark" ? "#f8f8f8" : "#202020",
            transition: "all 0.4s",
            "&:hover": {
                backgroundColor: theme === "dark" ? "#f8f8f8" : "#202020",
                color: theme === "dark" ? "#202020" : "#f8f8f8",
            },
        }),
    };

    return (
        <ReactSelect
            style={{ border: "0px" }}
            styles={{ ...selectStyles, ...customStyles }} // Merge the custom styles with the provided styles
            className={className}
            options={options}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={false}
            components={{
                Option,
            }}
            onChange={handleChange}
            allowSelectAll={allowSelectAll}
            defaultValue={
                isUserTypes
                    ? optionsSelected.map((item) => {
                          return {
                              value: item,
                              label: item,
                          };
                      })
                    : optionsSelected
            }
            placeholder={
                <DropDownPlaceholder style={styles}>
                    {placeholder ? t(placeholder) : t("select")}...
                </DropDownPlaceholder>
            }
        />
    );
};

DropDown.propTypes = {
    selectStyles: PropTypes.object,
    options: PropTypes.array,
    isMulti: PropTypes.bool,
    setState: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    closeMenuOnSelect: PropTypes.bool,
    placeholder: PropTypes.string,
    styles: PropTypes.string,
    optionsSelected: PropTypes.object,
    isUserTypes: PropTypes.bool,
};

const DropDownPlaceholder = styled.div`
    color: ${({ theme }) => theme.color};
    border: 0px;
`;
export default DropDown;
