// Libraries
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
const VideoPlayer = ({ src, className, coverPhoto }) => {
  const videoRef = useRef(null);

  // Handles Forward and backward video
  const handleKeyPress = (e) => {
    if (videoRef.current) {
      switch (e.keyCode) {
        case 37: // Left arrow key
          videoRef.current.currentTime += 5;
          break;
        case 39: // Right arrow key
          videoRef.current.currentTime -= 5;
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <Video
      className={className}
      ref={videoRef}
      controls
      preload="metadata"
      onKeyDown={handleKeyPress}
      data-testid="video-player"
      style={{ backgroundImage: `url(${coverPhoto})` }}
      controlsList="nodownload"
    >
      <source src={src?.length ? src : null} type="video/mp4"></source>
      Your Browser Dosent Support Our Video Player
    </Video>
  );
};
VideoPlayer.propTypes = {
  incrementViews: PropTypes.func,
  className: PropTypes.string,
  src: PropTypes.object,
  coverPhoto: PropTypes.string,
};
const Video = styled.video`
  height: 70vh;
  width: 100%;
  object-fit: fill;
  border-radius: 20px;
  border: 2px solid black;
  backdrop-filter: blur(22.5px);
  @media (max-width: 767px) {
    max-width: 100%;
  }
  background-size: cover;
  background-repeat: no-repeat;
`;
export default VideoPlayer;
