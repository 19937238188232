// Libraries
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// Services
import {
    selectUser,
    setUserImage,
} from "../../../../redux/services/user/userSlice";
import { isValidName, isValidWebsiteUrl } from "../../../../services/Helpers";
import {
    DefaultImage,
    deleteBlob,
    uploadBlob,
} from "../../../../services/BlobServices";
import { useUpdateMutation } from "../../../../redux/services/user/userApi";
// Components
import MuzeButton from "../../../../components/muzeBtn/MuzeButton";
import Loading from "../../../../components/loading/Loading";
import { toast, ToastContainer } from "react-toastify";
// Assets
import "react-toastify/dist/ReactToastify.css";
import { Flex } from "../../../..";
const StudioEdit = () => {
    const { user } = useSelector(selectUser);
    const dispatch = useDispatch();
    const [formData, setformData] = useState({
        type: "add",
        firstName: user.firstName,
        lastName: user.lastName,
        userImageName: user.userImage.blobName,
        userImageUrl: user.userImage.blobUrl,
        bio: "",
        instagramLink: "",
        facebookLink: "",
        twitterLink: "",
        youtubeLink: "",
    });
    const image = {
        src: formData.userImageUrl || "",
    };
    const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] =
        useUpdateMutation();
    const handleChange = (event) => {
        setformData((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    };
    const checkFormErrors = () => {
        const {
            firstName,
            lastName,
            instagramLink,
            facebookLink,
            youtubeLink,
            twitterLink,
        } = formData;
        let isErrorExist = false;
        if (!isValidName(firstName)) {
            isErrorExist = true;
            document.getElementById("firstName").style.borderColor = "red";
            document.getElementById("firstNameError").style.display = "inline";
            document.getElementById("firstNameInput").style.width = "50%";
        } else {
            document.getElementById("firstName").style.borderColor = "black";
            document.getElementById("firstNameError").style.display = "none";
            document.getElementById("firstNameInput").style.width = "80%";
        }
        if (!isValidName(lastName)) {
            isErrorExist = true;
            document.getElementById("lastName").style.borderColor = "red";
            document.getElementById("lastNameError").style.display = "inline";
            document.getElementById("lastNameInput").style.width = "50%";
        } else {
            document.getElementById("lastName").style.borderColor = "black";
            document.getElementById("lastNameError").style.display = "none";
            document.getElementById("lastNameInput").style.width = "80%";
        }
        if (
            instagramLink.length !== 0 &&
            !isValidWebsiteUrl("instagram", instagramLink)
        ) {
            isErrorExist = true;
            document.getElementById("instagram").style.borderColor = "red";
            document.getElementById("instagramError").style.display = "inline";
            document.getElementById("instagramInput").style.width = "50%";
        } else {
            document.getElementById("instagram").style.borderColor = "black";
            document.getElementById("instagramError").style.display = "none";
            document.getElementById("instagramInput").style.width = "80%";
        }
        if (
            youtubeLink.length !== 0 &&
            !isValidWebsiteUrl("youtube", youtubeLink)
        ) {
            isErrorExist = true;
            document.getElementById("youtube").style.borderColor = "red";
            document.getElementById("youtubeError").style.display = "inline";
            document.getElementById("youtubeInput").style.width = "50%";
        } else {
            document.getElementById("youtube").style.borderColor = "black";
            document.getElementById("youtubeError").style.display = "none";
            document.getElementById("youtubeInput").style.width = "80%";
        }
        if (
            twitterLink.length !== 0 &&
            !isValidWebsiteUrl("twitter", twitterLink)
        ) {
            isErrorExist = true;
            document.getElementById("twitter").style.borderColor = "red";
            document.getElementById("twitterError").style.display = "inline";
            document.getElementById("twitterInput").style.width = "50%";
        } else {
            document.getElementById("twitter").style.borderColor = "black";
            document.getElementById("twitterError").style.display = "none";
            document.getElementById("twitterInput").style.width = "80%";
        }
        if (
            facebookLink.length !== 0 &&
            !isValidWebsiteUrl("facebook", facebookLink)
        ) {
            isErrorExist = true;
            document.getElementById("facebook").style.borderColor = "red";
            document.getElementById("facebookError").style.display = "inline";
            document.getElementById("facebookInput").style.width = "50%";
        } else {
            document.getElementById("facebook").style.borderColor = "black";
            document.getElementById("facebookError").style.display = "none";
            document.getElementById("facebookInput").style.width = "80%";
        }
        return isErrorExist;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        // post to update endpoint if there is no field errors in the form
        if (!checkFormErrors()) {
            // check if there is a new image uploaded
            if (image.src !== formData.userImageUrl) {
                const uploadedImage = await uploadNewImage(image);
                formData.userImageName = uploadedImage.blobName;
                formData.userImageUrl = uploadedImage.blobUrl;
            }
            await update(formData);
        }
    };

    const uploadNewImage = async (image) => {
        const newImageBlob = await uploadBlob(image.src, "img");
        if (newImageBlob.blobUrl) {
            // if image uploaded delete old one if not the default img
            if (user.userImage?.blobUrl !== DefaultImage) {
                await deleteBlob(user.userImage?.blobName);
            }
            dispatch(setUserImage(newImageBlob));
        }

        return newImageBlob;
    };

    useEffect(() => {
        if (isUpdateSuccess) {
            toast.success("Updated information successfully");
        }
    }, [isUpdateSuccess]);

    return isUpdateLoading ? (
        <StyledLoading />
    ) : (
        <>
            <Container onSubmit={handleSubmit}>
                <Flex Align="center" gap="20px">
                    <HeaderTitle>Studio details</HeaderTitle>
                </Flex>
                <br />

                <Flex Align="center" gap="20px">
                    <SectionTitle>Bio</SectionTitle>
                </Flex>
                <BioInputContainer id="bio">
                    {/* <BioInputWrapper> */}
                    <BioTextArea
                        onChange={handleChange}
                        placeholder="Max Characters 240"
                        maxLength={240}
                        name="bio"
                    />
                    {/* </BioInputWrapper> */}
                    <BioCounter style={{ color: "white" }}>
                        {formData.bio.length}/240
                    </BioCounter>
                </BioInputContainer>
                <Flex Align="center" gap="20px">
                    <SectionTitle>What do we have</SectionTitle>
                </Flex>
                <BioInputContainer id="bio">
                    {/* <BioInputWrapper> */}
                    <BioTextArea
                        onChange={handleChange}
                        placeholder="Max Characters 240"
                        maxLength={240}
                        name="bio"
                    />
                    {/* </BioInputWrapper> */}
                    <BioCounter style={{ color: "white" }}>
                        {formData.bio.length}/240
                    </BioCounter>
                </BioInputContainer>
                <Flex Align="center" gap="20px">
                    <SectionTitle>Location</SectionTitle>
                </Flex>
                <InputContainer id="location">
                    <InputWrapper>
                        <Input
                            style={{ color: "black", minHeight: "50px" }}
                            name="locationLink"
                            onChange={handleChange}
                            id="locationInput"
                            placeholder="Type here..."
                        />
                        <FieldError id="instagramError">
                            Please Enter Valid Link
                        </FieldError>
                    </InputWrapper>
                </InputContainer>
                <HeaderTitle>Social</HeaderTitle>
                <InputContainer id="instagram">
                    <InputWrapper>
                        <InputLabel>Instagram</InputLabel>
                        <Input
                            style={{ color: "black" }}
                            name="instagramLink"
                            onChange={handleChange}
                            id="instagramInput"
                            placeholder="Type here..."
                        />
                        <FieldError id="instagramError">
                            Please Enter Valid Instagram Link
                        </FieldError>
                    </InputWrapper>
                </InputContainer>
                <InputContainer id="youtube">
                    <InputWrapper>
                        <InputLabel>Youtube</InputLabel>
                        <Input
                            style={{ color: "black" }}
                            name="youtubeLink"
                            onChange={handleChange}
                            id="youtubeInput"
                            placeholder="Type here..."
                        />
                        <FieldError id="youtubeError">
                            Please Enter Valid Youtube Link
                        </FieldError>
                    </InputWrapper>
                </InputContainer>
                <InputContainer id="facebook">
                    <InputWrapper>
                        <InputLabel>Facebook</InputLabel>
                        <Input
                            style={{ color: "black" }}
                            name="facebookLink"
                            onChange={handleChange}
                            id="facebookInput"
                            placeholder="Type here..."
                        />
                        <FieldError id="facebookError">
                            Please Enter Valid Facebook Link
                        </FieldError>
                    </InputWrapper>
                </InputContainer>
                <InputContainer id="twitter">
                    <InputWrapper>
                        <InputLabel>Twitter</InputLabel>
                        <Input
                            style={{ color: "black" }}
                            name="twitterLink"
                            onChange={handleChange}
                            id="twitterInput"
                            placeholder="Type here..."
                        />
                        <FieldError id="twitterError">
                            Please Enter Valid Twitter Link
                        </FieldError>
                    </InputWrapper>
                </InputContainer>
                <ButtonsContainer>
                    <StyledMuzeButton type="submit">
                        Save Changes
                    </StyledMuzeButton>
                </ButtonsContainer>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    limit={3}
                    rtl={document.dir === "rtl"}
                    pauseOnFocusLoss
                    draggable={true}
                    pauseOnHover
                    toastStyle={{ backgroundColor: "black" }}
                />
            </Container>
        </>
    );
};

export const Container = styled.form`
    width: 90%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
`;
export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => {
        if (theme === "dark") {
            return "rgba(35, 35, 35, 0.75)";
        }
        return "#f8f8f8";
    }};
    border: 1px solid
        ${({ theme }) => {
            if (theme === "dark") {
                return "rgba(35, 35, 35, 0.75)";
            }
            return "#0000002a";
        }};
    border-radius: 8px;
    margin: 15px 0;
`;
const BioInputContainer = styled(InputContainer)`
    textarea {
        padding: 20px 0;
    }
`;
export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2%;
    width: 95%;
    max-width: 100%;
`;

export const InputLabel = styled.div`
    cursor: default;
    font-weight: 500;
    font-size: 20px;
    min-width: 120px;
    width: max-content;
    white-space: nowrap;
    padding: 10px 10px 10px 0;

    [data-theme="dark"] & {
        color: #262626;
    }
`;
export const Input = styled.input`
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
`;
const BioTextArea = styled.textarea`
    width: 80%;
    height: 90px;
    resize: none;
    background: transparent;
    border: none;
    color: #000000a1;
    outline: none;
`;
const BioCounter = styled.div`
    align-self: flex-end;
    margin-right: ${() => (document.dir === "ltr" ? "2%" : "0%")};
    margin-left: ${() => (document.dir === "rtl" ? "2%" : "0%")};
    margin-bottom: 2%;
`;
const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8vh;
`;
const StyledMuzeButton = styled(MuzeButton)``;
const SectionTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.color};
`;

const HeaderTitle = styled.div`
    align-self: flex-start;
    font-size: 20px;
    font-weight: 500;
    margin-top: 2%;
    color: ${({ theme }) => theme.color};
`;
const FieldError = styled.div`
    color: red;
    display: none;
    min-width: fit-content;
`;
const StyledLoading = styled(Loading)`
    width: 100%;
    height: 100%;
`;

export default StudioEdit;
