// Libraries
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import arLocale from "i18n-iso-countries/langs/ar.json";
// retrieve all the countries
export const getCountries = (lng) => {
    countries.registerLocale(enLocale);
    countries.registerLocale(arLocale);
    const countryObj = countries.getNames(localStorage.getItem("i18nextLng"), {
        select: "official",
    });
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return value;
    });
    return countryArr;
};
// convert string to camel case
export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .split(" ")
        .map((word, index) => {
            if (index) {
                return word[0].toUpperCase() + word.slice(1);
            }
            return word;
        })
        .join("");
};
// validator for arabic and english names
export const isValidName = (name) => {
    const expression = "^([A-za-z]+|[\u0621-\u064A]+){3,}$";
    return new RegExp(expression, "i").test(name);
};
// validator to check if the password contains at least 1 uppercase letter,lower case,number,symbol and min 8 characters
export const isStrongPassword = (password) => {
    const expression =
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
    return new RegExp(expression).test(password);
};
// validator to check if the website url is valid
export const isValidWebsiteUrl = (website, url) => {
    const expression = `^(http|https):\/\/www\.${website}\.com\/[A-Za-z0-9]*[^\s]`; //eslint-disable-line
    return new RegExp(expression).test(url);
};

export const isValidSpotifyUrl = (url) => {
    const expression =
        /^(https?:\/\/)?(open\.spotify\.com|play\.spotify\.com)\/(track|album|playlist|artist|user\/[a-zA-Z0-9]+\/playlist)\/[a-zA-Z0-9]+$/; //eslint-disable-line
    return new RegExp(expression).test(url);
};

export const isValidYoutubeUrl = (url) => {
    const expression = /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/)?[@\w-]+$/;
    return new RegExp(expression).test(url);
};

export const isValidInstagramUrl = (url) => {
    const expression =
        /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+(\/\?.*)?$/; //eslint-disable-line
    return new RegExp(expression).test(url);
};

export const isValidSoundCloudUrl = (url) => {
    const expression =
        /^(https?:\/\/)?(www\.)?(soundcloud\.com|snd\.sc)\/[^/\s]+(\/\w+)*$/; //eslint-disable-line
    return new RegExp(expression).test(url);
};

export const handleValidation = (evnt, formData) => {
    const { password, confirmPassword } = formData;
    const passwordInputValue = evnt.target.value.trim();
    const setPasswordMessage = evnt.target;
    const passwordInputFieldName = evnt.target.name;
    if (passwordInputFieldName === "password") {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = passwordInputValue.length;
        const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
        const digitsPassword = digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword = minLengthRegExp.test(passwordInputValue);

        let errMsg = "";

        if (passwordLength === 0) {
            errMsg = "Password is empty";
        } else if (!uppercasePassword) {
            errMsg = "At least one Uppercase";
        } else if (!lowercasePassword) {
            errMsg = "At least one Lowercase";
        } else if (!digitsPassword) {
            errMsg = "At least one digit";
        } else if (!specialCharPassword) {
            errMsg = "At least one Special Characters";
        } else if (!minLengthPassword) {
            errMsg = "At least minumum 8 characters";
        } else {
            errMsg = "";
        }
        return setPasswordMessage.setCustomValidity(errMsg);
    }
    // for confirm password
    if (
        passwordInputFieldName === "confirmPassword" ||
        (passwordInputFieldName === "password" && confirmPassword > 0)
    ) {
        if (password !== passwordInputValue) {
            setPasswordMessage.setCustomValidity(
                "Confirm password is not matched"
            );
        } else {
            setPasswordMessage.setCustomValidity("");
        }
    }
};
