// Libraries
import React, { useCallback, useState } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
// Components
import { toast, ToastContainer } from "react-toastify";
import MuzeButton from "../muzeBtn/MuzeButton";

const DropIcon = `/icons/common/Drop-Box-Icon.svg`;

const FileDropBox = ({ setFile }) => {
    const [videoChose, setVideoChose] = useState(false);
    const onDrop = useCallback((acceptedFile, rejectedFile) => {
        if (acceptedFile.length) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFile({ src: e.target.result });
                setVideoChose(true);
            };
            reader.readAsDataURL(acceptedFile[0]);
            return acceptedFile[0];
        }
        toast.error("Unsupported File Type");
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: {
            "video/*": [".mp4", ".webm", ".ogg", ".mov", ".wav"],
        },
        maxFiles: 2,
    });

    return (
        <FileContainer
            {...getRootProps()}
            onClick={open}
            data-testid="file-dropbox"
        >
            <Text>Drag and Drop here</Text>
            <img src={DropIcon} />
            <input {...getInputProps()} />
            <Text>or</Text>
            <StyledMuzeButton>Select File</StyledMuzeButton>
            {videoChose && <p>Selected</p>}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                limit={3}
                rtl={document.dir === "rtl"}
                pauseOnFocusLoss
                draggable={true}
                pauseOnHover
                toastStyle={{ backgroundColor: "black" }}
            />
        </FileContainer>
    );
};

FileDropBox.propTypes = {
    setFile: PropTypes.func,
    setCover: PropTypes.func,
    onPostDropHandler: PropTypes.func,
};

const FileContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;
const Text = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
`;
const StyledMuzeButton = styled(MuzeButton)`
    border-radius: 4px;
    width: 100%;
    height: 15%;
`;
export default FileDropBox;
