import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { PropTypes } from "prop-types";
import styled from "styled-components";

const StyledProgressBar = styled(ProgressBar)`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  .progress-bar {
    background-color: #ffc107;
  }
  min-height: 20px;
`;

const UploadBar = ({ progress, style }) => {
  return (
    <div>
      <StyledProgressBar now={progress} label={`${progress} %`} style={style} />
    </div>
  );
};

UploadBar.propTypes = {
  progress: PropTypes.number,
  style: PropTypes.object,
};

export default UploadBar;
