// Libraries
import React, { useState } from "react";
import styled from "styled-components";
// Components
import ProfileSettings from "./pages/ProfileSettings";
import PaymentSettings from "./pages/PaymentSettings";
import PasswordSettings from "./pages/PasswordSettings";
import Switcher from "./../../components/switcher/Switcher";
const Settings = () => {
    const Switchers = [
        { name: "Profile", component: <ProfileSettings /> },
        { name: "Password", component: <PasswordSettings /> },
        { name: "Payments", component: <PaymentSettings /> },
        { name: "Notifications", component: <div>Notifications</div> },
        { name: "Security and Privacy", component: <div>Security</div> },
    ];
    // index of the switcher
    const [selected, setSelected] = useState(0);

    return (
        <Container>
            <Box>
                <StyledSwitcher
                    switchers={Switchers}
                    setSelected={setSelected}
                    selected={selected}
                ></StyledSwitcher>
                {Switchers[selected].component}
            </Box>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
`;
const Box = styled.div`
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    @media (min-width: 240px) {
        width: 100%;
    }
    @media (min-width: 1024px) {
        width: 65%;
    }
`;
const StyledSwitcher = styled(Switcher)`
    width: 100%;
    @media (min-width: 240px) {
    }
    @media (min-width: 1024px) {
        width: 100%;
    }
`;
export default Settings;
