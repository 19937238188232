// Libraries
import React, { lazy, Suspense, useEffect } from "react";
import Cookies from "universal-cookie";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createBrowserHistory } from "history";
import { useSelector, useDispatch } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { useMediaQuery } from "react-responsive";
// Stylings
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import GlobalStyles from "./styles/Global";
import { darkTheme, lightTheme } from "./styles/Theme.styled";
// Services
import { selectTheme } from "./redux/services/theme/themeSlice";
import { selectUser, signout } from "./redux/services/user/userSlice";
import { getAuthToken } from "./services/cookieManager";
// Components
import Footer from "./components/footer/Footer";
import PublicRoute from "./services/PublicRoute";
import Navbar from "./components/navbar/Navbar";
import Loading from "./components/loading/Loading";
import NotFound from "./features/not-found/NotFound";
import SideMenu from "./components/sidemenu/SideMenu";
import MobileSideMenu from "./components/sidemenu/MobileSideMenu";
import ProtectedRoute from "./services/ProtectedRoute";
import AllCollaborations from "./features/collaborations/AllCollaborations";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import CollaborationProfile from "./features/collaborations/CollaborationProfile";
// import Booking from "./features/booking/Booking";
import VideoScreen from "./features/videoScreen/VideoScreen";
import Settings from "./features/settings/Settings";
import Profile from "./features/profile/Profile";
import Sponsoring from "./features/sponsoring/Sponsoring";
import Feedback from "./features/feedback/Feedback";
import MyCollaborationsRequests from "./features/collaborations/MyCollaborationsRequests";
import PostCollabortaion from "./features/collaborations/PostCollabortaion";
import BookingStudio from "./features/booking/BookingStudio";
import SearchPage from "./features/search-list/SearchPage";
import UsersProfile from "./features/users-profiles/UsersProfile";
import MyStudioDetails from "./features/profile/screens/studio/MyStudioDetails";
import Report from "./features/report/Report";
import WaitingForAuth from "./features/signup/screens/waitingForAuthentication/WaitingForAuth";
import Blank from "./features/blank/Blank";
import ComingSoon from "./features/coming-soon/ComingSoon";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Messaging from "./features/messaging";
import Careers from "./features/Careers/Careers";
import ContactUs from "./features/ContactUs/ContactUs";
import FAQs from "./features/FAQs/FAQs";
import OurStory from "./features/OurStory/OurStory.js";
import PrivacyPolicy from "./features/privacyPolicy/PrivacyPolicy.js";

// code splitting for lazy loading
const Signup = lazy(() => import("./features/signup/Signup.js"));
const Signin = lazy(() => import("./features/signin/Signin.js"));
const Landing = lazy(() => import("./features/landing/Landing"));
const NewsFeed = lazy(() => import("./features/news-feed/NewsFeed"));
const CollabortaionsMenu = lazy(() => import("./features/collaborations/CollaborationsMenu.js"));

// initializing the browser History;
export const history = createBrowserHistory();
// initializing cookies
export const cookies = new Cookies();
const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  // Redux theme state (light || dark)
  const { theme } = useSelector(selectTheme);
  // user session state
  const { signedOut } = useSelector(selectUser);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  // checks if user is signedin, if not dispatch signout action
  const checkSession = () => {
    const token = getAuthToken();
    if (!token || token === "undefined" || !token.length || token === undefined) {
      dispatch(signout());
    }
  };
  // on any render check if the user session valid
  useEffect(() => {
    checkSession();
  }, []);
  // setting document dir from the i18n library dir when language changes
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);
  return (
    // using unstable history router to perform navigation in non functional components
    <GoogleOAuthProvider clientId="953112050834-r7uf5lbvo3umk82rsk93r5sgr3i22n57.apps.googleusercontent.com">
      <Router>
        <ScrollToTop />
        {/* suspending with loading component for lazy loading */}
        <Suspense fallback={<Loading />}>
          <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
            <GlobalStyles />
            <StyledApp className="App">
              {/* wrapping components with Auth and Protected route to detect authorization */}
              <Navbar />
              <ContentContainer>
                {!signedOut && !isTabletOrMobile ? <SideMenu /> : null}
                <Routes>
                  <Route path="/" exact element={<Navigate to="/explore" />} />
                  <Route path="/explore" exact element={<Landing />} />
                  <Route
                    path="/home"
                    exact
                    element={
                      <ProtectedRoute>
                        <NewsFeed />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/messaging"
                    exact
                    element={
                      <ProtectedRoute>
                        <Messaging />
                        {/* <ComingSoon /> */}
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/search-list"
                    exact
                    element={
                      <ProtectedRoute>
                        <SearchPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaborations"
                    exact
                    element={
                      <ProtectedRoute>
                        <CollabortaionsMenu />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaboration/post"
                    exact
                    element={
                      <ProtectedRoute>
                        <PostCollabortaion />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaborations/all"
                    exact
                    element={
                      <ProtectedRoute>
                        <AllCollaborations />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaborations/musicians/"
                    exact
                    element={
                      <ProtectedRoute>
                        <AllCollaborations />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaborations/genre"
                    exact
                    element={
                      <ProtectedRoute>
                        <AllCollaborations />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaboration/view/:id"
                    exact
                    element={
                      <ProtectedRoute>
                        <CollaborationProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/collaboration/requests"
                    exact
                    element={
                      <ProtectedRoute>
                        <MyCollaborationsRequests />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/bookings"
                    exact
                    element={
                      <ProtectedRoute>
                        {/* <Booking /> */}
                        <ComingSoon />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/mystudio"
                    exact
                    element={
                      <ProtectedRoute>
                        <MyStudioDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/booking/studio"
                    exact
                    element={
                      <ProtectedRoute>
                        <BookingStudio />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    exact
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/video" exact element={<VideoScreen />} />
                  <Route
                    path="/settings"
                    exact
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/feedback" exact element={<Feedback />} />
                  <Route path="/users" element={<UsersProfile />} />
                  <Route path="/sponsoring" element={<Sponsoring />} />
                  <Route
                    path="/signin"
                    element={
                      <PublicRoute>
                        <Signin />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <PublicRoute>
                        <Signup />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/coming-soon"
                    element={
                      <ProtectedRoute>
                        <ComingSoon />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/waiting-auth"
                    exact
                    element={
                      <PublicRoute>
                        <WaitingForAuth />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/user"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/report"
                    element={
                      <ProtectedRoute>
                        <Report />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/blank"
                    element={
                      <ProtectedRoute>
                        <Blank />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/careers" exact element={<Careers />} />
                  <Route path="/contact-us" exact element={<ContactUs />} />
                  <Route path="/faqs" exact element={<FAQs />} />
                  <Route path="/our-story" exact element={<OurStory />} />
                  <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />

                  <Route path="*" element={<NotFound />} />
                </Routes>
                {/* Render Mobile SideMenu */}
                {isTabletOrMobile && !signedOut ? <MobileSideMenu /> : null}
              </ContentContainer>
              <Footer />
            </StyledApp>
          </ThemeProvider>
        </Suspense>
      </Router>
    </GoogleOAuthProvider>
  );
};
const StyledApp = styled.div`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 70%;
  background-image: url(${({ theme }) => theme.backgroundImage});
`;
const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;
export default App;
