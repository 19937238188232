// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PostCard from "../../../components/cards/PostCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Loading from "../../../components/loading/Loading";
// Services
import { useGetPostsMutation } from "../../../redux/services/users/usersApi";
import { PropTypes } from "prop-types";
// Assets
const PostImg = `./icons/common/post-card-img.svg`;

const Portfolio = () => {
  const { user } = useSelector((state) => state.user.searchedUser);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [myPosts, setMyPosts] = useState([]);
  const [getMyPosts] = useGetPostsMutation();

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      getMyPosts({
        userId: user.id,
      }).then((res) => {
        setMyPosts(res.data);
        setIsLoading(false);
      });
    }
  }, [user]);

  if (isLoading) {
    return <Loading className="mini" />;
  }

  return (
    <Container>
      {myPosts?.posts?.length ? (
        myPosts?.posts?.map((post, index) => (
          <StyledPostCard
            key={index}
            cover={post.postCoverUrl.length ? post.postCoverUrl : PostImg}
            isProfile={true}
            onClickHandler={() => {
              navigate("/video", {
                state: post,
              });
            }}
            date={post.createdOn}
          />
        ))
      ) : (
        <Text>No Posts Yet</Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 20px;
`;
const StyledPostCard = styled(PostCard)`
  max-width: 100%;
  max-height: 60vh;
  margin: 20px 10px;
`;

Portfolio.propTypes = {
  id: PropTypes.number,
};

export default Portfolio;
