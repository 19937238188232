import React from "react";
import styled from "styled-components";

const OurStory = () => {
  return (
    <Container>
      <Title>Our Story:</Title>
      <Paragraph>
        <ParagraphSection>Muze: Where Music Finds its Community</ParagraphSection>
        <ParagraphSection>
          Our journey began two years ago when Youssef Hamza and Mohamed Gabr, driven by a lifelong
          passion for music, set out to redefine how musicians connect.
        </ParagraphSection>
        <ParagraphSection>
          As the world grappled with the challenges of the COVID-19 pandemic, we felt a deep longing
          for genuine connections within the music community.
        </ParagraphSection>
        <ParagraphSection>
          Dissatisfied with existing platforms that seemed to overlook this need, we founded Muze—a
          unique platform that combines the best features of various social
        </ParagraphSection>
        <ParagraphSection>
          networks to facilitate meaningful connections among musicians worldwide.
        </ParagraphSection>
        <ParagraphSection>
          At Muze, we&apos;re committed to fostering collaboration, sparking creativity, and
          nurturing the bonds that make music thrive.
        </ParagraphSection>
        <ParagraphSection>Join us in shaping the future of music networking.</ParagraphSection>
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;

const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  padding: 30px 0 20px;
`;

const ParagraphSection = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 18px;
  }
`;

export default OurStory;
