import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "./cookieManager";
import { useDispatch } from "react-redux";
import { signout } from "../redux/services/user/userSlice";

// Public route for routes that dont need authentication
const PublicRoute = ({ children }) => {
    // navigate hook for routing
    const navigate = useNavigate();
    // dispatch hook for dispatching actions to the redux store
    const dispatch = useDispatch();
    useEffect(() => {
        // check if user is authorized then redirect to landing page
        // else logout the session
        if (getAuthToken()) {
            navigate("/explore");
        } else {
            dispatch(signout());
        }
    }, []);

    return children;
};
// adding children for props to accept
PublicRoute.propTypes = {
    children: PropTypes.node.isRequired,
};
export default PublicRoute;
