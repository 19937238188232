import React, { useState } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";

import SendIcon from "../../../../../icons/send.png";
import AttachIcon from "../../../../../icons/attach-file.png";
import { uploadBlob } from "../../../../../services/BlobServices";

import { useSelector } from "react-redux";
import { useUploadFileMutation } from "../../../../../redux/services/chat/chatApi";

const PanelFooter = ({ sendMessage }) => {
  const [message, setMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uuid = useSelector((state) => state.chat.uuid);
  const [uploadFile] = useUploadFileMutation();

  const handleSendMessage = () => {
    sendMessage(message);
    setMessage("");
  };

  const handleAttachClick = () => {
    const fileInput = document.getElementById("chat-file");
    fileInput.click();
    fileInput.addEventListener("change", handleFileChange);
  };

  const handleFileChange = async (e) => {
    console.log(e);
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      const reader = new FileReader();
      reader.onprogress = (event) => {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      };
      reader.onload = async (e) => {
        const fileUrl = e.target.result;
        const attachmentBlob = await uploadBlob(fileUrl, "attachments");

        const attachmentPayload = {
          uuid,
          url: attachmentBlob.blobUrl,
          description: file.name,
        };

        await uploadFile(attachmentPayload);

        await sendMessage(
          `<a href="${attachmentBlob.blobUrl}" download="${file.name}">
            ${file.name}
          </a>`
        );
        setUploading(false);
        setUploadProgress(0);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box>
      <ChatWrapper>
        <ChatInput
          placeholder="Enter a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </ChatWrapper>
      <IconsWrapper>
        <IconWrapper onClick={handleSendMessage}>
          <img src={SendIcon} alt="send icon" />
        </IconWrapper>
        <div style={{ cursor: "pointer" }} onClick={handleAttachClick}>
          <img src={AttachIcon} alt="send icon" />
        </div>
      </IconsWrapper>
      {uploading && <UploadProgress>{uploadProgress}% uploaded</UploadProgress>}
      <input
        type="file"
        id="chat-file"
        style={{
          position: "absolute",
          left: 9000,
          visibility: "hidden",
        }}
      />
    </Box>
  );
};

PanelFooter.propTypes = {
  sendMessage: PropTypes.func,
};

export default PanelFooter;

const Box = styled.div`
  margin: 0 44px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 32px;
  background-color: #0d0d0d;
  border-radius: 10px;
  border: 1px solid #0b0d71;
`;

const ChatWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const ChatInput = styled.input`
  outline: none;
  background-color: transparent;
  border: none;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #3a3a3a;
  border-radius: 10px;
  padding: 8px;
  transition: 0.3s;
  &:hover {
    background-color: #6d6a6a;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const UploadProgress = styled.div`
  color: #fff;
  font-size: 14px;
`;
