import React from "react";
import styled from "styled-components";

const Blank = () => {
    return (
        <TextContainer>
            <Text>
                If you’d like to create a page for your Studio on Muze, please
                contact us on{" "}
                <a
                    href="mailto:muze@muze-network.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    muze@muze-network.com.
                </a>
            </Text>
        </TextContainer>
    );
};

const TextContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10%;
`;

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
`;
export default Blank;
