import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MuzeButton from "./../../../components/muzeBtn/MuzeButton";
import CollabCard from "./../../../components/cards/collaboration/CollabCard";
import { useGetSearchedUserCollaborationsMutation } from "../../../redux/services/users/usersApi";
import { useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
const MyCollaborations = () => {
  // fetch user collaborations from backend and list them in the UI
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user.searchedUser);
  const [getCollaborations] = useGetSearchedUserCollaborationsMutation();
  const [collaborations, setCollabortaions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getCollaborations({
      userId: user.id,
    }).then((res) => {
      setCollabortaions(res.data["User Collaborations"]);
      setIsLoading(false);
    });
  }, []);
  return isLoading ? (
    <Loading className="mini" />
  ) : (
    <Container>
      <CollabortaionWrapper>
        {collaborations.length
          ? collaborations.map((collab) => (
              <StyledCollabCard
                key={collab.pk}
                item={collab}
                firstName={collab.joiningUser.length ? collab.joiningUser[0].username : null}
                title={collab?.title}
                location={collab.location}
                ActionButton={
                  <ActionButton
                    onClick={() => {
                      navigate(`/collaboration/view/${collab.pk}`, {
                        state: {
                          collab,
                        },
                      });
                    }}
                  >
                    View Details
                  </ActionButton>
                }
              />
            ))
          : null}
      </CollabortaionWrapper>
    </Container>
  );
};
const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;

const CollabortaionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  @media (min-width: 240px) {
    margin-top: 6%;
  }
  @media (min-width: 1024px) {
    margin-top: 2%;
  }
`;
const StyledCollabCard = styled(CollabCard)`
  width: 100%;
  margin-bottom: 2%;
`;
const ActionButton = styled(MuzeButton)`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;
  width: fit-content;
  @media (min-width: 240px) {
    height: 7vh;
  }
  @media (min-width: 640px) {
    height: 5vh;
  }
  @media (min-width: 1024px) {
    height: 7vh;
  }
`;
export default MyCollaborations;
