import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectUser } from "../../redux/services/user/userSlice";
import Switcher from "../../components/switcher/Switcher";
import StudioPortfolio from "./StudioPortfolio";
import StudioCard from "../../components/cards/studio/StudioCard";

const studioImages = [
    "https://images.unsplash.com/photo-1598488035139-bdbb2231ce04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3R1ZGlvfGVufDB8fDB8fHww&w=1000&q=80",
    "https://images.unsplash.com/photo-1598653222000-6b7b7a552625?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bXVzaWMlMjBzdHVkaW98ZW58MHx8MHx8fDA%3D&w=1000&q=80",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMTKh4FdRSBCSE5VJUkE-R6y3pZ0f7nj1hGjDf7li7&s",
    "https://img.freepik.com/free-photo/sound-mixer-studio_107420-64845.jpg?w=2000",
    "https://media.istockphoto.com/id/1279654034/photo/studio-microphone-and-pop-shield-on-mic-in-the-empty-recording-studio-with-copy-space.jpg?s=612x612&w=0&k=20&c=hoYDQnNkbwHr4fXKz1eRyxTAXwGZeNvBEKBLqpX-DLg=",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbAiQb8Dmqz6HM1oUcte4Ab7ewR7xTPJao3w&usqp=CAU",
];

const BookingStudio = () => {
    const studioDetails = useSelector((state) => state.booking.selectedStudio);
    const { user } = useSelector(selectUser);
    const Switchers = [
        {
            name: "Portfolio",
            component: (
                <StudioPortfolio details={studioDetails}></StudioPortfolio>
            ),
        },
        { name: "Tags By Users", component: <div>tags</div> },
        { name: "Following", component: <div>Following</div> },
        { name: "Followers", component: <div>Followers</div> },
    ];
    // index of the section
    const [selectedSection, setSelectedSection] = useState(0);
    const [showModal, setShowModal] = useState(false);
    return (
        <Container>
            <Modal
                show={showModal}
                onHide={() => setShowModal(!showModal)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <ModalImage src={user.userImage.blobUrl}></ModalImage>
            </Modal>
            <PersonalSection>
                <StudioCard images={studioImages} />
            </PersonalSection>
            <BorderConnect />
            <ProfileContentContainer>
                <StyledSwitcher
                    selected={selectedSection}
                    setSelected={setSelectedSection}
                    switchers={Switchers}
                ></StyledSwitcher>
                {Switchers[selectedSection].component}
            </ProfileContentContainer>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const PersonalSection = styled.div`
    width: 110%;
    height: 300px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 567px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
    }
`;

const ProfileContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 100%;
    border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
    border-image-slice: 1;
    border-width: 2px 0 0 2px;
    border-style: solid;
    @media (max-width: 991px) {
        padding: 20px 0;
        width: 95%;
    }
`;
const StyledSwitcher = styled(Switcher)`
    @media (min-width: 240px) {
        width: 95%;
    }
    @media (min-width: 1024px) {
        width: 90%;
        margin-top: 2%;
    }
`;
const ModalImage = styled.img`
    src: ${(props) => props.src};
`;

const BorderConnect = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 15vh;
    border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
    border-image-slice: 1;
    border-width: 0px 2px 0px 0px;
    border-style: solid;
    @media (max-width: 991px) {
        padding: 20px 0;
        width: 95%;
    }
`;
export default BookingStudio;
