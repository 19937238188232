import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../baseQuery";

export const searchApi = createApi({
    reducerPath: "searchApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        searchArtist: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/search_artists",
                    method: "post",
                    body,
                };
            },
        }),
        getAllArtists: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/listall_artists",
                    method: "post",
                    body,
                };
            },
        }),
    }),
});

export const { useSearchArtistMutation, useGetAllArtistsMutation } = searchApi;
