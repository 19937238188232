import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useGetStudioRoomDetailsMutation } from "../../../redux/services/booking/bookingApi";
import { setMyRoomDetails } from "../../../redux/services/user/userSlice";
import { useNavigate } from "react-router-dom";

const MyStudios = () => {
    const myStudios = useSelector((state) => state.user.userStudios);
    const [getStudioRoom] = useGetStudioRoomDetailsMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (id) => {
        getStudioRoom({
            StudioRoomId: id,
        }).then((res) => {
            dispatch(setMyRoomDetails(res.data));
            navigate("/mystudio");
        });
    };
    return (
        <>
            {myStudios.length ? (
                <StudiosContainer>
                    {myStudios.map((studio) => (
                        <StudioCard
                            onClick={() => handleClick(studio.id)}
                            key={studio.id}
                        >
                            <StudioImage src="./icons/studio.png" />
                            <StudioProfile>
                                <StudioProfileSection>
                                    <StudioName>{studio.name}</StudioName>
                                </StudioProfileSection>
                                <StudioProfileSectionSeparator />
                                <FacilitiesSection>
                                    <div>Wifi</div>
                                </FacilitiesSection>

                                <StudioProfileSectionSeparator />
                                <StudioProfileSection>
                                    <RatingSection>
                                        5.0 (318 reviews)
                                    </RatingSection>
                                </StudioProfileSection>
                            </StudioProfile>
                        </StudioCard>
                    ))}
                </StudiosContainer>
            ) : (
                <Container>
                    <Text>No studios yet</Text>
                </Container>
            )}
        </>
    );
};

const StudiosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 90%;
    height: 100%;
    overflow-y: auto;
    margin: 1rem;
`;
const StudioCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 50%;
    margin-bottom: 5%;
    border-radius: 1rem;
    cursor: pointer;
    padding: 1rem;
    opacity: 0.7;
    background-color: white;
`;
const StudioImage = styled.img`
    src: ${(props) => props.src};
    width: 30%;
    border-radius: 20px;
`;
const StudioProfile = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 0 10px;
`;
const StudioProfileSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
`;
const StudioName = styled.div`
    color: "black";
    font-weight: 500;
    font-size: 20px;
`;

const StudioProfileSectionSeparator = styled.div`
    width: 20%;
    border-bottom: 2px solid white;
    margin-bottom: 5%;
`;
const FacilitiesSection = styled.div`
    color: #6b7280;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5%;
`;
const RatingSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
`;
const Container = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    overflow-x: auto;
`;

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
`;

export default MyStudios;
