// Libraries
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// Components
import DropDown from "../../components/dropdown/DropDown";
import { toast, ToastContainer } from "react-toastify";
import MuzeButton from "../../components/muzeBtn/MuzeButton";
import { Spacer } from "../signin/Signin";
import { Flex } from "../..";
import { useSubmitFeedbackMutation } from "../../redux/services/user/userApi";
import Loading from "../../components/loading/Loading";
import { useSelector } from "react-redux";
import { selectTheme } from "../../redux/services/theme/themeSlice";
const Report = () => {
    // t hook for translation
    const { t } = useTranslation();
    // selected screen state
    const [screen, setScreen] = useState("");
    // ref for details cause we only care about the value on submitting the form
    const detailsRef = useRef();
    // The api to submit feedback
    const [submit] = useSubmitFeedbackMutation();

    const [isLoading, setIsLoading] = useState(false);
    // screens array
    const screens = [
        "Explore",
        "Home",
        "Profile",
        "Booking",
        "Signin",
        "Signup",
        "Video Screen",
        "Settings",
        "Collaborations",
    ];
    // DropDown Styles
    const ltrBG =
        "linear-gradient(252.51deg, rgba(255, 255, 255, 0.7) 15.97%, rgba(173, 173, 173, 0.658) 100%)";

    const rtlBG =
        " linear-gradient(104.76deg, rgba(255, 255, 255, 0.7) -2.57%, rgba(173, 173, 173, 0.658) 92.68%)";

    const { theme } = useSelector(selectTheme);

    const DropDownStyles = {
        control: (styles) => ({
            ...styles,
            background: "transparent",
            cursor: "pointer",
        }),
        option: (styles, state) => {
            return {
                ...styles,
                color: document.dir === "ltr" ? "black" : "white",
                backgroundColor: state.isSelected ? "#D4A9E4" : "transparent",
                cursor: "pointer",
            };
        },
        dropdownIndicator: (styles) => {
            return {
                ...styles,
                cursor: "pointer",
            };
        },
        singleValue: (styles) => {
            return {
                ...styles,
                color: theme === "dark" ? "white" : "black",
                cursor: "pointer",
            };
        },
        menuList: (styles) => {
            return {
                ...styles,
                // color: document.dir === "ltr" ? "black" : "white",
                background: document.dir === "ltr" ? ltrBG : rtlBG,
                border: "1px solid rgba(255, 255, 255, 0.8)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                backdropFilter: "blur(10px)",
                cursor: "pointer",
            };
        },
        placeholder: (styles) => {
            return {
                ...styles,
                color: document.dir === "ltr" ? "black" : "white",
            };
        },
    };
    // submit function should call backend endpoint if there are no errors regarding the form
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        if (formHasErrors()) {
            toast.error("Please choose a screen and fill up the details");
            setIsLoading(false);
        } else {
            submit({
                title: screen,
                content: detailsRef.current.value,
            }).then(() => {
                setIsLoading(false);
                toast.success("Feedback submitted successfully");
            });
        }
    };
    // function to check if the form has errors
    const formHasErrors = () => {
        if (screen.length && detailsRef.current?.value.length >= 10) {
            return false;
        }
        return true;
    };

    const handleSetScreen = (value) => {
        setScreen(value);
    };
    return isLoading ? (
        <Loading />
    ) : (
        <Container>
            <Title>Help Us Improve</Title>
            <StyledDropDown
                allowSelectAll={false}
                setState={handleSetScreen}
                selectStyles={DropDownStyles}
                name={"screen"}
                isMulti={false}
                placeholder="Choose a screen"
                options={screens.map((value) => {
                    return { value, label: t(value) };
                })}
                optionsSelected={screen}
            />
            <TextArea
                placeholder="Details minimum text is 10 letters"
                ref={detailsRef}
                maxLength={200}
            ></TextArea>
            <Spacer />
            <Flex Justify="end">
                <MuzeButton type={"submit"} onClick={handleSubmit}>
                    Submit
                </MuzeButton>
            </Flex>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                limit={3}
                rtl={document.dir === "rtl"}
                pauseOnFocusLoss
                draggable={true}
                pauseOnHover
                toastStyle={{ backgroundColor: "black" }}
            />
        </Container>
    );
};
const Container = styled.form`
    width: 70%;
    margin: 0 6%;
    height: 700px;
    @media (max-width: 991px) {
        width: 80%;
    }
`;
const Title = styled.div`
    width: max-content;
    font-weight: 600;
    font-size: 24px;
    align-self: flex-start;
    color: ${({ theme }) => theme.color};
    border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
    border-image-slice: 1;
    border-width: 0 0 5px 0;
    border-style: solid;
    padding: 10px 0 5px;
    margin: 20px 0;
`;
const StyledDropDown = styled(DropDown)`
    height: 40px;
    margin-bottom: 20px;
`;
const TextArea = styled.textarea`
    width: 100%;
    height: 60%;
    padding: 20px 10px 10px 20px;
    resize: none;
    border: none;
    color: ${({ theme }) => theme.color};
    outline: none;
    align-self: flex-end;
    background: ${({ theme }) => theme.background};
    border: 1px solid #000000;
    border-radius: 8px;
`;
export default Report;
