import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MuzeButton from "./../../../components/muzeBtn/MuzeButton";
import CollabCard from "./../../../components/cards/collaboration/CollabCard";
import { useGetMyCollabsMutation } from "../../../redux/services/user/userApi";
import { useNavigate } from "react-router-dom";
import { useDeleteCollaborationMutation } from "../../../redux/services/collaboration/collaborationApi";
import { toast } from "react-toastify";

const MyCollaborations = () => {
  const [getMyCollabs] = useGetMyCollabsMutation();
  const [deleteCollab] = useDeleteCollaborationMutation();

  const [myCollabs, setMyCollabs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getMyCollabs().then((res) => setMyCollabs(res.data["own collaborations"]));
  }, []);

  const handleViewDetails = (collab) => {
    navigate(`/collaboration/view/${collab.pk}`, {
      state: {
        collab,
      },
    });
  };

  const handleDeleteCollab = (event, collabId) => {
    event.stopPropagation();
    deleteCollab({ collaborationId: collabId }).then(() => {
      toast.success(`Collaboration deleted successfully`);
      getMyCollabs().then((res) => setMyCollabs(res.data["own collaborations"]));
    });
  };

  return (
    <Container>
      <CollabortaionWrapper>
        {myCollabs.length
          ? myCollabs.map((collab) => (
              <StyledCollabCard
                key={collab.pk}
                item={collab}
                title={collab?.title}
                location={collab.location}
                ActionButton={
                  <ActionButtonContainer>
                    <ActionButton onClick={() => handleViewDetails(collab)}>
                      View Details
                    </ActionButton>
                    <ActionButton onClick={(event) => handleDeleteCollab(event, collab.pk)}>
                      Delete Post
                    </ActionButton>
                  </ActionButtonContainer>
                }
              />
            ))
          : null}
      </CollabortaionWrapper>
    </Container>
  );
};
const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
`;

const CollabortaionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  @media (min-width: 240px) {
    margin-top: 6%;
  }
  @media (min-width: 1024px) {
    margin-top: 2%;
  }
`;
const StyledCollabCard = styled(CollabCard)`
  width: 100%;
  margin-bottom: 2%;
`;
const ActionButton = styled(MuzeButton)`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  @media (min-width: 240px) {
    height: 7vh;
  }
  @media (min-width: 640px) {
    height: 5vh;
  }
  @media (min-width: 1024px) {
    height: 7vh;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export default MyCollaborations;
