// Libraries
import React from "react";
import ReactDOM from "react-dom/client";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en"; // Import other locales as needed
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// Services
import store from "./redux/store";
import "./services/i18n";
// Components
import App from "./App";
import Loading from "./components/loading/Loading";
// Assets
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import styled from "styled-components";
// Animated Guide Global Css
import "intro.js/introjs.css";

export const Flex = styled.div`
  display: flex;
  justify-content: ${(props) => props.Justify || "initial"};
  align-items: ${(props) => props.Align || "initial"};
  gap: ${(props) => props.gap || "10px"};
  flex-direction: ${(props) => props.Direction || "row"};
  width: ${(props) => props.wisth || "100%"};
`;

// Redux persistor for persisting web app state against refresh
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));

TimeAgo.addDefaultLocale(en);
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
