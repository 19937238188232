import React from "react";
import styled from "styled-components";

const Report = () => {
    return (
        <Container>
            <ReportContainer>
                <ReportTitle>Contact us ?</ReportTitle>
            </ReportContainer>
        </Container>
    );
};
const Container = styled.div`
    width: 100%;
    height: inhert;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ReportContainer = styled.div`
    width: 80%;
    height: 90%;
    display: flex;
`;
const ReportTitle = styled.p`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 40px;
`;

export default Report;
