import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";

const MuzeInput = ({
    id,
    name,
    type,
    autoComplete,
    placeholder,
    onChange,
    required,
    isDark,
}) => {
    return (
        <Input
            id={id}
            name={name}
            type={type}
            autoComplete={autoComplete}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            data-testid={"muze-input"}
        ></Input>
    );
};
const Input = styled.input`
    width: 70%;
    outline: 0;
    background: transparent;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.204545px;
    color: ${(props) => (props.isDark ? "#ffffff" : "#000000")};
    padding: 0.4vw 0.4vh 0.6vw 0.4vh;
    border-width: 0 0 2px;
    border-color: #ffffff;
    padding-bottom: 4vh;
    margin: 10px;
    z-index: 2;
    &::placeholder {
        color: #ffffff;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
    }
    /* targeting mobile devices */
    @media (min-width: 280px) {
        width: 70%;
        line-height: 16px;
        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }
    }

    /* targeting tablets and ipad */
    @media (min-width: 641px) {
        padding-bottom: 2vh;
        width: 60%;
        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
        }
    }

    /* targeting laptops */
    @media (min-width: 1024px) {
        padding-bottom: 4vh;
        width: 70%;
        line-height: 19px;
        &::placeholder {
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
        }
    }
`;

MuzeInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    isDark: PropTypes.bool,
};
export default MuzeInput;
