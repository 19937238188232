import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../baseQuery";

export const bookingApi = createApi({
    reducerPath: "bookingApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        searchBooking: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/studio_booking/search_rooms",
                    method: "post",
                    body,
                };
            },
        }),
        getSlots: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/studio_booking/list_bookings",
                    method: "post",
                    body,
                };
            },
        }),
        book: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/studio_booking/create_booking",
                    method: "post",
                    body,
                };
            },
        }),
        delete: builder.mutation({
            query: (body) => {
                return {
                    url: "social/api/booking/delete_event",
                    method: "post",
                    body,
                };
            },
        }),
        getMyBookings: builder.mutation({
            query: (body) => {
                return {
                    url: "social/api/studio_booking/list_user_bookings",
                    method: "post",
                    body,
                };
            },
        }),
        getStudioRoomDetails: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/studio_booking/room_details_full",
                    method: "post",
                    body,
                };
            },
        }),
        deleteBooking: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/studio_booking/delete_booking",
                    method: "post",
                    body,
                };
            },
        }),
        getStudioImages: builder.mutation({
            query: (body) => {
                return {
                    url: "/social/api/booking/institution_photos",
                    method: "post",
                    body,
                };
            },
        }),
    }),
});

export const {
    useSearchBookingMutation,
    useGetSlotsMutation,
    useDeleteMutation,
    useBookMutation,
    useGetMyBookingsMutation,
    useGetStudioRoomDetailsMutation,
    useDeleteBookingMutation,
    useGetStudioImagesMutation,
} = bookingApi;
