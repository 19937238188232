// Libraries
import React, { useCallback, useState } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
// Components
import { toast, ToastContainer } from "react-toastify";
import MuzeButton from "../muzeBtn/MuzeButton";
const DropIcon = `/icons/common/Drop-Box-Icon.svg`;

const CoverDropBox = ({ setCover }) => {
    const [coverChose, setCoverChose] = useState(false);
    const onDrop = useCallback((acceptedFile, rejectedFile) => {
        if (acceptedFile.length) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCover({ src: e.target.result });
                setCoverChose(true);
            };
            reader.readAsDataURL(acceptedFile[0]);
            return acceptedFile[0];
        }
        toast.error("Unsupported File Type");
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: {
            "image/*": [".png", ".jpeg", ".svg", ".jpg"],
        },
        maxFiles: 1,
    });

    return (
        <FileContainer
            {...getRootProps()}
            onClick={open}
            data-testid="cover-dropbox"
        >
            <Text>Add video cover photo</Text>
            <img src={DropIcon} alt="Drop Icon" />
            <input {...getInputProps()} data-testid="dropzone" />
            <Text>or</Text>
            <StyledMuzeButton>Select File</StyledMuzeButton>
            {coverChose && <p>Selected</p>}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                limit={3}
                rtl={document.dir === "rtl"}
                pauseOnFocusLoss
                draggable={true}
                pauseOnHover
                toastStyle={{ backgroundColor: "black" }}
            />
        </FileContainer>
    );
};

CoverDropBox.propTypes = {
    setCover: PropTypes.func,
    onPostDropHandler: PropTypes.func,
};

const FileContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;
const Text = styled.div`
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
`;
const StyledMuzeButton = styled(MuzeButton)`
    border-radius: 4px;
    width: 100%;
    height: 15%;
`;
export default CoverDropBox;
