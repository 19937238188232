import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ArtistCard from "../../components/cards/ArtistCard";
import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";
import { setSearchedUserDetails } from "../../redux/services/user/userSlice";

const SearchPage = () => {
  const searchResult = useSelector((state) => state.search.searchResult);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();

  const handleClick = (user) => {
    getSearchedUser({
      profileId: user.user.id,
    }).then((res) => {
      dispatch(setSearchedUserDetails(res.data["Profile Details"]));
    });
    navigate("/users");
  };

  return (
    <Container>
      <ContentContainer>
        <Section>
          <CardContainer>
            {searchResult?.length ? (
              <>
                {searchResult.map((search) => (
                  <div
                    key={search.user}
                    style={{ minWidth: "30%" }}
                    onClick={() => handleClick(search)}
                  >
                    <ArtistCard key={search.id} artist={search} id={search.user.id} />
                  </div>
                ))}
              </>
            ) : (
              <>
                <Text>Not found !</Text>
              </>
            )}
          </CardContainer>
        </Section>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2%;
  margin-bottom: 2%;
  flex-grow: 1;
  /* targeting mobile devices */
  @media (min-width: 280px) {
    width: 100%;
  }

  /* targeting tablets and ipad */
  @media (min-width: 821px) {
    width: 80%;
  }
`;
const Section = styled.div`
  margin-top: 2%;
`;
const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;
const CardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
  /* overflow: auto; */
  gap: 25px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Text = styled.p`
  font-weight: 900;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
  color: white;
  text-align: center;
`;
export default SearchPage;
