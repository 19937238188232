import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const WaitingForAuth = () => {
  const isAuthDone = false;
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);
  return (
    <TextContainer>
      {isAuthDone ? (
        <Text>Authentication Done !</Text>
      ) : (
        <>
          <Text>Waiting For authentication...</Text>
          <p style={{ color: "white" }}>Sign in when done</p>
        </>
      )}
    </TextContainer>
  );
};
const TextContainer = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Text = styled.div`
  align-self: center;
  font-size: 30px;
  color: ${({ theme }) => theme.color};
`;
export default WaitingForAuth;
