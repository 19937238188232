import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import styled from "styled-components";

// User Redux
import { useGetSearchedUserDetailsMutation } from "../../../../../redux/services/user/userApi";
import { setSearchedUserDetails, selectUser } from "../../../../../redux/services/user/userSlice";

// Chat Redux
import {
  useCreateRoomMutation,
  useGetRoomsMutation,
  useJoinRoomMutation,
} from "../../../../../redux/services/chat/chatApi";
import {
  setUserChats,
  setUUID,
  setChatMessages,
  setChatData,
} from "../../../../../redux/services/chat/chatSlice";

// Components
import Modal from "react-bootstrap/Modal";
import MessagesChat from "./MessagesChat";
import Search from "../../../../../components/search/Search";

const NewChatModal = ({ show, onHide, setActiveIndex }) => {
  const dispatch = useDispatch();

  // APIs
  const [createRoom] = useCreateRoomMutation();
  const [getRooms] = useGetRoomsMutation();
  const [joinRoom] = useJoinRoomMutation();
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();

  // Selectors
  const searchList = useSelector((state) => state.search.searchList);
  const chats = useSelector((state) => state.chat.userChats);
  const { user } = useSelector(selectUser);

  const onHideHandler = async () => {
    await joinRoom({ uuid: chats[0].uuid }).then((room) => {
      dispatch(
        setChatData(room.data["Profiles in Room"].filter((profile) => profile.user.id !== user.pk))
      );
      dispatch(setUUID(room.data["Room Data"].uuid));
      dispatch(setChatMessages(room.data["Room Data"].messages));
    });
    onHide();
  };

  const createRoomHandler = async (Chat) => {
    await createRoom({ OtherUser: Chat.user.id });
    await getRooms({ userId: user.pk }).then((rooms) => {
      dispatch(setUserChats(rooms.data["User Rooms"]));
      joinRoom({ uuid: rooms.data["User Rooms"][0].uuid }).then((room) => {
        dispatch(setChatMessages([]));
        dispatch(
          setChatData(
            room.data["Profiles in Room"].filter((profile) => profile.user.id !== user.pk)
          )
        );
        dispatch(setUUID(room.data["Room Data"].uuid));
        dispatch(setChatMessages(room.data["Room Data"].messages));
      });
    });
    setActiveIndex(0);
    onHide();
  };

  const handleSearch = (user) => {
    getSearchedUser({
      profileId: user.user,
    }).then((res) => {
      dispatch(setSearchedUserDetails(res.data["Profile Details"]));
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHideHandler}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Header
        style={{
          backgroundColor: "#171717",
          color: "white",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">Create New Chat</Modal.Title>
      </Header>
      <Modal.Body
        style={{
          backgroundColor: "#171717",
          height: "480px",
          overflow: "hidden",
        }}
      >
        <SearchWrapper>
          <Headline>To</Headline>
          <SearchInputWrapper>
            <StyledSearch
              type="text"
              placeholder="Search For User"
              onChange={handleSearch}
              messaging={true}
              onHide={onHideHandler}
              noSuggestions={true}
              isForChat={true}
            />
          </SearchInputWrapper>
        </SearchWrapper>
        <ProfilesWrapper>
          {searchList &&
            searchList
              ?.slice(0, 4)
              ?.map((chat, index) => (
                <MessagesChat chat={chat} key={index} onClick={() => createRoomHandler(chat)} />
              ))}
        </ProfilesWrapper>
      </Modal.Body>
    </Modal>
  );
};

NewChatModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setShowModal: PropTypes.func,
  setChatData: PropTypes.func,
  setActiveIndex: PropTypes.func,
};

const Header = styled(Modal.Header)`
  justify-content: center;
  border: none;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Headline = styled.h3`
  color: #fff;
  font-size: 19px;
`;
const SearchInputWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 22px;
  padding: 15px;
  padding-left: 20px;
  background-color: #0d0d0d;
  border-radius: 10px;
`;

const StyledSearch = styled(Search)`
  max-width: 600px;
  @media (max-width: 567px) {
    display: none;
  }
  input {
    html[data-theme="dark"] & {
      color: #fff !important;
    }
  }
`;

const ProfilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export default NewChatModal;
