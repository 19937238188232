// Libraries
import React, { useCallback, useState } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
// Components
import { toast, ToastContainer } from "react-toastify";
import MuzeButton from "../muzeBtn/MuzeButton";
import { uploadBlob } from "../../services/BlobServices";
import { useUpdateMutation } from "../../redux/services/user/userApi";
import Loading from "../loading/Loading";
const DropIcon = `/icons/common/Drop-Box-Icon.svg`;

const UserCoverDropBox = ({ setBackgroundChange, setCover }) => {
  const coverChose = false;
  const [isLoading, setIsLoading] = useState(false);
  const [update] = useUpdateMutation();
  const onDrop = useCallback((acceptedFile, rejectedFile) => {
    if (acceptedFile.length) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const file = await uploadBlob(e.target.result, "img");
        await update({
          type: "add",
          userCoverImageName: file.blobName,
          userCoverImageUrl: file.blobUrl,
        }).then((res) => {
          setIsLoading(false);
          setBackgroundChange(false);
          toast.success("Updated successfully");
          setCover(file.blobUrl);
        });
      };
      reader.readAsDataURL(acceptedFile[0]);
    }
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [".png", ".jpeg", ".svg", ".jpg", ".PNG"],
    },
    maxFiles: 1,
  });

  return isLoading ? (
    <Loading className="mini" />
  ) : (
    <FileContainer {...getRootProps()} onClick={open}>
      <img src={DropIcon} />
      <input {...getInputProps()} />
      <Text>or</Text>
      <StyledMuzeButton>Select File</StyledMuzeButton>
      {coverChose && <p>Selected</p>}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        limit={3}
        rtl={document.dir === "rtl"}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        toastStyle={{ backgroundColor: "black" }}
      />
    </FileContainer>
  );
};

UserCoverDropBox.propTypes = {
  setCover: PropTypes.func,
  onPostDropHandler: PropTypes.func,
  setBackgroundChange: PropTypes.func,
};
const FileContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0rem;
`;
const Text = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;
const StyledMuzeButton = styled(MuzeButton)`
  border-radius: 4px;
  width: 100%;
  height: 15%;
`;
export default UserCoverDropBox;
