// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../features/signin/Signin";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCollaborationDetailMutation,
  useCollaborationJoinMutation,
} from "../../redux/services/collaboration/collaborationApi";
import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setSearchedUserDetails } from "../../redux/services/user/userSlice";
import ArtistCard from "../../components/cards/ArtistCard";
import Loading from "../../components/loading/Loading";

const CollaborationProfile = () => {
  const { state } = useLocation();
  const { user } = useSelector(selectUser);
  const { requestingUser } = state;
  const { pk } = state;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collabData, setCollabData] = useState([]);

  const [getSearchedUser] = useGetSearchedUserDetailsMutation();
  const [sendCollabData] = useCollaborationJoinMutation();
  const [getSingleCollab] = useCollaborationDetailMutation();

  const [isMyCollab] = useState(requestingUser === user?.pk);
  const [joiningUsers, setJoiningUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onCollabClick = () => {
    sendCollabData({ collabToJoinId: state?.pk })?.then((response) => {
      if (!response?.error) {
        toast.success("Collaboration joined successfully");
      }
    });
  };

  const handleViewProfile = () => {
    getSearchedUser({
      profileId: requestingUser.user.id,
    })
      .then((res) => {
        if (res.data["Profile Details"].user.id === pk) {
          navigate("/profile");
        } else {
          dispatch(setSearchedUserDetails(res.data["Profile Details"]));
          navigate("/users");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleUserClick = () => {};

  useEffect(() => {
    setIsLoading(true);
    getSingleCollab({
      collaborationId: pk,
    }).then((res) => {
      setCollabData(res.data);
      setJoiningUsers(res.data.joiningUserProfiles);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Title>{collabData?.title}</Title>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {!isMyCollab ? (
          <>
            <Button onClick={handleViewProfile} style={{ marginRight: "69px", width: "200px" }}>
              View Profile
            </Button>
            <Button style={{ width: "200px" }} onClick={onCollabClick}>
              Collab
            </Button>
          </>
        ) : (
          <>
            <p style={{ color: "#f3f3f3" }}>
              You are the owner of this collaboration, you cant join it, but you can view the users
              who joined it !
            </p>
          </>
        )}
      </div>
      <Content>
        <Section>
          <SectionTitle> Experience Level: </SectionTitle>
          <SectionText>{collabData?.experienceLevel}</SectionText>
        </Section>
        <Section>
          <SectionTitle> Contact Email: </SectionTitle>
          <SectionText>
            {collabData.joiningUser && (
              <a
                href={`mailto:${collabData?.requestingUser?.email}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{ color: "#fff" }}
              >
                {collabData?.requestingUser?.email}
              </a>
            )}
          </SectionText>
        </Section>
        <Section>
          <SectionTitle> Location: </SectionTitle>
          <SectionText>{collabData?.location}</SectionText>
        </Section>
        <Section>
          <SectionTitle> Application Deadline: </SectionTitle>
          <SectionText>{collabData?.applicationDeadline}</SectionText>
        </Section>
        <Section>
          <SectionTitle>Collab description</SectionTitle>
          <CollabDescription>{collabData?.description}</CollabDescription>
        </Section>
        <Section style={{ width: "100%" }}>
          <SectionTitle> Joined Users:</SectionTitle>
          <CardContainer>
            {joiningUsers.length ? (
              joiningUsers.map((search) => (
                <div
                  key={search.user}
                  style={{ minWidth: "30%" }}
                  onClick={() => handleUserClick(search)}
                >
                  <ArtistCard
                    key={search.user}
                    artist={search}
                    isEmailVisible={true}
                    id={search.user.id}
                  />
                </div>
              ))
            ) : (
              <Text>No users yet</Text>
            )}
          </CardContainer>
        </Section>
      </Content>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 5%;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.collabTitleBackground};
  height: 15vh;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  @media (min-width: 240px) {
    font-size: 18px;
  }
  @media (min-width: 640px) {
    font-size: 22px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-left: 5%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.color};
  margin-top: 15px;
  @media (min-width: 240px) {
    width: 90%;
  }
  @media (min-width: 640px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 30%;
  }
`;
const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.color};
  margin-top: 5%;
  align-self: flex-start;
`;
const SectionText = styled.div`
  font-weight: 300;
  font-size: 17px;

  color: ${({ theme }) => theme.color};
`;
const CollabDescription = styled(SectionText)`
  width: 90%;
`;

const CardContainer = styled.div`
  width: 100%;
  min-width: 20vw;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: 10px 0;
  flex-wrap: wrap;
  /* overflow: auto; */
  gap: 25px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const Text = styled.p`
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
  color: white;
  text-align: center;
`;
export default CollaborationProfile;
