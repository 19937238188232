import React from "react";
import styled from "styled-components";
const Sponsoring = () => {
  return (
    <Container>
      <Title>Sponsoring and Partnerships</Title>
      <Paragraph>
        <ParagraphSection>
          At Muze we are fond of bringing forth change to the world by helping people.
        </ParagraphSection>
        <ParagraphSection>
          We always look forward to creating an exceptional experience for people around the world
          who seek to thrive in their passion.
        </ParagraphSection>
        <ParagraphSection>
          This is why we are always proud to embark on journeys with other entities that share
          similar objectives to further hance people’s networking and bring communities together.
        </ParagraphSection>
        <ParagraphSection>
          By working together, we will make our world a better place.
        </ParagraphSection>
        <ParagraphSection>
          If you are interested in sponsoring an event with Muze to create a dynamic atmosphere for
          all ages, genders and races to provide them with a positive outcome that would not only
          aid them with their career but also entertain them, this is your place to be.
        </ParagraphSection>
        <ParagraphSection>
          If you would like to know more information about our events please leave an e-mail at:{" "}
          <a href="mailto:info@muze-network.com">info@muze-network.com</a>
        </ParagraphSection>
      </Paragraph>
    </Container>
  );
};
const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;
const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;
const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  padding: 30px 0 20px;
`;
const ParagraphSection = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 18px;
  }
  a {
    background: linear-gradient(to right, #016299, #f77599);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
`;
export default Sponsoring;
