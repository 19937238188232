// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// Components
import Switcher from "./../../components/switcher/Switcher";
import Portfolio from "./screens/Portfolio";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// Services
import {
  selectUser,
  setGenres,
  setUserBookings,
  setUserDetails,
  setUserStudios,
} from "../../redux/services/user/userSlice";

import MyCollaborations from "./screens/MyCollaborations";
import {
  useGetAllDetailsMutation,
  useGetGenresMutation,
  useIsPrivateEmailMutation,
} from "../../redux/services/user/userApi";
import Genres from "./screens/Genres";
import Following from "./screens/Following";
import Followers from "./screens/Followers";
import MyBookings from "./screens/MyBookings";
import MyStudios from "./screens/MyStudios";
import { useGetMyBookingsMutation } from "../../redux/services/booking/bookingApi";
import PostModal from "../../components/post-input/PostModal";
import { ModalBody } from "react-bootstrap";
import UserCoverDropBox from "../../components/post-input/UserCoverDropBox";
import Loading from "../../components/loading/Loading";
// Animated Guide
import { Steps } from "intro.js-react";
import { toast } from "react-toastify";
const isGuest = localStorage.getItem("Guest");

const InstagramIcon = `./icons/profile/instagram.png`;
const SoundcloudIcon = "./icons/profile/soundcloud.png";
const YoutubeIcon = "./icons/profile/youtube.png";
const SpotifyIcon = "./icons/profile/spotify.png";

const ProfileImagePlaceholder = "./icons/common/user.jpg";
const CoverImagePlaceholder = "./icons/profile-background-cover.png";

const Profile = () => {
  const { user } = useSelector(selectUser);
  const [imageOpen, setImageOpen] = useState(false);
  const [modaleImage, setModaleImage] = useState();
  const [backgroundChange, setBackgroundChange] = useState(false);
  const myStudios = useSelector((state) => state.user.userStudios);

  const Switchers = [
    { name: "Portfolio", component: <Portfolio></Portfolio> },
    { name: "Genres", component: <Genres></Genres> },
    { name: "Listed Collabs", component: <MyCollaborations /> },
    { name: "Followers", component: <Followers></Followers> },
    { name: "Following", component: <Following></Following> },
    { name: "Bookings", component: <MyBookings /> },
    myStudios.length !== 0 ? { name: "Studios", component: <MyStudios /> } : {},
  ];
  // index of the section
  const [selectedSection, setSelectedSection] = useState(0);
  const [myUserDetails, setMyUserDetails] = useState();
  const [isPrivate, setIsPrivate] = useState(null);
  const [getGenres] = useGetGenresMutation();
  const [getUserDetails] = useGetAllDetailsMutation();
  const [isPrivateEmail] = useIsPrivateEmailMutation();
  const [getBookings] = useGetMyBookingsMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const handleChangeBackground = () => {
    setBackgroundChange(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getUserDetails().then((res) => {
      setMyUserDetails(res.data["Profile Details"]);
      setIsPrivate(res.data["Profile Details"].isEmailPrivate);
      dispatch(setUserDetails(res.data["Profile Details"]));
      dispatch(setUserStudios(res.data["Owned Studio Rooms"]));
      localStorage.setItem("Guest", res.data["Profile Details"]?.tour_guide_done);
      const animationKey = res.data["Profile Details"].signUpProcessDone;
      setStepsEnabled(!animationKey);
    });
    getGenres().then((res) => dispatch(setGenres(res.data)));
    getBookings().then((res) => {
      dispatch(setUserBookings(res.data));
      setIsLoading(false);
    });
  }, []);

  // Animated Guide
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const onExit = () => {
    setInitialStep(0);
    setStepsEnabled(false);
  };

  const switchHandler = async () => {
    await isPrivateEmail();
    await getUserDetails().then((res) => {
      setIsPrivate(res.data["Profile Details"].isEmailPrivate);
    });
    toast.success("Privacy Changed");
  };

  const steps = [
    {
      element: ".dropdown",
      intro:
        "First, you want to head to your settings page to put in your bio and profile picture and if you have any social media links to connect to your account.",
    },
    {
      element: ".Profile_Icon",
      intro:
        "Welcome to your profile page. You can start polishing your profile page by Choosing a Cover Photo.",
    },
    {
      element: "#modalButton",
      intro: "Start creating your portfolio by clicking on Create New Post",
    },
  ];
  return isLoading ? (
    <Loading />
  ) : (
    <>
      {isGuest && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
          options={{ doneLabel: "Okay" }}
        />
      )}
      <Container>
        <Modal
          show={imageOpen}
          onHide={() => {
            setImageOpen(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {modaleImage ? (
            <ModalImage src={modaleImage}></ModalImage>
          ) : (
            <ModalImage src="https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492"></ModalImage>
          )}
        </Modal>
        {/* Modal for the background image */}
        <Modal
          show={backgroundChange}
          onHide={() => {
            setBackgroundChange(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalBody style={{ backgroundColor: "#171717", color: "white" }}>
            <Text style={{ fontSize: "35px", textAlign: "center" }}>Upload Cover Photo</Text>
            <FileDropBoxContainer>
              <UserCoverDropBox
                setBackgroundChange={setBackgroundChange}
                setCover={(blobUrl) =>
                  setMyUserDetails({ ...myUserDetails, userCoverImageUrl: blobUrl })
                }
              />
            </FileDropBoxContainer>
          </ModalBody>
        </Modal>
        <PersonalSection className="Cover">
          <CoverPhoto
            src={
              myUserDetails?.userCoverImageUrl
                ? myUserDetails?.userCoverImageUrl
                : CoverImagePlaceholder
            }
            onClick={() => {
              setImageOpen(true);
              setModaleImage(myUserDetails.userCoverImageUrl);
            }}
          />
          <AllContainer>
            <PenIconContainer>
              <i
                onClick={handleChangeBackground}
                style={{ fontSize: "20px", cursor: "pointer" }}
                className="fa-regular fa-pen-to-square pen-icon"
              ></i>
            </PenIconContainer>
            <div>
              <div>
                <ProfileInfoCard className={"Cover"}>
                  <UserDescription>
                    {myUserDetails ? (
                      <>
                        <Text>I am {myUserDetails.firstName} .</Text>
                      </>
                    ) : (
                      <Text>
                        I am {user.firstName} .
                        {/* {user.userType[0] ? `a ${user.userType}` : null} */}
                      </Text>
                    )}

                    <Paragraph>
                      {myUserDetails !== undefined && myUserDetails.bio
                        ? myUserDetails.bio
                        : "No bio yet"}
                    </Paragraph>
                    <ContainerEmail>
                      <EmailContainer>Email: {myUserDetails?.user?.email}</EmailContainer>
                      <SwitchContainer>
                        <Form.Check
                          type="switch"
                          onChange={switchHandler}
                          checked={isPrivate}
                          label={isPrivate ? "Hide Email" : "Show Email"}
                        />
                      </SwitchContainer>
                    </ContainerEmail>
                    <SocialMediaSection>
                      {myUserDetails?.spotifyLink && (
                        <SocialMediaIcon
                          src={SpotifyIcon}
                          onClick={() => {
                            window.open(myUserDetails.spotifyLink, "_blank", "noopener,noreferrer");
                          }}
                        />
                      )}

                      {myUserDetails?.soundcloudLink && (
                        <SocialMediaIcon
                          src={SoundcloudIcon}
                          onClick={() => {
                            window.open(
                              myUserDetails.soundcloudLink,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        />
                      )}

                      {myUserDetails?.youtubeLink && (
                        <SocialMediaIcon
                          src={YoutubeIcon}
                          onClick={() => {
                            window.open(myUserDetails.youtubeLink, "_blank", "noopener,noreferrer");
                          }}
                        />
                      )}

                      {myUserDetails?.instagramLink && (
                        <SocialMediaIcon
                          src={InstagramIcon}
                          onClick={() => {
                            window.open(
                              myUserDetails.instagramLink,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        />
                      )}
                    </SocialMediaSection>
                  </UserDescription>
                </ProfileInfoCard>
              </div>
              <ButtonContainer style={{ position: "relative", zIndex: 999 }}>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  id="modalButton"
                >
                  Create New post
                </Button>
              </ButtonContainer>
              <PostModal
                show={showModal}
                onHide={() => {
                  setShowModal(false);
                }}
                setShowModal={setShowModal}
              ></PostModal>
            </div>
          </AllContainer>
        </PersonalSection>

        <NonProfileContainer>
          <ProfileImageContainer>
            <ProfileImage
              src={
                myUserDetails?.userImageUrl ? myUserDetails?.userImageUrl : ProfileImagePlaceholder
              }
              onClick={() => {
                setImageOpen(true);
                setModaleImage(myUserDetails.userImageUrl);
              }}
            ></ProfileImage>
          </ProfileImageContainer>
          <BorderConnect />
          <ProfileContentContainer>
            <StyledSwitcher
              selected={selectedSection}
              setSelected={setSelectedSection}
              switchers={Switchers}
            ></StyledSwitcher>
            {Switchers[selectedSection].component}
          </ProfileContentContainer>
        </NonProfileContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const NonProfileContainer = styled.div`
  width: 100%;
  margin-top: -8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 29;
`;
const PersonalSection = styled.div`
  width: 90%;
  height: 400px;
  // background: url("./icons/profile-background-cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 3.5%;
  @media (max-width: 567px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 500px;
  border-radius: 50px;
  cursor: pointer;
`;

const AllContainer = styled.div`
  position: absolute;
`;

const ProfileInfoCard = styled.div`
  width: 40rem;
  height: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: start;

  background: linear-gradient(
    326.78deg,
    rgba(1, 98, 153, 0.8) 22.45%,
    rgba(247, 117, 153, 0.8) 89.05%
  );
  border-radius: 20px;
  color: white;
  position: relative;
`;
const PenIconContainer = styled.span`
  position: absolute;
  top: -10%;
  right: -25%;
  color: white;
  z-index: 1;
`;
const UserDescription = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Text = styled.p`
  font-weight: 900;
  font-size: 48px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 52px;
  }
`;
const Paragraph = styled.p`
  max-width: 90%;
  min-height: 10vh;
  margin-bottom: 2%;
`;
const SocialMediaSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-top: 1%;
`;
const SocialMediaIcon = styled.img`
  max-width: 30px;
  max-height: 30px;
  margin-top: auto;
  align-self: flex-end;
  cursor: pointer;
  border-radius: 50%;
  src: ${(props) => props.src};
`;

const ProfileImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  \ @media (max-width: 767px) {
    margin-right: 1rem;
  }
  @media (min-width: 1200px) {
    margin-right: 5rem;
  }
`;

const ProfileImage = styled.img`
  /* src: ${(props) => props.src}; */
  height: 200px;
  cursor: pointer;
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  z-index: 1;
  @media (max-width: 767px) {
    max-width: 130px;
    max-height: 250px;
    bottom: 180px;
    right: 20px;
  }
  object-fit: contain;
`;

const FileDropBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BorderConnect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 8vh;
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0px 2px 0px 0px;
  border-style: solid;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 20px 0;
    width: 95%;
  }
`;

const ProfileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  min-height: 700px;
  overflow: hidden;

  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 2px 0 0 2px;
  border-style: solid;
  @media (max-width: 991px) {
    padding: 20px 0;
    width: 95%;
  }
`;
const StyledSwitcher = styled(Switcher)`
  @media (min-width: 240px) {
    width: 95%;
  }
  @media (min-width: 1024px) {
    width: 90%;
    margin-top: 2%;
  }
`;
const ModalImage = styled.img`
  src: ${(props) => props.src};
`;
const ButtonContainer = styled.div`
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const Button = styled.button`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  border-radius: 10px;
  padding: 10px 25px;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  width: max-content;
`;

const EmailContainer = styled.p`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 18px;
`;

const ContainerEmail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export default Profile;
