import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
const CustomCollapse = ({ children, open }) => {
  return <StyledCollapse in={open}>{children}</StyledCollapse>;
};
const StyledCollapse = styled(Collapse)`
  margin: 0;
  padding: 0;
`;
CustomCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
};
export default CustomCollapse;
