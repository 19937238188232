// Libraries

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// Components
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";
// Assets
import { useLikeMutation, useDislIkeMutation } from "../../redux/services/user/userApi";
import { useDeletePostMutation } from "../../redux/services/video/videoApi";
import { useSelector } from "react-redux";
import Loading from "../../components/loading/Loading";

import { selectUser } from "../../redux/services/user/userSlice";
import CommentSection from "./CommentSection";

const Arrow = `./icons/common/arrow.svg`;
const Like = `./icons/cards/like.png`;

const VideoScreen = () => {
  // video props passed from the previous screen to use in the component
  const { state } = useLocation();
  const { user } = useSelector(selectUser);
  const { pk } = user;

  // user comment state
  const [isLike, setIsLike] = useState(state.likes?.map((ele) => ele.id).indexOf(pk) >= 0);

  // Holds the count of likes
  const [likesCount, setLikesCount] = useState(state.likes?.length);

  // Video data from redux

  // navigate hook for routing
  const navigate = useNavigate();
  // t hook for translation

  // const // getPopularVideos,
  //   { data: postsData, refetch } = useMyPostsQuery({ skip: isSkip });
  const isLoading = false;
  // function to change the state value of the comment when user types in the comment field

  const [userCoverPhoto, setUserCoverPhoto] = useState();

  const [sendlikeData, { isSuccess: isLikeSuccess }] = useLikeMutation();

  const [sendDislikeData, { isSuccess: isDislikeSuccess }] = useDislIkeMutation();

  const [deletePost] = useDeletePostMutation();

  const onClickLike = (id) => {
    sendlikeData({
      post_id: id,
    }).then((res) => {
      setLikesCount(res.data.post.likes?.length);
    });
  };

  const onClickDisLike = (id) => {
    sendDislikeData({
      post_id: id,
    }).then((res) => setLikesCount(res.data.post.likes?.length));
  };

  const deletePostHandler = (id) => {
    deletePost({
      postId: id,
    })
      .then((response) => {
        toast.success("Video deleted successfully");
        window.history.back();
      })
      .catch((error) => {
        toast.error("Error deleting Post");
        console.error("Error deleting Post:", error);
      });
  };

  useEffect(() => {
    if (isLikeSuccess) {
      toast.success("Liked");
      setIsLike(true);
    }
  }, [isLikeSuccess]);

  useEffect(() => {
    if (isDislikeSuccess) {
      toast.success("Disliked");
      setIsLike(false);
    }
  }, [isDislikeSuccess]);

  useEffect(() => {
    setUserCoverPhoto(state.postCoverUrl);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <VideoPlayerWrapper>
        <HeaderSection>
          <BackArrow src={Arrow} onClick={() => navigate(-1)} />
          <VideoTitle>{state?.body ?? "Video Title"}</VideoTitle>
        </HeaderSection>
        <VideoContainer>
          <VideoPlayer src={state?.videoUrl[0]?.url} coverPhoto={userCoverPhoto} />
          {isLike ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {state.author === user.pk ? (
                <DeleteButton onClick={() => deletePostHandler(state.id)}>Delete Post</DeleteButton>
              ) : (
                <div></div>
              )}
              <div
                style={{
                  marginLeft: "640px",
                  fontSize: "24px",
                  color: "white",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    position: "absolute",
                    right: "80px",
                  }}
                >
                  {likesCount}
                </div>
                <LikeBtn onClick={() => onClickDisLike(state?.id)}></LikeBtn>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {state.author === user.pk ? (
                <DeleteButton onClick={() => deletePostHandler(state.id)}>Delete Post</DeleteButton>
              ) : (
                <div></div>
              )}
              <div
                style={{
                  marginLeft: "640px",
                  fontSize: "24px",
                  color: "white",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    position: "absolute",
                    right: "80px",
                  }}
                >
                  {likesCount}
                </div>
                <LikeBtn onClick={() => onClickLike(state?.id)}></LikeBtn>
              </div>
            </div>
          )}
        </VideoContainer>
        <DescriptionContainer>
          <DescriptionTitle>Caption : </DescriptionTitle>
          <DescriptionBody>{state.description.length ? state.description : ``}</DescriptionBody>
        </DescriptionContainer>
      </VideoPlayerWrapper>
      <CommentSection author={state.author} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 20px 30px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const VideoPlayerWrapper = styled.div`
  flex: 3;
`;

const HeaderSection = styled.div`
  padding: 15px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
  display: flex;
  gap: 10px;
  align-items: center;
  [data-theme="light"] & {
    border-color: #676767;
  }
`;
const BackArrow = styled.img`
  cursor: pointer;
  height: 27px;
  width: 27px;
  padding: 5px;
  border-radius: 7px;
  [data-theme="light"] & {
    background-color: #000000;
  }
`;
const VideoTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: ${({ theme }) => theme.color};
`;
const VideoContainer = styled.div``;

const DescriptionContainer = styled.div`
  margin-left: 2%;
`;
const DescriptionTitle = styled.h1`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;
const DescriptionBody = styled.p`
  color: ${({ theme }) => theme.color};
  font-weight: 400;
  font-size: 20px;
  max-width: 70%;
  margin-top: 2%;
  @media (max-width: 767px) {
    font-size: 10px;
  }
`;
const LikeBtn = styled.div`
  background-image: url(${Like});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  width: 38px;
  height: 30px;
  margin: 10px 35px 10px auto;
  cursor: pointer;
  transition: all ease 200ms;
  color: white;

  &:hover {
    transform: scale(1.25);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
      0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
`;
const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fc6a6a;
  background-color: transparent;
  width: fit-content;
  border: 1px solid #fc6a6a;
  padding: 2px 16px;
  border-radius: 10px;
  transition: all 0.3s;
  &:hover {
    color: #8d0000;
    border-color: #8d0000;
  }
`;
export default VideoScreen;
