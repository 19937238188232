/* eslint-disable*/
import React from "react";

const TermsAR = () => {
  return (
    <div dir="rtl" lang="ar">
      <p lang="ar" dir="rtl">
        شروط الخدمة لدى muze y network يسرنا التعاون والتعمل معكم واليكم الشروط
        والاحكام
      </p>
      <ul>
        <li>
          <b lang="ar" dir="rtl">
            اولا : أهلا بك في muze y network .
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                ما لم يتم الاتفاق خطيا مع muze y network على خلاف ذلك، سوف تتضمن
                اتفاقيتك الشروط والأحكام الواردة في هذه الوثيقة، والتي يشار
                إليها لاحقا بـ "الشروط العامة
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                كما تتضمن اتفاقيتك مع muze y network شروط أية إشعارات قانونية
                تنطبق على الخدمات، إضافة إلى الشروط العامة. ويشار الى جميع هذه
                الشروط لاحقا فى هذه الوثيقة ب "الشروط الاضافية"
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إن الشروط العامة والشروط الإضافية تشكل معا اتفاقية قانونية ملزمة
                بينك وبين muze y network فيما يتعلق باستعمالك للخدمات. ومن المهم
                أن تأخذ الوقت الكافي لقراءتها بدقة. ويشار أدناه إلى هذه
                الاتفاقية القانونية بـ "الشروط"
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            ثانيا : قبول الشروط
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                لكي تستخدم الخدمات يتعين عليك أولا أن توافق على الشروط، ولا
                يمكنك استعمال الخدمات قبل قبولك بالشروط
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                يمكن قبول الشروط عبر أي من الخطوتين التاليتين:
              </p>
              <ol type="A">
                <li>
                  <p dir="rtl" lang="ar">
                    النقر على خيار القبول أو الموافقة على الشروط حيثما توفره لك
                    muze y network في واجهة التطبيق الخاصة بالمستخدم لاى من
                    الخدمات
                  </p>
                </li>
                <li>
                  <p dir="rtl" lang="ar">
                    استعمال الخدمات فعليا. وفى هذه الحالة تدرك وتوافق ان muze y
                    ستعتبر استخدامك للخدمات بمثابة قبول بالشروط من تلك اللحظة
                    فصاعدا
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                للتمتع بالخدمات يجب ان تكون ضمن السن القانوني الذي يخولك إبرام
                عقد ملزم muze y network
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            ثالثا : لغة الشروط
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                حيثما توفر لك muze y network ترجمة للنسخة الإنجليزية من الشروط،
                فإنك توافق على أن الترجمة قد يتم توفيرها للتسهيل عليك فقط وأن
                النسخة الإنجليزية للشروط هي النسخة التي سوف تعتمد وتحكم علاقتك
                بـ muze y network
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            رابعا : تقديم الخدمات
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                إن لـ muze y network حول العالم شركات فرعية وشركات شقيقة
                ("الشركات الفرعية والشقيقة"). وهذه الشركات ستقوم أحيانا بتزويدك
                بالخدمات نيابة عن muze y network نفسها. وعليه، فإنك تقر وتوافق
                بأن الشركات الفرعية والشقيقة ستزودك بالخدمات
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                ن muze y network لكي توفر لمستخدميها أفضل خبرة ممكنة، وعليه،
                فإنك تقر وتوافق بأن شكل وطبيعة الخدمات التي توفرها يمكن أن تتغير
                من وقت لآخر ودون سابق إشعار إليك
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إنك تقر و توافق بأن لشركة muze y network وبمحض اختيارها أن توقف
                (بشكل مؤقت أو دائم) تزويد الخدمات (أو أي من خصائص الخدمات) إليك
                أو إلى مستخدميها بشكل عام دون إشعار مسبق
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            خامسا : استعمالك للخدمات
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                بغية الدخول إلى خدمات معينة، قد يتعين عليك تقديم بعض المعلومات
                الخاصة بك(مثل الهوية أو العنوان وتفاصيل اإلتصال) كجزء من عملية
                تسجيل اشتراكك في الخدمة، أو كجزء من استمرار استعمالك للخدمات.
                وتوافق على أن أية معلومات تقدمها لشركة network y muze لغرض تسجيل
                اشتراكك ستكون دائماً صحيحة ودقيقة ومحدثة وعلى مسئوليتك الشخصية .
              </p>
            </li>

            <li>
              <p dir="rtl" lang="ar">
                إنك توافق على استعمال الخدمات فقط لألغراض التي تمسح بها الشروط
                والقوانين أو األنظمة أو القواعد أو التعليمات السائدة في الدولة
                المعنية العملية المقبولة عموما .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إنك توافق على عدم الدخول (أو محاولة الدخول)إلى أي من الخدمات بأي
                طريقة سوى من خالل واجهة التفاعل التي توفرها network y muze .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                إنك توافق على أن ال تشارك في أي نشاط يتعارض مع الخدمات (أو أجهزة
                خدمة المعلومات المركزية والشبكات المرتبطة بالخدمات) أو يعيق
                استعمالها.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                ما لم يتم السماح لك بذلك بموجب اتفاقية منفصلة مع network y muze
                ، إنك توافق على عدم إعادة إنتاج الخدمات أو تصويرها أو نسخها أو
                بيعها أو المتاجرة فيها أو إعادة بيعها ألي غرض .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .إنك توافق على أنك وحدك المسؤول (وأن network y muze ليست مسؤولة
                بأي شكل تجاهك وال تجاه أي طرف آخر) عن أي إخالل بالتزاماتك
                المترتبة عليك بموجب الشروط وعن نتائج ذلك اإلخالل
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            سادسا : أمن كلمات المرور والحساب الخاص بك
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                .إنك تدرك وتوافق على أنك مسؤول عن المحافظة على سرية كلمات المرور
                الخاصة بأي حساب تستخدمه للدخول إلى الخدمات.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .وبناء عليه، فإنك توافق على أن تكون المسؤول الوحيد تجاه network
                y muze عن جميع األنشطة التي تتم من خالل حسابك .
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            سابعا : محتويات الخدمات{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                إنك تدرك أن جميع المعلومات (مثل ملفات البيانات، النصوص المكتوبة،
                برامج الكمبيوتر، الموسيقى، الملفات السمعية، الصور، األفالم
                المصورة أو غير ذلك من الصور) التي يمكنك الدخول إليها ضمن أو من
                خالل استعمالك للخدمات هي مسؤولية الشخص الذي نشأت عنه هذه
                المحتويات وحده. ويشار إلى جميع هذه المعلومات فيما يلي بـ
                "المحتويات."
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                ينبغي عليك أن تدرك أن المحتويات المقدمة إليك كجزء من الخدمات،
                بما في ذلك ودون حصر اإلعالنات ورعاية المحتويات ضمن الخدمات قد
                تكون محمية بقانون حقوق الملكية الفكرية التي تملكها الجهات
                الراعية أو الجهات المعلنة التي توفر تلك المحتويات لـ( network y
                muze أو يملكها أشخاص أو شركات بالنيابة عنهم) . ولذا ال يجوز لك
                تعديل تلك المحتويات أو استئجارها أوتأجيرها أو إقراضها أو بيعها
                أو توزيعها أو اشتقاق أعمال بناء عليها (سواء كليا أو جزئيا) ما لم
                تبلغك network y muze أو مالكو تلك المحتويات صراحة بإمكانية
                القيام بذلك، بموجب اتفاقية منفصلة .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .تحتفظ network y muze بحقها (ولكن دون أي التزام) في فحص أي أو
                كافة المحتويات ومراجعتها واختصارها وتنقيحها وتعديلها ورفضها و
                إزالتها من أي من الخدمات.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .إنك تدرك أن استعمالك للخدمات قد يعرضك لمحتوى تجد أنه مزعج أو
                غير الئق أو غير مرغوب فيه، وتعلم أنك في هذا الشأن تستعمل الخدمات
                على مسؤوليتك .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إنك توافق على أنك وحدك المسؤول (وأن network y muze ال تتحمل أية
                مسؤولية تجاهك أو تجاه أي طرف ثالث) عن المحتويات التي تنشئها
                بنفسك أو تنشرها أو تعرضها أثناء استعمالك للخدمات وعن أية نتائج
                تترتب على تصرفاتك.
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            سادسا : أمن كلمات المرور والحساب الخاص بك
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                .إنك تدرك وتوافق على أنك مسؤول عن المحافظة على سرية كلمات المرور
                الخاصة بأي حساب تستخدمه للدخول إلى الخدمات.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .وبناء عليه، فإنك توافق على أن تكون المسؤول الوحيد تجاه network
                y muze عن جميع األنشطة التي تتم من خالل حسابك .
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            ثامنا : الحقوق الخاصة
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                إنك تدرك وتوافق على ان( network y muze أو المرخصين لها)يملكون
                كامل الحق القانوني وحق الملكية والمصلحة في الخدمات بما في ذلك
                أية حقوق ملكية فكرية موجودة ضمن الخدمات
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                ما لم تكن قد اتفقت مع network y muze خطيا على خالف ذلك، ال شيء
                في هذه الشروط يمنحك الحق في استعمال أسماء network y muze
                التجارية أو عالماتها أو عالمات خدمتها أو شعاراتها أو أسماء
                مواقعها وغير ذلك من خصائص االسم التجاري الخاصة بها.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                . - إنك توافق على أنه لن تقوم بإزالة أو إخفاء أو تغيير أية
                إشعارات حقوق خاصة)بما في ذلك إشعارات حقوق التأليف والعالمات
                التجارية( التي يمكن أن تكون ملحقة بالخدمات أو مشمولة فيها.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                ما لم تكن قد اتفقت مع network y muze صراحة وخطيا على خالف ذلك،
                إنك توافق على أنك أثناء استعمال الخدمات، لن تستخدم أية عالمة
                تجارية أو عالمة خدمة أو اسم تجاري أو شعار عائد ألي شركة أو مؤسسة
                بطريقة يمكن أن تسبب أو تهدف إلى التسبب في إحداث لبس بخصوص المالك
                أو المستخدم المرخص له استعمال هذه العالمات أو األسماء أو
                الشعارات.
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            تاسعا : الترخيص من network y muze
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                إن network y muze تمنحك ترخيصا شخصيا حول العالم وبرسوم وغير قابل
                للتنازل وغير حصري باستعمال البرنامج الذي تزودك به network y muze
                كجزء من الخدمات المزودة لك وهذا الترخيص هو فقط لغرض تمكينك من
                استعمال والتمتع بمنفعة الخدمات كما هي مزودة لك من قبل network y
                muze وبالطريقة التي تسمح بها الشروط.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                ل يجوز لك (وال يجوز لك أن تسمح ألحد آخر) بنسخ أو تعديل أو إنشاء
                عمل مشتق من أو إعادة إنتاج بالهندسة العكسية أو فك أو محاولة
                انتزاع مصدر الرمز للبرنامج أو أي جزء منه، ما لم يكن ذلك مسموحا
                به أو مطلوبا صراحة بموجب القانون أوفي حال تم إبالغك خطيا بشكل
                محدد من قبل network y muze بأنه يجوز لك فعل ذلك.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                بالقيام بذلك، ال يجوز لك التنازل (أو منح ما لم تكن network y
                muze قد منحتك إذنًا خطياًمحدداً ترخيص من الباطن)عن حقوقك
                باستعمال البرنامج أو منح ضمانة من جراء حقوقك باستعمال البرنامج
                أو نقل أي جزء من حقوقك باستعمال البرنامج.
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            عاشرا: محتويات الترخيص لك
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                .إنك تحتفظ بحق التأليف وأية حقوق أخرى تملكها مسبقاً في المحتويات
                التي تسلمها أو تنشرها أو تعرضها في ترخيصاً أو من خالل الخدمات.
                من خالل تسليم أو نشر أو عرض المحتويات، فإنك تمنح network y muze
                دائما بإعادة إنتاج وتكييف وتعديل وترجمة ً وغير قاب ٍل للنقض وفي
                كل أنحاء العالم وبدون رسوم وغير حصري ونشر وإنجاز علناً وعرض
                علناً وتوزيع أي من المحتويات التي تسلمها أو تنشرها أو تعرضها في
                أو من خالل الخدمات. وهذا الترخيص هو فقط لغرض تمكين network y
                muze من عرض وتوزيع وترويج الخدمات ويمكن سحبه بالنسبة لخدمات
                معينة كما هو محدد ضمن الشروط اإلضافية لتلك الخدمات .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إنك توافق على أن هذا الترخيص يشمل الحق لشركة network y muze في
                توفير تلك المح تويات للشركات والمؤسسات واألشخاص اآلخرين الذين ل
                لشركة عالقة بهم لتوفير خدمات مشتركة واستعمال تلك المحتويات فيما
                يتعلق بتوفير تلك الخدمات.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                . إنك تؤكد وتضمن لشركة network y muze أنه لديك جميع الحقوق
                والسلطات والصالحيات الالزمة لمنح الترخيص المذكور أعاله .
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            حادى عشر : تحديث البرنامج
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                إن البرنامج الذي تستخدمه يمكن أن يحمل ويركب من وقت آلخر تحديثا
                من .network y muze والهدف من هذا التحديث هو تحسين الخدمات
                وتطويرها ورفع مستواها ويمكن أن يكون على شكل إصالح عيوب أو تحسين
                وظائف أو تركيبات برمجية جديدة أو نسخا جديدة بالكامل. وإنك توافق
                على استالم مثل هذه التحديثات (وتسمح لشركة network y muze
                بتسليمها لك) كجزء من استعمالك للخدمات.
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            ثان عشر : إنهاء عالقتك مع network y muze{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                تستمر الشروط نافذة المفعول إلى أن يتم إنهاؤها إما من قبلك أو من
                قبل network y muze كما هو مبين أدناه .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                إذا أردت إنهاء اتفاقيتك القانونية مع network y muze ، يمكنك فعل
                ذلك من خالل )أ( إشعار network y muze في أي وقت )ب( إقفال حساباتك
                لجميع الخدمات التي تستعملها .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                يجوز لشركة network y muze في أي وقت إنهاء اتفاقيتها القانونية
                معك في حال: )أ( خالفت أي نص من نصوص الشروط ) ب( ، إذا كان أو
                أصبح تقديم الخدمات إليك غير مشروع قانونا ( )ج( قام الشريك الذي
                قدمت معه network y muze الخدمات لك بإنهاء عالقته مع الشركة أو
                توقف عن تقديم الخدمة لك )د( قررت network y muze وقف تقديم
                الخدمات لجميع المستخدمين في البلد الذي تقيم فيه أو الذي تستعمل
                الخدمة منه، أو )هـ( أصبح تقديم الخدمة لك غير مجدٍ تجارياً من
                وجهة نظر..network y muze
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            ثالث عشر :استبعاد الضمانات{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                ليس في هذه الشروطما يستبعد أو يقيد ضمان network y muze أو
                مسؤوليتها عن الخسائر التي قد ال تكون استبعدت أو قيدت بشكل قانوني
                بفعل تطبيق القانون. فبعض البلدان ال تسمح قوانينها باستبعاد بعض
                الضمانات أو الشروط أو القيود أو استبعاد المسؤولية عن الخسارة أو
                الضرر الناجم عن اإلهمال أو مخالفة العقد أو مخالفة الشروط
                الضمنية، أو األضرار العرضية أو التبعية. وبالتالي، فإن القيود
                القانونية المطبقة في بلدك فقط هي ما ينطبق عليك وستكون مسؤوليتنا
                محدودة بالحد األ قصى المسموح به نظاما.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                إنك تدرك تماما وتوافق على أن استعمالك للخدمات على مسؤوليتك وحدك
                وأن الخدمات تقدم إليك "كما هي" و"كما هي متوفرة."
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                وعلى وجه الخصوص، إن network y muze وشركاتها الفرعية والشقيقة
                واألطراف المرخص لها من قبلها ال تؤكد وال تضمن لك أن: )أ (
                استعمالك للخدمات سيلبي احتياجاتك، )ب( استعمالك للخدمات لن يتعرض
                للتوقف وسيتوفر في الوقت المحدد وسيكون آمن أو خاليا من األخطاء،
                )ت( أية معلومات تحصل عليها نتيجة الستخدام الخدمات ستكون دقيقة
                وموثوقة، و )ث( عيوب التشغيل أو العمل في أي برنامج يتم توفيره لك
                كجزء من الخدمات سيتم إصالحها.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                إن تحميل أية مادة أو الحصول عليها من خالل استعمال الخدمات يتم
                بمحض اختيارك وعلى مسؤوليتك وأن المسؤولية تقع عليك وحدك عن أي ضرر
                قد يلحق بجهاز الكمبيوتر أو أي جهاز آخر عائد لك أو فقدان معلومات
                نتيجة لتحميل تلك المادة.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .إن أية نصيحة أو معلومة، شفهية كانت أم خطية، تحصل عليها من
                network y muze أو من الخدمات أو من خاللها لن تشكل ضمانا غير
                منصوص عليه صراحة في الشروط.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                ما تخلي network y muze نفسها تماما من أية مسؤولية عن جميع
                الضمانات والشروط أيا كانت، سواء صريحة أم ضمنية، بما فيها ودون
                حصر الضمانات والشروط الضمنية المتعلقة بإمكانية التسويق أو
                الصالحية لغرض محدد وعدم االنتهاك.
              </p>
            </li>
          </ol>
        </li>
        ِ <br />
        <li>
          <b lang="ar" dir="rtl">
            رابع عشر : محدودية المسؤولية{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                مراعاة إنك تدرك تماما وتوافق على أن network y muze وشركاتها
                الفرعية والشقيقة واألطراف التي تم الترخيص من قبلها، لن تتحمل أية
                مسؤولية عن: )أ ( أية أضرار مباشرة أو غير مباشرة أوعرضية أوتبعية
                خاصة أو اتعاظية قد تتكبدها أنت، وأيا كان سببها وتحت أية نظرية من
                نظريات المسؤولية. ويشمل ذلك دون حصر أي فقدان للربح )سواء وقع
                بصورة مباشرة أو غير مباشرة( وأية خسارة في الشهرة أو السمعة
                التجارية وأي فقدان يحدث للبيانات وأي تكلفة لشراء سلع أو خدمات
                بديلة أو أية خسارة غير ملموسة، )ب( أية خسارة أو ضرر قد يلحق بك،
              </p>
            </li>
          </ol>
        </li>
        ِ <br />
        <li>
          <b lang="ar" dir="rtl">
            خامس عشر : اإلعالنات{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                .هناك بعض الخدمات المدعومة من إيرادات اإلعالنات ويمكن أن تعرض
                إعالنات ومواد ترويجية. وهذه اإلعالنات قد تستهدف محتوى المعلومات
                المخزنة على الخدمات، االستفسارات التي تتم من خالل الخدمات أو
                معلومات أخرى .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                {" "}
                مقابل منح network y muze لك إمكانية الدخول إلى الخدمات
                واستعمالها، فإنك توافق على أنه يجوز لشركة Google وضع تلك
                اإلعالنات على الخدمات .
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            سادس عشر : تعديل الشروط
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                .يمكن لشركة network y muze أن تجري تعديالت على الشروط العامة أو
                الشروط اإلضافية من وقت آلخر. وسيتم تزويدك بأية شروط إضافية من
                داخل الخدمات التي يطرأ التعديل عليها أو بواسطتها .
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                .إنك تدرك وتوافق على أنه إذا ما استعملت الخدمات بعد التاريخ الذي
                تعدل فيه الشروط العامة أوالشروط اإلضافية، فإن network y muze سوف
                تعتبر استعمالك للخدمات بمثابة قبول بالشروط العامة والشروط
                اإلضافية المعدلة.
              </p>
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b lang="ar" dir="rtl">
            سابع عشر .الشروط القانونية العامة{" "}
          </b>
          <br />
          <br />
          <ol type="1">
            <li>
              <p dir="rtl" lang="ar">
                في بعض األحيان عندما تستعمل الخدمات، قد تستعمل )نتيجة استعمالك
                للخدمات أو من خالله( خدمة أو تحمل جزءاً من برنامج، أو تشتري
                بضاعة يوفرها شخص آخر أو شركة أخرى. وقد يخضع استعمالك لهذه
                الخدمات أو البرامج أو السلع األخرى لشروط منفصلة بينك وبين الشركة
                أو الشخص المعني. فإذا كان األمر كذلك، فإن الشروط ال تؤثر على
                عالقتك القانونية بتلك الشركات األخرى أو األفراد اآلخرين
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                . إنك تقر وتوافق على أن كل عضو في مجموعة الشركات التابعة لـ
                network y muze سيعتبر طرفا ثالثا مستفيدا من االشروط وأنه سيكون
                لتلك الشركات األخرى الحق في التنفيذ المباشر واالعتماد على أي نص
                من نصوص الشروط التي تعطي منفعة لها )أو حقوقا لصالحها(. بخالف
                ذلك، فليس ألي شخص أو شركة أن يكون طرفا ثالثا مستفيدا من هذه
                الشروط.
              </p>
            </li>
            <li>
              <p dir="rtl" lang="ar">
                تخضع الشروط، وعالقتك مع network y muze بمقتضى الشروط، لقوانين
                لجمهورية مصر العربية بصرف النظر عن نصوصها القانونية بشأن نزاع
                القوانين.
              </p>
            </li>
          </ol>
        </li>
      </ul>
      {/* <ul>
        <li></li>
      </ul> */}
    </div>
  );
};

export default TermsAR;
