// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import CollabCard from "../../components/cards/collaboration/CollabCard";
import {
  useCollaborationARMutation,
  useCollaborationRequestsMutation,
} from "../../redux/services/collaboration/collaborationApi";
import { toast } from "react-toastify";
import { Button } from "../signin/Signin";
import Loading from "../../components/loading/Loading";
import userIcon from "../../icons/user.jpg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchedUserDetails } from "../../redux/services/user/userSlice";
import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";

// const Accept = `./icons/common/accept.png`;
// const Reject = `./icons/common/rejected.png`;

const MyCollaborationsRequests = () => {
  const [showModal, setShowModal] = useState(false);
  const [collabRequestData, setCollabRequestData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [collaborationRequestData] = useCollaborationRequestsMutation();
  const [acceptRejectData] = useCollaborationARMutation();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    collaborationRequestData().then((response) => {
      if (!response?.error) {
        setCollabRequestData(response?.data["received requests (own's requests filtered)"]);
      }
    });
  }, []);

  const onAcceptRejectClick = (event, collabId, userId, acceptReject) => {
    setIsLoading(true);
    event.stopPropagation();
    acceptRejectData({
      collaborationId: collabId,
      userId,
      responseType: acceptReject,
    }).then((response) => {
      toast.success(acceptReject === "Accept" ? "Accepted" : "Rejected");
      setCollabRequestData(response?.data["Received requests (own's requests filtered)"]);
      setIsLoading(false);
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();
  const handleCollaborationRequestClick = (user, event) => {
    getSearchedUser({
      profileId: user.user.id,
    }).then((res) => {
      dispatch(setSearchedUserDetails(res.data["Profile Details"]));
    });
    navigate("/users");
    event.stopPropagation();
  };
  // should send query to fetch incoming requests and list them
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <Header>
          <HeaderTitle>My Collaboration Requests</HeaderTitle>
        </Header>
        <CollabortaionWrapper>
          {collabRequestData && collabRequestData.length > 0 ? (
            collabRequestData.map((item) => {
              return (
                <StyledCollabCard
                  key={item.pk}
                  item={item}
                  firstName={item.joiningUserInScreenFullDetails?.firstName}
                  lastName={item.joiningUserInScreenFullDetails?.lastName}
                  profileImage={
                    item.joiningUserInScreenFullDetails?.userImageUrl
                      ? item.joiningUserInScreenFullDetails?.userImageUrl
                      : userIcon
                  }
                  title={item.title}
                  location={item.location}
                  ActionButton={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column-reverse",
                        alignItems: "flex-end",
                        gap: 20,
                        marginRight: "60px",
                      }}
                    >
                      <ActionButton>
                        <Icon
                          className="fa-regular fa-circle-check"
                          onClick={(event) =>
                            onAcceptRejectClick(
                              event,
                              item.pk,
                              item.joiningUserInscreen[0]?.id,
                              "Accept"
                            )
                          }
                        />
                        <Icon
                          className="fa-regular fa-circle-xmark"
                          onClick={(event) =>
                            onAcceptRejectClick(
                              event,
                              item.pk,
                              item.joiningUserInscreen[0]?.id,
                              "Reject"
                            )
                          }
                        />
                      </ActionButton>
                      <ActionButton>
                        <ViewUserButton
                          onClick={(event) =>
                            handleCollaborationRequestClick(
                              item.joiningUserInScreenFullDetails,
                              event
                            )
                          }
                        >
                          View User
                        </ViewUserButton>
                      </ActionButton>
                    </div>
                  }
                />
              );
            })
          ) : (
            <div style={{ margin: "auto" }}>
              <FallBackText>No requests yet</FallBackText>
            </div>
          )}
        </CollabortaionWrapper>
      </Container>

      <StyledModal
        show={showModal}
        onHide={toggleModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Header>
        <ModalBody>
          <Button onClick={() => setShowModal(false)}>Accept</Button>
          <Button>Decline</Button>
        </ModalBody>
      </StyledModal>
    </>
  );
};
const Container = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Header = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 240px) {
    flex-direction: column;
    margin-top: 3%;
  }
  @media (min-width: 640px) {
    flex-direction: row;
    margin-top: 1%;
  }
`;
const HeaderTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 14px;
  }
  @media (min-width: 640px) {
    font-size: 30px;
  }
`;
const CollabortaionWrapper = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @media (min-width: 240px) {
    margin-top: 6%;
  }
  @media (min-width: 1024px) {
    margin-top: 2%;
  }
`;
const StyledCollabCard = styled(CollabCard)`
  width: 100%;
  margin-bottom: 2%;
`;
const ActionButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media (min-width: 240px) {
    width: 25%;
  }
  @media (min-width: 640px) {
    width: 10%;
  }
`;
const ViewUserButton = styled.div`
  width: 150px !important;
  padding: 15px 35px;
  display: flex;
  justify-content: center;
  align-items: center
  gap: 2rem;
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  color: white;
  z-index: 100;
  transition: 0.35s ease-in-out;
  border-radius: 10px;
  white-space: nowrap;

  @media (min-width: 240px) {
    width: 25%;
  }
  @media (min-width: 640px) {
    width: 10%;
  }
  :hover {
    background-color: red;
    padding: 15px 70px;
  }
`;
const Icon = styled.i`
  font-size: 45px;
  cursor: pointer;
  color: ${({ theme }) => theme.color};
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  justify-content: space-around;
`;
const StyledModal = styled(Modal)``;

const FallBackText = styled.p`
  color: ${({ theme }) => theme.color};
  font-size: 26px;
  margin-bottom: 35px;
  text-align: center;
`;
export default MyCollaborationsRequests;
