import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGetAllDetailsMutation } from "../../../../../redux/services/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { setChatMessages } from "../../../../../redux/services/chat/chatSlice";
import ReactHtmlParser from "react-html-parser";
import { PropTypes } from "prop-types";
const PanelBody = ({ messages }) => {
  const dispatch = useDispatch();
  const boxRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [userPhoto, setUserPhoto] = useState();

  const otherUser = useSelector((state) => state.chat.chatData);
  const oldMessages = useSelector((state) => state.chat.chatMessages);
  const defaultProfile =
    "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492";
  const allMessages = oldMessages?.length ? [...oldMessages, ...messages] : messages;

  const [getUserDetails] = useGetAllDetailsMutation();

  useEffect(() => {
    getUserDetails().then((data) => {
      setUserId(data.data["Profile Details"].user.id);
      setUserPhoto(data.data["Profile Details"].userImageUrl);
      dispatch(setChatMessages([]));
    });
    boxRef.current.scrollTop = boxRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    boxRef.current.scrollTop = boxRef.current.scrollHeight;
  }, [messages]);

  return (
    <Box ref={boxRef}>
      {allMessages.map((message, index) => {
        const isSelf = message.createdBy === userId;
        if (isSelf) {
          return (
            <SendedWrapper key={index}>
              <Avatar image={userPhoto || defaultProfile}></Avatar>
              <Wrapper>
                <SendedMessageBox>
                  {ReactHtmlParser(message.message || message.body)}
                </SendedMessageBox>
                <TimeSpan>{message?.createdAt.slice(11, 16)}</TimeSpan>
              </Wrapper>
            </SendedWrapper>
          );
        } else {
          return (
            <RecivedWrapper key={index}>
              <Avatar image={otherUser[0].userImageUrl || defaultProfile}></Avatar>
              <Wrapper>
                <RecivedMessageBox>
                  {ReactHtmlParser(message.message || message.body)}
                </RecivedMessageBox>
                <TimeSpan>{message?.createdAt.slice(11, 16)}</TimeSpan>
              </Wrapper>
            </RecivedWrapper>
          );
        }
      })}
    </Box>
  );
};

// Mini Components
const Avatar = ({ image }) => {
  return (
    <AvatarBox>
      <img
        src={image}
        alt="avatar"
        style={{
          width: 35,
          height: 35,
          borderRadius: 50,
        }}
      />
    </AvatarBox>
  );
};

PanelBody.propTypes = {
  messages: PropTypes.array,
};

Avatar.propTypes = {
  image: PropTypes.string,
};

export default PanelBody;

const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  gap: 50px;
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
`;

const SendedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  max-width: 500px;
`;

const RecivedWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;
  max-width: 500px;
  align-self: flex-end;
`;

const AvatarBox = styled.div`
    padding: 5px;
    padding-bottom: 0;
    border-radius: 50%;
    // border: 1px solid #b7b9ff;
    width: 35px
    height: 35px;
    position: relative;
`;

const SendedMessageBox = styled.div`
  background-color: #0d0d0d;
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid #2068a5;
`;

const RecivedMessageBox = styled.div`
  background-color: #3a3a3a;
  padding: 8px 12px;
  border-radius: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const TimeSpan = styled.div`
  color: #b7b9ff;
  font-size: 12px;
  position: absolute;
  bottom: -25px;
  left: 0px;
  background-color: transparent;
  color: #434343;
  font-size: 14px;
`;
