// Libraries
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  selectUser,
  signout,
  setUserImage,
  setUserDetails,
  setUserUserTypes,
  setUserName,
} from "../../../redux/services/user/userSlice";
import { isValidName } from "../../../services/Helpers";
import { uploadBlob } from "../../../services/BlobServices";
import {
  useDeleteMutation,
  useGetAllDetailsMutation,
  useUpdateMutation,
} from "../../../redux/services/user/userApi";
import { useUserTypesMutation } from "../../../redux/services/utils/utilsApi";
// Components
import MuzeButton from "../../../components/muzeBtn/MuzeButton";
import DropBox from "../../../components/dropBox/DropBox";
import DropDown from "../../../components/dropdown/DropDown";
import Loading from "../../../components/loading/Loading";
import { toast, ToastContainer } from "react-toastify";
// Assets
import "react-toastify/dist/ReactToastify.css";
import { Flex } from "../../..";
import { Button } from "../../signin/Signin";
// Translation hook

// DropDown Style

const dropDownStyles = {
  color: "#262626",
  padding: "0 0 0 25px",
  fontSize: "20px",
};

const ltrBG =
  "linear-gradient(252.51deg, rgba(255, 255, 255, 0.7) 15.97%, rgba(173, 173, 173, 0.658) 100%)";

const rtlBG =
  " linear-gradient(104.76deg, rgba(255, 255, 255, 0.7) -2.57%, rgba(173, 173, 173, 0.658) 92.68%)";
const colourStyles = {
  control: (styles) => ({
    ...styles,
    background: "transparent",
    cursor: "pointer",
    height: "50px",
    "border-radius": "8px",
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "white",
      backgroundColor: state.isSelected ? "#D4A9E4" : "transparent",
      cursor: "pointer",
    };
  },
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      cursor: "pointer",
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      color: "white",
      cursor: "pointer",
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      background: document.dir === "ltr" ? ltrBG : rtlBG,
      border: "1px solid rgba(255, 255, 255, 0.8)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      backdropFilter: "blur(10px)",
      cursor: "pointer",
      maxHeight: "30vh",
      overFlow: "auto",
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: "white",
    };
  },
};

const ProfileSettings = () => {
  const { t } = useTranslation();
  const { user } = useSelector(selectUser);
  // To hold all user details on every update
  const userDetails = useSelector((state) => state.user.userDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setformData] = useState({
    type: "add",
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    userImageName: user.userImage.blobName,
    userImageUrl: user.userImage.blobUrl,
    userTypes: user.userType?.map((value) => {
      return value.name;
    }),
    bio: userDetails.bio,
    instagramLink: userDetails.instagramLink,
    spotifyLink: userDetails.spotifyLink,
    soundcloudLink: userDetails.soundcloudLink,
    youtubeLink: userDetails.youtubeLink,
  });
  const [userTypes, setUserTypes] = useState([]);
  const [image, setImage] = useState({
    src: formData.userImageUrl || "",
  });
  const [getUserDetails] = useGetAllDetailsMutation();
  const [getUserTypes, { data: userTypesData, isSuccess: isUserTypesSuccess }] =
    useUserTypesMutation();
  const [prefrences, setPrefrences] = useState({
    userType: user.userType?.map((value) => {
      return value.name;
    }),
  });

  useEffect(() => {
    // set user types on every update
    setformData((prev) => {
      return {
        ...prev,
        userTypes: prefrences.userType,
      };
    });
  }, [prefrences]);

  const [update, { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading }] = useUpdateMutation();
  const [
    deleteUser,
    { isSuccess: isDeleteSuccess, isError: isDeleteError, isLoading: isDeleteLoading },
  ] = useDeleteMutation();
  const handleChange = (event) => {
    setformData((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };
  const checkFormErrors = () => {
    const { firstName } = formData;
    let isErrorExist = false;

    if (!isValidName(firstName)) {
      isErrorExist = true;
      document.getElementById("firstName").style.borderColor = "red";
      document.getElementById("firstNameError").style.display = "inline";
      document.getElementById("firstNameInput").style.width = "50%";
    } else {
      document.getElementById("firstName").style.borderColor = "black";
      document.getElementById("firstNameError").style.display = "none";
      document.getElementById("firstNameInput").style.width = "80%";
    }

    // Removed validations for Instagram, Spotify, YouTube, and SoundCloud URLs

    return isErrorExist;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Convert userTypes from array of strings to array of objects
    const userTypesObjects = prefrences.userType.map((type, index) => ({
      id: index + 1,
      name: type,
    }));

    // post to update endpoint if there are no field errors in the form
    if (!checkFormErrors()) {
      // check if there is a new image uploaded
      if (image.src !== formData.userImageUrl) {
        if (
          image.src ===
          "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492"
        ) {
          formData.userImageName = "default-avatar";
          formData.userImageUrl =
            "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492";
        } else {
          const uploadedImage = await uploadNewImage(image);
          formData.userImageName = uploadedImage.blobName;
          formData.userImageUrl = uploadedImage.blobUrl;
        }
      }

      dispatch(setUserUserTypes(userTypesObjects));
      await update(formData).then((res) => {
        console.log(res.data);
        dispatch(
          setUserName({
            firstName: res.data.userInfo.user.firstName,
            lastName: res.data.userInfo.user.lastName,
          })
        );
        navigate("/profile");
      });
    }
  };

  const handleDelete = async () => {
    await deleteUser();
  };

  const onHandleDeleteModal = () => {
    setShowModal(true);
  };

  const uploadNewImage = async (image) => {
    const newImageBlob = await uploadBlob(image.src, "img");
    if (newImageBlob.blobUrl) {
      dispatch(setUserImage(newImageBlob));
    }

    return newImageBlob;
  };

  useEffect(() => {
    getUserTypes();
    getUserDetails().then((res) => {
      dispatch(setUserDetails(res.data["Profile Details"]));
      setPrefrences({
        userType: res.data["Profile Details"].userTypes?.map((value) => {
          return value.name;
        }),
      });
    });
  }, []);

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success("Updated information successfully");
      getUserDetails().then((res) => dispatch(setUserDetails(res.data["Profile Details"])));
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success("Deleted user successfully");
      dispatch(signout());
      navigate("/signin");
    } else if (isDeleteError) {
      toast.error("Failed to delete user");
    }
  }, [isDeleteSuccess, isDeleteError]);
  useEffect(() => {
    if (isUserTypesSuccess && userTypesData) {
      // Here, update the state or props to include the fetched user types
      // For example, if you store user types in a state:
      setUserTypes(userTypesData.userTypes);
    }
  }, [isUserTypesSuccess, userTypesData]);

  return isUpdateLoading || isDeleteLoading ? (
    <StyledLoading />
  ) : (
    <>
      <Container onSubmit={handleSubmit}>
        <InputContainer>
          <InputWrapper>
            <InputLabel>Email</InputLabel>
            <Input style={{ color: "black" }} defaultValue={user.email} readOnly name="email" />
            <FieldError id="emailError">Please Enter Valid Email</FieldError>
          </InputWrapper>
        </InputContainer>
        <Flex Align="center" gap="20px">
          <SectionTitle>Profile Image</SectionTitle>
          <StyledDropBox image={image} setImage={setImage} />
        </Flex>
        <InputContainer id="firstName">
          <InputWrapper>
            <InputLabel>First Name</InputLabel>
            <Input
              defaultValue={userDetails.firstName}
              name="firstName"
              id="firstNameInput"
              onChange={handleChange}
              maxLength={20}
              style={{ color: "black" }}
              placeholder="Type here..."
            />
            <FieldError id="firstNameError">Please enter a valid name</FieldError>
          </InputWrapper>
        </InputContainer>
        <InputContainer id="lastName">
          <InputWrapper>
            <InputLabel>Last Name</InputLabel>
            <Input
              defaultValue={userDetails.lastName}
              name="lastName"
              style={{ color: "black" }}
              onChange={handleChange}
              id="lastNameInput"
              maxLength={20}
              placeholder="Type here..."
            />
          </InputWrapper>
        </InputContainer>

        <StyledDropDown
          id="userType"
          allowSelectAll={false}
          setState={setPrefrences}
          selectStyles={colourStyles}
          styles={dropDownStyles}
          name={"userType"}
          isMulti={true}
          placeholder={"Select User Type"}
          options={
            userTypes.length > 0
              ? userTypes?.map((value) => {
                  return { value, label: t(value) };
                })
              : null
          }
          optionsSelected={prefrences.userType}
          isUserTypes={true}
        />
        <FieldError id="userTypeError">Please choose at least one user type</FieldError>

        <BioInputContainer id="bio">
          <InputLabel style={{ marginLeft: "4%" }}>Bio</InputLabel>
          <BioTextArea
            onChange={handleChange}
            placeholder="Max 240 characters"
            maxLength={240}
            name="bio"
            defaultValue={userDetails !== undefined && userDetails.bio ? userDetails.bio : null}
          />
          <BioCounter style={{ color: "white" }}>{formData.bio?.length}/240</BioCounter>
        </BioInputContainer>
        <HeaderTitle>Social</HeaderTitle>
        <InputContainer id="spotify">
          <InputWrapper>
            <InputLabel>Spotify</InputLabel>
            <Input
              defaultValue={
                userDetails !== undefined && userDetails.spotifyLink && userDetails.spotifyLink
              }
              style={{ color: "black" }}
              name="spotifyLink"
              onChange={handleChange}
              id="spotifyInput"
              placeholder="Spotify Link"
            />
            <FieldError id="spotifyError">Please Enter Valid spotify Link</FieldError>
          </InputWrapper>
        </InputContainer>
        <InputContainer id="soundCloud">
          <InputWrapper>
            <InputLabel>SoundCloud</InputLabel>
            <Input
              defaultValue={
                userDetails !== undefined &&
                userDetails.soundcloudLink &&
                userDetails.soundcloudLink
              }
              style={{ color: "black" }}
              name="soundcloudLink"
              onChange={handleChange}
              id="soundCloudInput"
              placeholder="SoundCloud Link"
            />
            <FieldError id="soundCloudError">Please Enter Valid SoundCloud Link</FieldError>
          </InputWrapper>
        </InputContainer>
        <InputContainer id="youtube">
          <InputWrapper>
            <InputLabel>Youtube</InputLabel>
            <Input
              defaultValue={
                userDetails !== undefined && userDetails.youtubeLink && userDetails.youtubeLink
              }
              style={{ color: "black" }}
              name="youtubeLink"
              onChange={handleChange}
              id="youtubeInput"
              placeholder="Youtube Link"
            />
            <FieldError id="youtubeError">Please Enter Valid Youtube Link</FieldError>
          </InputWrapper>
        </InputContainer>
        <InputContainer id="instagram">
          <InputWrapper>
            <InputLabel>Instagram</InputLabel>
            <Input
              defaultValue={
                userDetails !== undefined && userDetails.instagramLink && userDetails.instagramLink
              }
              style={{ color: "black" }}
              name="instagramLink"
              onChange={handleChange}
              id="instagramInput"
              placeholder="Instagram Link"
            />
            <FieldError id="instagramError">Please Enter Valid Instagram Link</FieldError>
          </InputWrapper>
        </InputContainer>
        <ButtonsContainer>
          <DeleteButton onClick={onHandleDeleteModal}>Delete Account</DeleteButton>
          <StyledMuzeButton type="submit">Save Changes</StyledMuzeButton>
        </ButtonsContainer>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          limit={3}
          rtl={document.dir === "rtl"}
          pauseOnFocusLoss
          draggable={true}
          pauseOnHover
          toastStyle={{ backgroundColor: "black" }}
        />
      </Container>
      <StyledModal
        show={showModal}
        onHide={onHandleDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure want to delete account?
          </Modal.Title>
        </Header>
        <ModalBody>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete Account</Button>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export const Container = styled.form`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => {
    if (theme === "dark") {
      return "rgba(35, 35, 35, 0.75)";
    }
    return "#f8f8f8";
  }};
  border: 1px solid
    ${({ theme }) => {
      if (theme === "dark") {
        return "rgba(35, 35, 35, 0.75)";
      }
      return "#0000002a";
    }};
  border-radius: 8px;
  margin: 15px 0;
`;
const BioInputContainer = styled(InputContainer)`
  textarea {
    padding: 10px 0;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2%;
  width: 95%;
  max-width: 100%;
`;
export const InputLabel = styled.div`
  cursor: default;
  font-weight: 500;
  font-size: 20px;
  min-width: 120px;
  width: max-content;
  white-space: nowrap;
  padding: 10px 10px 10px 0;

  [data-theme="dark"] & {
    color: #262626;
  }
`;
export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
`;

const StyledDropDown = styled(DropDown)`
  margin-top: 1%;
  margin-bottom: 3%;
  width: 100%;
  background: ${({ theme }) => getBackgroundColor(theme)};
  padding-left: 30px;
  font-size: 20px;
  border: 0px;
  // border: 1px solid ${({ theme }) => getBorderColor(theme)};
  // color: #000;
  // border-radius: 8px;

  /* Add a media query for responsive styling */
  @media (max-width: 768px) {
    /* Adjust the styling for smaller screens */
    margin-top: 2%; /* Example adjustment */
    margin-bottom: 2%; /* Example adjustment */
    font-size: 16px; /* Example adjustment */
    /* Add any other responsive adjustments as needed */
  }

  &&& {
    /* Your styles for the parent div here */
    /* For example, you can add background color to the parent div */
    border-radius: 8px;
    padding: 0;
    color: #000;
  }
`;

const BioTextArea = styled.textarea`
  width: 80%;
  height: 90px;
  resize: none;
  background: transparent;
  border: none;
  color: #000000a1;
  outline: none;
  transform: translateX(-50px);
`;
const BioCounter = styled.div`
  align-self: flex-end;
  margin-right: ${() => (document.dir === "ltr" ? "2%" : "0%")};
  margin-left: ${() => (document.dir === "rtl" ? "2%" : "0%")};
  margin-bottom: 2%;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 8vh;
`;
const DeleteButton = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color};
`;
const StyledMuzeButton = styled(MuzeButton)``;
const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color};
`;
const StyledDropBox = styled(DropBox)`
  color: white;
  width: 160px;
  justify-content: space-around;
`;
const HeaderTitle = styled.div`
  align-self: flex-start;
  font-size: 20px;
  font-weight: 500;
  margin-top: 2%;
  color: ${({ theme }) => theme.color};
`;
const FieldError = styled.div`
  color: red;
  display: none;
  min-width: fit-content;
`;
const StyledLoading = styled(Loading)`
  width: 100%;
  height: 100%;
`;

const StyledModal = styled(Modal)``;
const Header = styled(Modal.Header)`
  justify-content: center;
  border: none;
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  justify-content: space-around;
`;

const getBackgroundColor = (theme) => {
  if (theme === "dark") {
    return "rgba(35, 35, 35, 0.75)";
  }
  return "#f8f8f8";
};

const getBorderColor = (theme) => {
  if (theme === "dark") {
    return "rgba(35, 35, 35, 0.75)";
  }
  return "#00002a";
};

export default ProfileSettings;
