import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Title>Muze - Get in Touch !</Title>
      <Paragraph>
        At Muze, we&apos;re here to support your music journey. Whether you have a question, need
        help navigating the platform, or simply want to share feedback, we&apos;re happy to hear
        from you.
      </Paragraph>
      <Paragraph style={{ marginTop: 20, marginBottom: 30 }}>
        Here&apos;s how you can reach out:
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            <strong>Email:</strong> Our dedicated support team is available at:{" "}
            <a href="mailto:info@muze-network.com">Info@muze-network.com</a>
          </li>
        </ul>
      </Paragraph>
      <Paragraph> We aim to respond to all inquiries within 24 business hours.</Paragraph>
      <Paragraph style={{ marginTop: 20, marginBottom: 30 }}>For additional resources:</Paragraph>
      <Paragraph>
        Remember you can always report any technical difficulties using our
        <LinearText onClick={() => navigate("/feedback")}> Give Feedback</LinearText> Option.
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            You can browse our comprehensive FAQ section for answers to frequently asked questions.
          </li>
        </ul>
      </Paragraph>
      <Paragraph style={{ marginTop: 30 }}>We look forward to connecting with you!</Paragraph>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;

const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
  margin-bottom: 30px;
`;

const Paragraph = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  font-size: 18px;
  margin-bottom: 20px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    margin-bottom: 10px;
  }

  a {
    background: linear-gradient(to right, #016299, #f77599);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
`;
const LinearText = styled.span`
  background: linear-gradient(to right, #016299, #f77599);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`;

export default ContactUs;
