// Libraries
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
// Services
import { selectUser, signout } from "../../redux/services/user/userSlice";
import { useSignoutMutation } from "../../redux/services/user/userApi";
// Components
import Search from "./../search/Search";
import BoxContainer from "../boxContainer/BoxContainer";
// Assets
import "./Navbar.css";
import "react-toastify/dist/ReactToastify.css";
import MuzeButton from "../muzeBtn/MuzeButton";
import { setSearchList } from "../../redux/services/search/searchSlice";
import { Modal } from "react-bootstrap";
import LineWrapper from "../lineWrapper/LineWrapper";

// const Bell = `/icons/navbar/bell.svg`;
// const Notifications = `/icons/navbar/notifications.svg`;
const DropDownArrow = `/icons/navbar/drop-arrow.svg`;
// const InboxCircle = `/icons/navbar/inbox-circle.svg`;
const defaultUserImage = `./icons/common/user.jpg`;

const Navbar = () => {
  const dispatch = useDispatch();
  const { signedOut, user } = useSelector(selectUser);
  const [showModal, setShowModal] = useState(false);
  const [signoutMutation, { isSuccess: isSignoutSuccess, isError: isSignoutError }] =
    useSignoutMutation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const handleDropDownOptionClick = (event) => {
    navigate(`/${event.target.name}`);
  };
  const handleSignout = () => {
    signoutMutation();
    document.documentElement.setAttribute("data-theme", "");
  };
  useEffect(() => {
    if (isSignoutSuccess) {
      dispatch(signout());
      navigate("/explore");
      toast.success(t("Signed out successfully"), {
        theme: document.documentElement.getAttribute("data-theme"),
      });
    }
  }, [isSignoutSuccess]);
  useEffect(() => {
    if (isSignoutError) {
      toast.error(t("Error while signing out"), {
        theme: document.documentElement.getAttribute("data-theme"),
      });
    }
  }, [isSignoutError]);
  useEffect(() => {
    dispatch(setSearchList());
  });

  return pathname === "/signup" || pathname === "/signin" ? (
    <LogoNavbar>
      <HeaderNavbarLogo
        id="navbar-logo"
        onClick={() => navigate("/")}
        as={motion.div}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
      ></HeaderNavbarLogo>
    </LogoNavbar>
  ) : (
    <>
      <Modal show={showModal} onHide={() => setShowModal(!showModal)} size="lg" centered>
        <Modal.Body className="customBackground">
          <HowItWorksBoxContainer>
            <HowItWorksContent>
              <h2>Unleash Your Musical Potential on Muze</h2>
              <LineWrapper theStyle={{ marginTop: -20, marginBottom: 30 }}></LineWrapper>
              <ul>
                <li>
                  <b>Connect Globally</b>, Create Locally: Build connections with musicians from
                  around the world.
                </li>
                <li>
                  <b>Showcase Your Talent</b>: Craft a professional portfolio to impress fans and
                  industry professionals.
                </li>
                <li>
                  <b>Collaborate & Create</b>: Team up with other artists and agencies to bring your
                  musical vision to life.
                </li>
                <li>
                  <b>Network & Grow</b>: Discover new opportunities and expand your reach within the
                  music industry.
                </li>
              </ul>
              <p>Join Muze today and take your music career to the next level</p>
            </HowItWorksContent>
          </HowItWorksBoxContainer>
        </Modal.Body>
      </Modal>
      <StyledNavbar>
        <NavbarLogo
          id="navbar-logo"
          onClick={() => navigate("/")}
          as={motion.div}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.9 }}
        ></NavbarLogo>
        {isTabletOrMobile && signedOut ? null : <StyledSearch />}
        {!signedOut ? (
          <ProfileContainer>
            <motion.div
              style={{
                alignSelf: "center",
                position: "relative",
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => {
                navigate("/messaging");
              }}
            >
              {/* <StyledInboxCircle />
              <NotificationsNumber>+</NotificationsNumber> */}
              {/* <img src={Notifications} style={{ cursor: "pointer" }} /> */}
            </motion.div>
            <motion.div
              style={{ alignSelf: "center", marginLeft: "1vw" }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
            >
              {/* <img src={Bell} style={{ cursor: "pointer" }} /> */}
            </motion.div>
            {isTabletOrMobile ? null : (
              <>
                <UserImage
                  src={user.userImage?.blobUrl ? user.userImage.blobUrl : defaultUserImage}
                  onClick={() => navigate("/profile")}
                  as={motion.img}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  className="Profile_Icon"
                />
                <ProfileName>
                  {user.lastName.length === 0
                    ? user.firstName
                    : user.firstName + " " + user.lastName[0] + "."}
                </ProfileName>
                <Dropdown>
                  <Dropdown.Toggle>
                    <img src={DropDownArrow} style={{ cursor: "pointer" }} />
                  </Dropdown.Toggle>
                  <DropDownMenu>
                    <DropDownItem name="profile" onClick={handleDropDownOptionClick}>
                      {t("profile")}
                    </DropDownItem>
                    <DropDownItem name="settings" onClick={handleDropDownOptionClick}>
                      {t("settings-privacy")}
                    </DropDownItem>
                    <DropDownItem name="feedback" onClick={handleDropDownOptionClick}>
                      {t("giveFeedback")}
                    </DropDownItem>
                    <DropDownItem onClick={() => handleSignout()}>{t("signOut")}</DropDownItem>
                  </DropDownMenu>
                </Dropdown>
              </>
            )}
          </ProfileContainer>
        ) : (
          <NavbarOptions>
            {!signedOut ? null : (
              <ModalLink className="signin-Btn" onClick={() => setShowModal(true)}>
                Our Features
              </ModalLink>
            )}
            <SigninBtn
              className="signin-Btn"
              type="button"
              onClick={() => navigate("/signin")}
              as={motion.button}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {t("signIn")}
            </SigninBtn>
            <SignupBtn
              type="button"
              onClick={() => navigate("/signup")}
              as={motion.button}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {t("signUp")}
            </SignupBtn>
          </NavbarOptions>
        )}
      </StyledNavbar>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        limit={3}
        rtl={document.dir === "rtl"}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        toastStyle={{ backgroundColor: "black" }}
      />
    </>
  );
};

const UserImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 1vw;
  margin-left: 1vw;
  padding: 0.25vw;
  cursor: pointer;
  border: 3px solid;
  border-color: ${({ theme }) => theme.background};
  @media (min-width: 641px) {
    width: 35px;
    height: 35px;
  }
  @media (min-width: 1024px) {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
`;

const StyledNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(100px);
  border-radius: 8px;
  z-index: 99;
  background-image: url(${({ theme }) => theme.navbarBackgroundImg});
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: 100% 100%;
  padding: 10px 40px 10px 30px;
  height: 80px;
  width: 100%;
  @media (min-width: 641px) {
    border-radius: 0px;
  }
`;
const LogoNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.navbarBackground};
  backdrop-filter: blur(100px);
  width: 100%;
  height: 80px;
  background: rgba(32, 32, 32, 0.4);
  position: absolute;
  top: 0;
  z-index: 99;
`;
const NavbarLogo = styled.div`
  cursor: pointer;
  background-image : ${({ theme }) => theme.navbarLogo}
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: 80% 300%;
  @media (min-width: 280px) {
    background-image:${({ theme }) => theme.navbarLogo}
    width: 20%;
    height: 70%;
    margin-right: 1vw;
    margin-left: 1vw;
  }
  @media (min-width: 641px) {
    width: 15%;
    height: 70%;
    margin-right: 1%;
    margin-left: 1%;
  }
  @media (min-width: 1024px) {
    background-image: ${({ theme }) => `url(${theme.navbarLogo})`};
    width: 20%;
    height: 75%;
    margin: 0%;
  }
`;

const HeaderNavbarLogo = styled(NavbarLogo)`
  background-image: ${({ theme }) => theme.navbarLogo} !important;
`;

const StyledSearch = styled(Search)`
  max-width: 600px;
  @media (max-width: 567px) {
    display: none;
  }
  input {
    html[data-theme="dark"] & {
      color: #fff !important;
    }
  }
`;

const NavbarOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 2%;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const SigninBtn = styled(MuzeButton)`
  border: ${({ theme }) => theme.navbarSigninBtnBorder};
  border-radius: 20px;
  box-sizing: border-box;
  background-color: transparent;
  color: ${({ theme }) => theme.color};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  padding: 13px 27px;
  cursor: pointer;
  &:hover {
    background-color: gray;
  }
`;
const SignupBtn = styled.button`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  padding: 13px 27px;
  color: white;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: lightskyblue;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 15%;
  gap: 0.5rem;
  @media (min-width: 280px) {
    width: 20%;
  }
  @media (min-width: 641px) {
    width: 15%;
  }
  @media (min-width: 1024px) {
    width: 15%;
  }
`;
const ProfileName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  cursor: default;
  color: ${({ theme }) => theme.navbarTextColor};
`;
const DropDownMenu = styled(Dropdown.Menu)`
  background: ${({ theme }) => theme.background};
  border: 1px solid;
  border-color: ${({ theme }) => theme.navbarDropDownMenuBorderColor};
  border-radius: 0px 0px 24px 24px;
`;
const DropDownItem = styled(Dropdown.Item)`
  margin-bottom: 5%;
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  &:hover {
    border-radius: 24px 24px 24px 24px;
    background-color: transparent;
    color: ${({ theme }) => theme.color};
  }
`;
// const NotificationsNumber = styled.div`
//   cursor: pointer;
//   position: absolute;
//   color: ${({ theme }) => theme.color};
//   font-size: 14px;
//   font-weight: 500;
//   top: -9px;
//   right: -2px;
// `;
// const StyledInboxCircle = styled.img.attrs({ src: InboxCircle })`
//   cursor: pointer;
//   position: absolute;
//   top: -5px;
//   right: -5px;
// `;

const ModalLink = styled.p`
  background: linear-gradient(90deg, #016299 0%, #f77599 92.59%);
  border-radius: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  padding: 13px 27px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: lightskyblue;
  }
`;

const HowItWorksContent = styled.div`
  color: #ffffff;

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 10px;
    text-align: justify;
  }

  p {
    font-style: italic;
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
  }
`;
const HowItWorksBoxContainer = styled(BoxContainer)`
  margin-right: auto;
  padding: 20px 10px;
  @media (min-width: 1024px) {
    padding: 40px 50px;
  }
  z-index: 1;
  display: flex;
  align-items: space-between;
  justify-content: center;
`;
export default Navbar;
