import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const FAQs = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Frequently Asked Questions (FAQs)</Title>
      <Paragraph style={{ marginBottom: -20 }}>
        Welcome to the Muze FAQs section! Here you&apos;ll find answers to common questions about
        our platform and services.
      </Paragraph>
      <Paragraph>
        If you can&apos;t find what you&apos;re looking for, feel free to reach out to us directly
        at <a href="mailto:info@muze-network.com">info@muze-network.com</a>. We&apos;re here to help
        you navi- gate your Muze experience and make the most out of your journey in the music
        community.
      </Paragraph>
      <Question>
        <QuestionNumber>1.</QuestionNumber> What is Muze?
        <Answer>
          Muze is a social platform designed for musicians to connect, collaborate, and grow their
          music networks.
        </Answer>
      </Question>

      <Question>
        <QuestionNumber>2.</QuestionNumber> Is Muze free to use?
        <Answer>Yes, Muze is currently free to use for all members.</Answer>
      </Question>
      <Question>
        <QuestionNumber>3.</QuestionNumber> What features does Muze offer?
        <Answer>
          Muze offers a range of features including profile creation, messaging, collaboration
          tools, and online bookings. Visit our website for a full list of features and
          capabilities.
        </Answer>
      </Question>
      <Question>
        <QuestionNumber>4.</QuestionNumber> How can I report a problem or provide feedback? if you
        encounter any issues or have feedback to share, you can use our Give Feedback tool or email
        us at info@muze-network.com
        <Answer>
          If you encounter any issues or have feedback to share, you can use our{" "}
          <LinearText onClick={() => navigate("/feedback")}> Give Feedback </LinearText> Tool or
          email us at <a href="mailto:info@muze-network.com">info@muze-network.com</a>
        </Answer>
      </Question>
      <ClosingStatement>
        Stay tuned for updates and additional FAQs as Muze continues to evolve. Thank you for being
        a part of our community!
      </ClosingStatement>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;

const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;

const Paragraph = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  font-size: 16px;
  padding: 30px 0 20px;
  a {
    background: linear-gradient(to right, #016299, #f77599);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
`;

const Question = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  font-size: 16px;
  padding: 20px 0;
`;

const QuestionNumber = styled.span`
  font-weight: bold;
`;

const Answer = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.color};
  font-size: 16px;
  margin-top: 8px;

  a {
    background: linear-gradient(to right, #016299, #f77599);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
`;

const ClosingStatement = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  font-size: 16px;
  padding-top: 20px;
`;

const LinearText = styled.span`
  background: linear-gradient(to right, #016299, #f77599);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`;

export default FAQs;
