import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MessagesHeading from "./parts/MessagesHeading";
import MessagesChat from "./parts/MessagesChat";

// Redux
import { selectUser } from "../../../../redux/services/user/userSlice";
import { useSelector, useDispatch } from "react-redux";

import { useJoinRoomMutation, useGetRoomsMutation } from "../../../../redux/services/chat/chatApi";

import {
  setUUID,
  setUserChats,
  setChatMessages,
  setChatData,
} from "../../../../redux/services/chat/chatSlice";

const Messages = () => {
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(null);

  const [joinRoom] = useJoinRoomMutation();
  const [getRooms] = useGetRoomsMutation();

  const { user } = useSelector(selectUser);
  const chats = useSelector((state) => state.chat.userChats);

  const joinRoomHandler = (index, chat) => {
    setActiveIndex(index === activeIndex ? null : index);

    joinRoom({ uuid: chat.uuid }).then((room) => {
      dispatch(setUUID(room.data["Room Data"].uuid));
      dispatch(setChatMessages(room.data["Room Data"].messages));
      dispatch(
        setChatData(room.data["Profiles in Room"].filter((profile) => profile.user.id !== user.pk))
      );
    });
  };

  const activeChat = useSelector((state) => state.chat.activeChat);
  useEffect(() => {
    dispatch(setUserChats([]));
    if (activeChat) {
      joinRoom({ uuid: activeChat }).then((room) => {
        dispatch(setUUID(room.data["Room Data"].uuid));
        dispatch(setChatMessages(room.data["Room Data"].messages));
        dispatch(
          setChatData(
            room.data["Profiles in Room"].filter((profile) => profile.user.id !== user.pk)
          )
        );
      });
      setActiveIndex(0);
    }
    getRooms({ userId: user.pk }).then((rooms) => {
      dispatch(setUserChats(rooms.data["User Rooms"]));
    });
  }, []);

  return (
    <Container>
      <MessagesHeading setActiveIndex={setActiveIndex} />
      <MessagesChatContainer>
        {chats &&
          chats.map((chat, index) => {
            const profile = chat["User Profiles"].find(
              (profile) => profile.firstName !== user.firstName
            );
            return (
              <MessagesChat
                key={index}
                isActive={index === activeIndex}
                onClick={() => joinRoomHandler(index, chat)}
                chat={profile}
              />
            );
          })}
      </MessagesChatContainer>
    </Container>
  );
};

export default Messages;

const Container = styled.div`
  flex: 1;
  color: #fff;
  padding: 20px;
  background-color: #0e0e0e99;
  border-radius: 20px;
  max-width: 400px;
  height: 750px;
  overflow: scroll;
  overflow-x: hidden;
`;

const MessagesChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;
