import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import BookingCard from "../../../components/cards/booking/BookingCard";

const MyBookings = () => {
    const mybookings = useSelector((state) => state.user.userBookings);
    return (
        <>
            {mybookings.length ? (
                <StudiosContainer>
                    {mybookings.map((booking) => (
                        <BookingCard
                            key={booking.id}
                            start={booking.start}
                            end={booking.end}
                            studioRoom={booking.studioRoom}
                        />
                    ))}
                </StudiosContainer>
            ) : (
                <Container>
                    <Text>No bookings yet</Text>
                </Container>
            )}
        </>
    );
};

const StudiosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 90%;
    height: 100%;
    overflow-y: auto;
`;
const Container = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    overflow-x: auto;
`;

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
`;

export default MyBookings;
