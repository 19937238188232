/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import "../booking/Slots.css";
import { useBookMutation } from "../../redux/services/booking/bookingApi";
import { useState } from "react";
import Loading from "../../components/loading/Loading";
const SlotsGrid = ({ slots, handleLoading }) => {
  const booked = useSelector((state) => state.booking.gridData);
  const studioId = useSelector((state) => state.booking.currentStudioId);
  const currentDate = useSelector((state) => state.booking.currentDate);
  const [book] = useBookMutation();
  const handleBook = (slot) => {
    handleLoading(true);
    const newDate = slot.slice(0, 2);
    const timing = newDate + ":00:00";
    book({
      studioId: studioId,
      startTime: currentDate + "-" + timing,
      noHours: 1,
    }).then((res) => {
      handleLoading(false);
    });
  };
  return (
    <div className="slots-container">
      {slots.map((slot) =>
        booked.includes(slot.slice(0, 2)) ? (
          <Button className="disabled" disabled>
            {slot}
          </Button>
        ) : (
          <Button onClick={() => handleBook(slot)}>{slot}</Button>
        )
      )}
    </div>
  );
};
const Button = styled.button`
  background: rgb(25, 25, 25);
  background: linear-gradient(
    90deg,
    rgba(25, 25, 25, 1) 27%,
    rgba(36, 36, 36, 1) 97%
  );
  border-radius: 10px;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
`;

export default SlotsGrid;
