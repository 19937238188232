// Libraries
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";

// Components
// Assets
import {
  useCreateCommentMutation,
  useGetAllPostCommentsMutation,
  useDeleteCommentMutation,
} from "../../redux/services/video/videoApi";
import { DefaultImage } from "../../services/BlobServices";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/loading/Loading";

import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";
import { selectUser, setSearchedUserDetails } from "../../redux/services/user/userSlice";
import { getAuthToken } from "../../services/cookieManager";

import Picker, { Theme } from "emoji-picker-react";
import { Modal } from "react-bootstrap";

const CommentBtn = `./icons/video-screen/comment-btn.png`;
const dots = "./icons/three-dots-vertical-svgrepo-com.svg";

const CommentSection = ({ author }) => {
  const { user } = useSelector(selectUser);
  const { pk } = user;
  const { state } = useLocation();
  const { id } = { state };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useSelector((state) => state.theme.theme);
  const [showPicker, setShowPicker] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [getAllComments] = useGetAllPostCommentsMutation();
  const [myComments, setMyComments] = useState([]);
  const [comment, setComment] = useState("");
  const [isLoading] = useState(false);
  const [createPost, { isSuccess: isCreateCommentSuccess, isError: isCreateCommentError }] =
    useCreateCommentMutation();
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const handleCommentSubmit = async (e) => {
    if (comment !== "") {
      e.preventDefault();
      await createPost({
        body: { comment, postId: state.id },
      }).then(() => {
        if (state.id) {
          getAllComments({
            postId: state.id,
          }).then((res) => {
            if (res.data) {
              setMyComments(res.data["All Post Comments"]);
            }
          });
        }
      });
      setComment("");
    }
  };

  const handleCommenterClick = (author) => {
    if (getAuthToken()) {
      getSearchedUser({
        profileId: author,
      }).then((res) => {
        if (res.data["Profile Details"].user === pk) {
          navigate("/profile");
        } else {
          dispatch(setSearchedUserDetails(res.data["Profile Details"]));
          navigate("/users");
        }
      });
    } else {
      navigate("/signin");
    }
  };
  const handleCommentOnChange = (e) => {
    setComment(e.target.value);
  };

  const handleEmojiClick = (e, emojiObject) => {
    setComment((comment) => comment + e.emoji);
    setShowPicker(false);
  };

  const deleteCommentHandler = (id) => {
    deleteComment({
      commentId: id,
    })
      .then((res) => {
        getAllComments({
          postId: state.id,
        }).then((res) => {
          if (res.data) {
            toast.success("Comment Deleted Successfully");
            setMyComments(res.data["All Post Comments"]);
          }
        });
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
        toast.error("Error deleting comment");
      });
  };
  const [emojiTheme, setEmojiTheme] = useState(theme === "dark" ? Theme.DARK : Theme.LIGHT);
  useEffect(() => {
    setShowPicker(false);
    if (theme === "dark") {
      setEmojiTheme(Theme.DARK);
    } else {
      setEmojiTheme(Theme.LIGHT);
    }
  }, [theme]);
  useEffect(() => {
    if (isCreateCommentSuccess) {
      toast.success("Created Comment Successfully");
    }
  }, [isCreateCommentSuccess]);
  useEffect(() => {
    if (isCreateCommentError) {
      toast.error("Sign in to comment");
    }
  }, [isCreateCommentError]);
  useEffect(() => {
    if (state.id) {
      getAllComments({
        postId: state.id,
      }).then((res) => {
        if (res.data) {
          setMyComments(res.data["All Post Comments"]);
        }
      });
    }
  }, [id]);

  return isLoading ? (
    <Loading />
  ) : (
    <CommentsSection>
      <CommentsWrapper>
        <CommentsHeader>Comments</CommentsHeader>
        {myComments.length ? (
          myComments.map((commentData) => {
            return (
              <CommentWrapper key={commentData.id}>
                <CommentImage
                  src={commentData.userImageUrl ? commentData.userImageUrl : DefaultImage}
                  onClick={() => handleCommenterClick(commentData.author)}
                />
                <UserInfo key={commentData.author}>
                  <UserName>{`${commentData?.firstName} ${commentData?.lastName}`}</UserName>
                  <Comment>{commentData.comment}</Comment>
                  {(user.pk === commentData.author || author === user.pk) && (
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(!showModal)}
                      size="sm"
                      centered
                    >
                      <ModalButton
                        style={{
                          padding: "15px",
                          cursor: "pointer",
                          borderBottom: "1px solid #ddd",
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#f00",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            setShowModal(false);
                            deleteCommentHandler(commentData.id);
                          }}
                        >
                          Delete
                        </p>
                      </ModalButton>
                      <ModalButton
                        style={{
                          padding: "15px",
                          cursor: "pointer",
                          borderBottomRightRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            color: "#000",
                          }}
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </p>
                      </ModalButton>
                    </Modal>
                  )}
                </UserInfo>
                <img
                  src={dots}
                  style={{
                    width: 25,
                    height: 50,
                    cursor: "pointer",
                  }}
                  className="dots-icon"
                  onClick={() => setShowModal(true)}
                />
              </CommentWrapper>
            );
          })
        ) : (
          <div>No Comment Yet</div>
        )}
        <div style={{ "margin-top": "23vh" }}>
          {showPicker && (
            <Picker
              pickerStyle={{
                width: "100%",
                left: "0px",
                bottom: "0px",
                zIndex: "100",
                margin: "23vh 0 0 0",
              }}
              onEmojiClick={handleEmojiClick}
              theme={emojiTheme}
            />
          )}
        </div>
      </CommentsWrapper>

      <PostComment onSubmit={(e) => handleCommentSubmit(e)}>
        <CommentInput
          placeholder={t("Write Comment Here...")}
          value={comment}
          onChange={handleCommentOnChange}
        />
        <EmojiButton className="emojiButton" onClick={() => setShowPicker(!showPicker)}>
          😀
        </EmojiButton>
        <CommentButton
          type="submit"
          src={CommentBtn}
          alt="Button"
          onClick={(e) => handleCommentSubmit(e)}
        />
      </PostComment>
    </CommentsSection>
  );
};

CommentSection.propTypes = {
  author: PropTypes.number,
};

const EmojiButton = styled.div`
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  border-radius: 50%;
  background: #161616;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const CommentsSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(frosted-glass-texture_33715-76.jpg), rgba(111, 111, 111, 0.09);
  box-shadow: 3px 4px 32px rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(32px);
  padding: 10px 20px 30px;
  max-width: 400px;
  width: 100%;
  max-height: 100vh;
  border-radius: 25px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;
const CommentsHeader = styled.div`
  padding: 30px 10px;
  border-bottom: 1px solid white;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.6px;

  color: ${({ theme }) => theme.color};
  [data-theme="light"] & {
    border-color: #676767;
  }
`;
const CommentsWrapper = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
  // &:hover {
  //   .dots-icon {
  //     visibility: visible;
  //   }
  // }
`;
const CommentWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
`;

const CommentImage = styled.img`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  cursor: pointer;
`;

const PostComment = styled.form`
  display: flex;
  width: 100%;
  margin-top: 10px;
  background: #161616;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const CommentInput = styled.input.attrs({ type: "text" })`
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.color};
  padding: 10px;
  [data-theme="light"] & {
    color: #ccc;
    &::placeholder {
      color: #ccc;
    }
  }
`;
const CommentButton = styled.img`
  cursor: pointer;
`;
const UserInfo = styled.div`
  flex: 2;
  position: relative;
`;
const UserName = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.color};
  cursor: pointer;
  padding: 0 0 6px;
`;
const Comment = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.color};
  width: fit-content;
  line-height: 20px;
`;

const ModalButton = styled.div`
  background-color: #fff;
`;

export default CommentSection;
