import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
const Image = ({ image, className }) => {
    return (
        <Img className={className} src={image.src} data-testid="test-image" />
    );
};

Image.propTypes = {
    image: PropTypes.object,
    className: PropTypes.string,
};
const Img = styled.img``;
export default Image;
