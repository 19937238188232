// Libraries
import React, { useState, useRef } from "react";
import styled from "styled-components";
// Components
import MuzeButton from "../../../components/muzeBtn/MuzeButton";
import { toast, ToastContainer } from "react-toastify";
// Assets
import "react-toastify/dist/ReactToastify.css";
// import { IconsBlobUrl } from "../../../services/BlobServices";
import { isStrongPassword } from "../../../services/Helpers";
import { Container, Input, InputContainer, InputLabel, InputWrapper } from "./ProfileSettings";

const EyeLogo = `./icons/common/eye.svg`;

const PasswordSettings = () => {
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!CheckFormErrors()) {
      document.getElementById("hint").style.visibility = "hidden";
      toast.success("Password Changed Successfully");
    } else {
      document.getElementById("hint").style.visibility = "visible";
      toast.error("Please Fix The Errors");
    }
  };
  const CheckFormErrors = () => {
    let isErrorExist = false;
    if (isStrongPassword(oldPasswordRef.current?.value)) {
      document.getElementById("oldPassword").style.borderColor = "#000000";
    } else {
      isErrorExist = true;
      document.getElementById("oldPassword").style.borderColor = "red";
    }
    if (isStrongPassword(newPasswordRef.current?.value)) {
      document.getElementById("newPassword").style.borderColor = "#000000";
    } else {
      isErrorExist = true;
      document.getElementById("newPassword").style.borderColor = "red";
    }
    if (
      isStrongPassword(confirmPasswordRef.current?.value) &&
      confirmPasswordRef.current?.value === newPasswordRef.current?.value
    ) {
      document.getElementById("confirmPassword").style.borderColor = "#000000";
    } else {
      isErrorExist = true;
      document.getElementById("confirmPassword").style.borderColor = "red";
    }
    if (oldPasswordRef.current?.value !== newPasswordRef.current?.value) {
      document.getElementById("oldPassword").style.borderColor = "#000000";
      document.getElementById("newPassword").style.borderColor = "#000000";
    } else {
      isErrorExist = true;
      document.getElementById("oldPassword").style.borderColor = "red";
      document.getElementById("newPassword").style.borderColor = "red";
    }
    return isErrorExist;
  };
  return (
    <Container onSubmit={handleSubmit}>
      <InputContainer id="oldPassword">
        <InputWrapper>
          <InputLabel style={{ minWidth: "max-content" }}>Old Password</InputLabel>
          <Input
            placeholder="Type Here..."
            style={{ color: "black" }}
            type={showOldPassword ? "text" : "password"}
            ref={oldPasswordRef}
          ></Input>
          <Eye
            onClick={() => {
              setShowOldPassword(!showOldPassword);
            }}
          ></Eye>
        </InputWrapper>
      </InputContainer>
      <InputContainer id="newPassword">
        <InputWrapper>
          <InputLabel style={{ minWidth: "max-content" }}>New Password</InputLabel>
          <Input
            placeholder="Type Here..."
            style={{ color: "black" }}
            type={showNewPassword ? "text" : "password"}
            ref={newPasswordRef}
          ></Input>
          <Eye
            onClick={() => {
              setShowNewPassword(!showNewPassword);
            }}
          ></Eye>
        </InputWrapper>
      </InputContainer>
      <InputContainer id="confirmPassword">
        <InputWrapper>
          <InputLabel style={{ minWidth: "max-content" }}>Confirm New Password</InputLabel>
          <Input
            placeholder="Type Here..."
            type={showConfirmPassword ? "text" : "password"}
            style={{ marginLeft: "3%", color: "black" }}
            ref={confirmPasswordRef}
          ></Input>
          <Eye
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
          ></Eye>
        </InputWrapper>
      </InputContainer>
      <Hint id="hint">
        Passwords must contain:
        <ul>
          <li>a minimum of 1 lower case letter [a-z]</li>
          <li>a minimum of 1 upper case letter [A-Z]</li>
          <li>a minimum of 1 numeric character [0-9]</li>
          <li>{'a minimum of 1 special character: ~`!@#$%^&*()-_+={}[]|;:"<>,./?'}</li>
          <li> a minimum of 20 characters in length</li>
        </ul>
      </Hint>
      <MuzeButton type={"submit"}>Save Changes</MuzeButton>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        limit={3}
        rtl={document.dir === "rtl"}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        toastStyle={{ backgroundColor: "black" }}
      />
    </Container>
  );
};
const Eye = styled.img.attrs({ src: EyeLogo })`
  position: absolute;
  margin-left: 8%;
  cursor: pointer;
  @media (min-width: 240px) {
    left: ${() => (document.dir === "ltr" ? "90%" : "20%")};
  }
  @media (min-width: 641px) {
    left: ${() => (document.dir === "ltr" ? "75%" : "25%")};
  }
  @media (min-width: 1024px) {
    left: ${() => (document.dir === "ltr" ? "70%" : "20%")};
  }
`;
const Hint = styled.h6`
  display: block;
  color: ${({ theme }) => theme.color};
  width: 100%;
  padding: 20px 50px;
  box-shadow: 0 0 10px #fff inset;
  border-radius: 8px;
  margin: 0 0 30px;
  transition: none;

  html[data-theme="light"] & {
    box-shadow: 0 0 10px #c0c0c0 inset;
  }
`;

export default PasswordSettings;
