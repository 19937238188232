// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import MuzeButton from "../../components/muzeBtn/MuzeButton";
import CollabCard from "./../../components/cards/collaboration/CollabCard";
import {
  useLazyCollaborationListAllQuery,
  useListCollaborationOnGenresMutation,
  useListCollaborationOnSkillsMutation,
} from "../../redux/services/collaboration/collaborationApi";
// import SecondaryBtn from "../../components/secondary-btn/SecondaryBtn";
import FilterCollab from "../../components/filter/FilterCollab";
import Loading from "../../components/loading/Loading";

const AllCollaborations = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collabData, setCollabData] = useState();
  const [filteredCollabData, setFilteredCollabData] = useState(collabData);
  const { state } = useLocation();

  const musicians = pathname.includes("musicians");
  const genre = pathname.includes("genre");

  const [collaborationOnGenre] = useListCollaborationOnGenresMutation();
  const [collaborationOnSkills] = useListCollaborationOnSkillsMutation();
  const [allCollaborationData] = useLazyCollaborationListAllQuery();
  const [viewAllCollabs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (genre) {
      const apiObj = {
        genre: [state],
      };
      collaborationOnGenre(apiObj).then((res) => {
        setCollabData(res.data["matching collaborations on genres (own's requests filtered)"]);
        setFilteredCollabData(
          res.data["matching collaborations on genres (own's requests filtered)"]
        );
        setIsLoading(false);
      });
    } else if (musicians) {
      const apiObj = {
        lookingForSkill: [state],
      };
      collaborationOnSkills(apiObj).then((res) => {
        setCollabData(res.data["matching collaborations (own's requests filtered)"]);
        setFilteredCollabData(res.data["matching collaborations (own's requests filtered)"]);
        setIsLoading(false);
      });
    }
    if (!musicians && !genre) {
      allCollaborationData().then((response) => {
        if (!response.error) {
          setCollabData(response?.data["All Collaborations"]);
          setFilteredCollabData(response?.data["All Collaborations"]);
        }
        setIsLoading(false);
      });
    }
  }, [musicians, genre]);

  useEffect(() => {
    allCollaborationData().then((response) => {
      if (!response.error) {
        setCollabData(response?.data["All Collaborations"]);
        setFilteredCollabData(response?.data["All Collaborations"]);
      }
      setIsLoading(false);
    });
  }, []);

  const handleInputChange = (input) => {
    setFilteredCollabData(input);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Header>
        <HeaderTitle>
          {musicians
            ? `All Listed Collaborations for ${state}`
            : genre
            ? `All Listed Collaborations for ${state}`
            : "Listed Collaborations"}
        </HeaderTitle>
        {musicians || genre || pathname.includes("all") ? (
          ""
        ) : (
          <>
            {/* <SecondaryBtn
              handleClick={() => {
                setViewAllCollabs(!viewAllCollabs);
              }}
            >
              View All
              {!viewAllCollabs && (
                <i style={{ marginLeft: "0.5rem" }} className="fa-solid fa-arrow-down"></i>
              )}
              {viewAllCollabs && (
                <i style={{ marginLeft: "0.5rem" }} className="fa-solid fa-arrow-up"></i>
              )}
            </SecondaryBtn> */}
          </>
        )}
      </Header>
      <FilterContainer>
        <FilterCollab
          original={collabData}
          input={filteredCollabData}
          handleInputChange={handleInputChange}
          searchKeyWord={"title"}
          searchKeyWords={["title", "location"]}
        />
      </FilterContainer>
      <CollabortaionWrapper>
        {filteredCollabData?.length <= 5 || viewAllCollabs
          ? filteredCollabData?.map((item, index) => {
              return (
                <StyledCollabCard
                  key={item.pk}
                  item={item}
                  firstName={item?.requestingUser?.firstName}
                  lastName={item?.requestingUser?.lastName}
                  title={item?.title}
                  location={item?.location}
                  ActionButton={
                    <ActionButton
                      onClick={() => {
                        navigate(`/collaboration/view/${index}`, {
                          state: item,
                        });
                      }}
                    >
                      View Details
                    </ActionButton>
                  }
                />
              );
            })
          : filteredCollabData?.slice(0, 6)?.map((item, index) => {
              return (
                <StyledCollabCard
                  key={item.pk}
                  item={item}
                  firstName={item?.requestingUser?.firstName}
                  lastName={item?.requestingUser?.lastName}
                  title={item?.title}
                  location={item?.location}
                  ActionButton={
                    <ActionButton
                      onClick={() => {
                        navigate(`/collaboration/view/${index}`, {
                          state: item,
                        });
                      }}
                    >
                      View Details
                    </ActionButton>
                  }
                />
              );
            })}
      </CollabortaionWrapper>
    </Container>
  );
};
const Container = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const Header = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 240px) {
    flex-direction: column;
    margin-top: 3%;
  }
  @media (min-width: 640px) {
    flex-direction: row;
    margin-top: 1%;
  }
`;
const HeaderTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 14px;
  }
  @media (min-width: 640px) {
    font-size: 30px;
  }
`;
const FilterContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0px;
`;
const CollabortaionWrapper = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  @media (min-width: 240px) {
    margin-top: 6%;
  }
  @media (min-width: 1024px) {
    margin-top: 2%;
  }
`;
const StyledCollabCard = styled(CollabCard)`
  width: 100%;
  margin-bottom: 2%;
`;
const ActionButton = styled(MuzeButton)`
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  @media (min-width: 240px) {
    height: 7vh;
  }
  @media (min-width: 640px) {
    height: 5vh;
  }
  @media (min-width: 1024px) {
    height: 7vh;
  }
`;
export default AllCollaborations;
