// Libraries
import React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
// Components
import Loading from "./../loading/Loading";
import { PropTypes } from "prop-types";

const Map = ({ mapContainerClassName, location, zoom, children }) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: API_KEY,
    });

    if (!isLoaded) {
        return <Loading />;
    }
    return (
        <GoogleMap
            data-testid="loading-indicator"
            zoom={zoom}
            center={location}
            mapContainerClassName={mapContainerClassName}
        >
            {children}
        </GoogleMap>
    );
};

Map.propTypes = {
    mapContainerClassName: PropTypes.string,
    location: PropTypes.object,
    zoom: PropTypes.number,
    children: PropTypes.node.isRequired,
};
export default Map;
