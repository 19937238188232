import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./StudioPortfolio.css";
import CustomMap from "../../components/map/Map";
import Slots from "./Slots";
import Rooms from "./Rooms";
import Loading from "../../components/loading/Loading";
import { PropTypes } from "prop-types";

const StudioPortfolio = ({ details }) => {
    const [initialLocation, setInitialLocation] = useState({
        lat: details.locationLatitude,
        lng: details.locationLongitude,
    });
    const [isLoading, setIsLoading] = useState();
    const handleLoading = (value) => {
        setIsLoading(value);
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setInitialLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
        });
    }, []);
    return isLoading ? (
        <Loading className="mini" />
    ) : (
        <div className="main-container">
            <Text>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry&apos;s standard
                dummy text ever since the 1500s, when an unknown printer took a
                galley of type and scrambled it to make a type specimen book. It
                has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.{" "}
            </Text>
            <div className="middle-container">
                <div className="left">
                    <Text>What we have</Text>
                </div>
                <div className="right">
                    <Text>Find us here</Text>
                    <CustomMap
                        center={initialLocation}
                        zoom={5}
                        mapContainerClassName="map"
                    ></CustomMap>
                </div>
            </div>
            <div className="calendar-container">
                <Rooms />
                <Slots handleLoading={handleLoading} />
            </div>
        </div>
    );
};

StudioPortfolio.propTypes = {
    details: PropTypes.object,
};

const Text = styled.div`
    color: ${({ theme }) => theme.color};
    font-weight: 600;
    font-size: 20px;
    margin: 2% 0% 3% 0%;
`;

export default StudioPortfolio;
