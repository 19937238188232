import React, { useEffect, useState } from "react";
import { useGetFollowingMutation } from "../../../redux/services/user/userApi";
import ArtistCard from "../../../components/cards/ArtistCard";
import styled from "styled-components";
import Loading from "../../../components/loading/Loading";

const Following = () => {
  const [getFollowing] = useGetFollowingMutation();
  const [following, setFollowing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFollowing().then((res) => {
      setFollowing(res.data);
      setIsLoading(false);
    });
  }, []);
  return isLoading ? (
    <Loading className="mini" />
  ) : (
    <>
      {following.length ? (
        <ContentContainer>
          <CardContainer>
            {following.map((follow) => (
              <ArtistCard key={follow.user} artist={follow} id={follow.user.id} />
            ))}
          </CardContainer>
        </ContentContainer>
      ) : (
        <Container>
          <Text>No followings yet</Text>
        </Container>
      )}
    </>
  );
};

const CardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;
  /* overflow: auto; */
  gap: 25px;
  @media (max-width: 991px) {
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
`;
const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 20px;
`;

export default Following;
