import { createGlobalStyle } from "styled-components";

// creating global styles for the app
const GlobalStyles = createGlobalStyle`


/* assigning border box to root class, etc.. */
#root, body, html {
  box-sizing: border-box;
}
/* assinging full height and width of the window to the page */
html, body, #root {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}
/* configuring default styles for elements */
div, h1, h2, h3, h4, h5, h6, header, i, img, label, li, p, small, span, ul {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /*a line that is drawn around elements, OUTSIDE the borders*/
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
/* adding vertical scroll bar*/
body {
  /* show scroll bar */
  overflow-y: scroll;
  /* no horizontal scroll bar */
  overflow-x: hidden;
  font-family: ${() => {
    return document.dir === "ltr" ? "Outfit" : "Cairo";
  }}
}

/* inverting carousel arrow colors on theme toggle */
.carousel-control-next,
.carousel-control-prev  {
    filter: ${({ theme }) => (theme.name === "light" ? "invert(100%)" : "")} ;
    
}

/* removing search input config */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}


`;
export default GlobalStyles;
