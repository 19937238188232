// Libraries
import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";

const Switcher = ({ className, switchers, setSelected, selected }) => {
    const border = {
        borderImage:
            " linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%)",
        borderImageSlice: 1,
    };
    const handleSwitch = (event) => {
        setSelected((prevState) => {
            if (prevState !== parseInt(event.target.id)) {
                document.getElementById(prevState).style.borderImage = null;
                document.getElementById(event.target.id).style.borderImage =
                    border.borderImage;
                document.getElementById(
                    event.target.id
                ).style.borderImageSlice = border.borderImageSlice;
                return parseInt(event.target.id);
            } else {
                return prevState;
            }
        });
    };

    return (
        <SwitcherContainer className={className}>
            {switchers.map((switcher, index) => {
                return (
                    <SwitcherSection
                        key={switcher.name}
                        id={index}
                        onClick={handleSwitch}
                        style={selected === index ? { ...border } : null}
                    >
                        {switcher.name}
                    </SwitcherSection>
                );
            })}
        </SwitcherContainer>
    );
};

Switcher.propTypes = {
    switchers: PropTypes.array,
    className: PropTypes.string,
    setSelected: PropTypes.func,
    selected: PropTypes.number,
};
const SwitcherContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid white;
    margin-bottom: 2%;
`;
const SwitcherSection = styled.div`
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: ${({ theme }) => theme.color};
    border-bottom: 5px solid transparent;

    border-image-slice: 1;
    @media (min-width: 240px) {
        font-size: 14px;
    }
    @media (min-width: 640px) {
        font-size: 20px;
    }
`;
export default Switcher;
