import React from "react";
import styled from "styled-components";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>Welcome to Muze!</Title>
      <Paragraph>
        We&apos;re committed to protecting your privacy and ensuring the security of your personal
        information. This Privacy Policy outlines how we collect, use, and safeguard your data when
        you use our website muze-network.com and our services.
      </Paragraph>
      <SectionTitle>1. Information We Collect</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - When you create an account on Muze, we may collect personal information such as your
          name, email address, and profile details.
        </ParagraphSection>
      </Paragraph>
      <SectionTitle>2. How We Use Your Information</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - We use the information we collect to provide and improve our services, personalize your
          experience, and communicate with you about updates and promotions.
        </ParagraphSection>
      </Paragraph>
      <SectionTitle>3. Data Sharing and Disclosure</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - Your data may be shared in accordance with legal requirements or to protect our rights
          and the rights of others.
        </ParagraphSection>
      </Paragraph>
      <SectionTitle>4. Data Security</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - We take the security of your data seriously and employ industry-standard measures to
          protect against unauthorized access, alteration, disclosure, or destruction.
        </ParagraphSection>
      </Paragraph>
      <SectionTitle>5. Your Choices and Rights</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - You have the right to access, update, or delete your personal information at any time.
        </ParagraphSection>
      </Paragraph>
      <SectionTitle>6. Updates to This Policy</SectionTitle>
      <Paragraph>
        <ParagraphSection>
          - We may update this Privacy Policy from time to time to reflect changes in our practices
          or legal requirements. We encourage you to review this Policy periodically for any
          updates.
        </ParagraphSection>
      </Paragraph>
      <ParagraphSection style={{ marginTop: 30 }}>
        If you have any questions or concerns about our Privacy Policy or data practices, please
        contact us at <a href="mailto:info@muze-network.com">info@muze-network.com</a>
      </ParagraphSection>
    </Container>
  );
};

const Container = styled.div`
  margin: 20px 6%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  height: max-content;
  min-height: 600px;
`;

const Title = styled.div`
  min-width: fit-content;
  font-weight: 600;
  font-size: 24px;
  align-self: flex-start;
  color: ${({ theme }) => theme.color};
  border-image: linear-gradient(269.85deg, #c4739a 3.22%, #2a69a5 100.77%);
  border-image-slice: 1;
  border-width: 0 0 5px 0;
  border-style: solid;
  padding-bottom: 5px;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.color};
  margin-top: 20px;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 20px 0;
  color: ${({ theme }) => theme.color};
`;

const ParagraphSection = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color};
  @media (min-width: 240px) {
    font-size: 16px;
  }
  @media (min-width: 640px) {
    font-size: 18px;
  }
  a {
    background: linear-gradient(to right, #016299, #f77599);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }
`;

export default PrivacyPolicy;
