// Libraries
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// Services
import { history } from "../../App";
// Components

// Assets
import { IconsBlobUrl } from "../../services/BlobServices";
const background = `${IconsBlobUrl}404/404.jpg`;
const NotFound = () => {
    const { t } = useTranslation();
    return (
        <NotFoundContainer>
            <Title>404</Title>
            <Content>{t("pageNotFound")}</Content>
            <Redirect>
                <RedirectContent>
                    {t("startFrom")} &nbsp;
                    <RedirectAnchor
                        onClick={() => {
                            history.push("/");
                        }}
                    >
                        {t("homePage")}
                    </RedirectAnchor>
                </RedirectContent>
            </Redirect>
        </NotFoundContainer>
    );
};

const NotFoundContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 72px;
    line-height: 91px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    mix-blend-mode: normal;
`;
const Content = styled.div`
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;

    text-align: center;

    color: #ffffff;

    mix-blend-mode: normal;
`;
const Redirect = styled.div`
    margin-top: 2%;
    width: fit-content;
`;
const RedirectContent = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
`;
const RedirectAnchor = styled.div`
    text-decoration: underline;
    cursor: pointer;
`;
export default NotFound;
