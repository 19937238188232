import React from "react";
import styled from "styled-components";
import Messages from "./components/Messages";
import Panel from "./components/Panel";

const Messaging = () => {
  return (
    <Container>
      <Messages />
      <Panel />
    </Container>
  );
};

export default Messaging;

const Container = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 65px;
  flex-grow: 1;
  /* targeting mobile devices */
  @media (min-width: 280px) {
    width: 100%;
  }
  /* targeting tablets and ipad */
  @media (min-width: 821px) {
    width: 80%;
  }
  gap: 13px;
  margin-left: 120px;
`;
