// Libraries
import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const genreCardsImages = [
  `./icons/collaborations/matthew-ball-Pr1e8z1ZocI-unsplash.jpg`,
  `./icons/collaborations/guilherme-stecanella-O7tDSkEA_t4-unsplash.jpg`,
  `./icons/collaborations/sebastien-chiron-0LYMnySgTK4-unsplash.jpg`,
  `./icons/collaborations/matthew-ball-Pr1e8z1ZocI-unsplash.jpg`,
];

const genreBackgroundColors = ["#e8125b ", "#55a891 ", " #f038a5", "#509bf5"];

const GenreCard = ({ genre, className, genreImage, genreBackgroundColor, onClick }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Container
      className={className}
      onClick={onClick}
      genreBackgroundColor={genreBackgroundColors[Math.floor(Math.random() * 4)]}
    >
      <GenreTitle>{t(genre)}</GenreTitle>
      <GenreImg
        as={motion.img}
        animate={!isTabletOrMobile ? { rotate: 30 } : {}}
        whileHover={!isTabletOrMobile ? { rotate: 0, scale: 1.1 } : {}}
        src={genreCardsImages[Math.floor(Math.random() * 4)]}
      ></GenreImg>
    </Container>
  );
};
GenreCard.propTypes = {
  genre: PropTypes.string,
  genreBackgroundColor: PropTypes.string,
  className: PropTypes.string,
  genreImage: PropTypes.string,
  onClick: PropTypes.func,
};

const Container = styled.div`
  background: ${(props) => props.genreBackgroundColor};
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  height: 8rem;
  padding: 1rem;
  overflow: hidden;
  margin-bottom: 1rem;
`;
const GenreTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin: 2% 0 0 5%;
`;
const GenreImg = styled.img`
  height: 12rem;
  width: 12rem;
  align-self: end;
  position: relative;
  left: 10%;
  top: -35%;

  src: ${(props) => props.src};
  transform-origin: bottom right;
  @media (min-width: 240px) {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
`;
export default GenreCard;
