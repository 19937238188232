// Libraries
import React from "react";
import styled from "styled-components";

const PaymentSettings = () => {
    return (
        <Container>
            <Header>
                <HeaderTitle>Activity</HeaderTitle>
                <SecondaryButton>See all</SecondaryButton>
            </Header>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5%;
`;
const HeaderTitle = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: ${({ theme }) => theme.color};
`;
const SecondaryButton = styled.div`
    font-weight: 500;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.3);
`;
export default PaymentSettings;
