// Libraries
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import { motion } from "framer-motion";
import { isEmail } from "validator";
import { toast, ToastContainer } from "react-toastify";
// Services
import { useSigninMutation } from "../../redux/services/user/userApi";
import { setIsUserSignUpDone, setUser } from "../../redux/services/user/userSlice";
// Stylings
import "react-toastify/dist/ReactToastify.css";
// Components
import BoxContainer from "./../../components/boxContainer/BoxContainer";
import LineWrapper from "./../../components/lineWrapper/LineWrapper";
import MuzeButton from "./../../components/muzeBtn/MuzeButton";
import MuzeInput from "./../../components/input/MuzeInput";
import { Eye, PasswordContainer } from "./../signup/screens/accountInfo/AccountInfo";
import Loading from "../../components/loading/Loading";
// import { useGoogleLogin } from "@react-oauth/google";
// Assets

// const FBLogo = `/icons/common/FB-Logo.svg`;
// const GoogleLogo = `/icons/common/Google-Logo.svg`;
const Signin = () => {
  // t function for translating
  const { t } = useTranslation();
  // boolean for showing password field
  const [showPassword, setShowPassword] = useState(false);
  // form errors
  const [errors, setErrors] = useState([]);
  // For loading effect
  const [isLoading, setIsLoading] = useState(false);
  // initializing the form input
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // navigate hook for routing
  const navigate = useNavigate();
  const location = useLocation();
  const isActivated = new URLSearchParams(location.search).get("activated");
  useEffect(() => {
    console.log(isActivated);
    if (isActivated) {
      // Perform any activation-related actions here
      toast.success("Account Is Activated");
    }
  }, [isActivated, t]);
  // dispatch hook for dispatching actions in the redux store
  const dispatch = useDispatch();
  // login user action
  const [signin, { data: signinData, isSuccess: isLoginSuccess }] = useSigninMutation();

  // function to check if there are erros in the form and show them in the ui
  const assignErrors = () => {
    if (!errors.includes("email") && !isEmail(formData.email)) {
      errors.push("email");
    }
    if (!errors.includes("password") && formData.password.length < 8) {
      errors.push("password");
    }

    errors.map((field) => {
      return (document.getElementById(field).style.borderColor = "red");
    });
  };
  // function to check if the previous erros are resolved and reflecting the result in the ui
  const removeErrors = () => {
    if (errors.includes("email") && isEmail(formData.email)) {
      errors.splice(errors.indexOf("email"), 1);
      document.getElementById("email").style.borderColor = "white";
    }
    if (errors.includes("password") && formData.password.length > 8) {
      errors.splice(errors.indexOf("password"), 1);
      document.getElementById("password").style.borderColor = "white";
    }
  };
  // submiting form input
  const handleSubmit = async (event) => {
    // preventing default form submit refresh
    event.preventDefault();
    setIsLoading(true);
    // validating the email value
    assignErrors();
    if (!errors.length) {
      // send request to backendApi
      await signin(formData).then((res) => {
        if (!res.data.Error) {
          setIsLoading(false);
          dispatch(setUser(res.data));
          dispatch(setIsUserSignUpDone(res.data.signUpDone));
        }
      });
    } else {
      // on error show a toast displaying the error
      const emailError = errors.find((error) => {
        return error === "email";
      });
      const passwordError = errors.find((error) => {
        return error === "password";
      });
      if (emailError && passwordError) {
        toast.error(t("emailPasswordError"), {
          theme: document.documentElement.getAttribute("data-theme"),
        });
      } else if (emailError) {
        toast.error(t("emailError"), {
          theme: document.documentElement.getAttribute("data-theme"),
        });
      } else if (passwordError) {
        toast.error(t("passwordError"), {
          theme: document.documentElement.getAttribute("data-theme"),
        });
      }
    }
    setIsLoading(false);
  };
  //   const handleGoogleLogin = useGoogleLogin({
  //     onSuccess: (tokenResponse) => console.log(tokenResponse),
  //   });
  // initializing the errors array when mounting the component
  useEffect(() => {
    setErrors([]);
  }, []);
  // on each change in the form fields, we check if errors are resolved
  useEffect(() => {
    removeErrors();
  }, [formData]);
  useEffect(() => {
    // on login success  dispatch the action and navigate to landing page
    if (isLoginSuccess && signinData.accessToken?.length) {
      dispatch(setUser(signinData));
      toast.success("Logged in Successfully");
      navigate("/");
    } else if (isLoginSuccess && signinData.Error) {
      toast.error("User is not activated");
    }
  }, [isLoginSuccess]);
  // handle form input change
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Parent>
        <StyledBoxContainer>
          <Title>Get Into Muze</Title>
          <AnchorContainer>
            <SubTitle>{t("dontHaveAnAccount?")}</SubTitle>
            <Anchor onClick={() => navigate("/signup")}>{t("signUp")}</Anchor>
          </AnchorContainer>
          <InputContainer>
            <MuzeInput
              type={"email"}
              id="email"
              name="email"
              autoComplete="off"
              placeholder={t("email")}
              onChange={handleChange}
              isDark={true}
            />
            <PasswordContainer>
              <MuzeInput
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder={t("password")}
                name="password"
                onChange={handleChange}
                isDark={true}
              />
              <Eye onClick={() => setShowPassword(!showPassword)} />
            </PasswordContainer>
            <Spacer />
            <Button name="Login" type={"submit"} onClick={handleSubmit}>
              {t("signIn")}
            </Button>
          </InputContainer>
          <LineWrapper lineStyle="line-style">
            <Text>{/* {t("orSigninWith")} */}</Text>
          </LineWrapper>
          {/* <IconsContainer>
                        <IconWrapper
                            className={"signin-icon-wrapper"}
                            as={motion.div}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={handleGoogleLogin}
                        >
                            <img src={GoogleLogo} alt="Google" />
                        </IconWrapper>
                        <IconWrapper
                            className={"signin-icon-wrapper"}
                            as={motion.div}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <img src={FBLogo} alt="Facebook" />
                        </IconWrapper>
                    </IconsContainer> */}
        </StyledBoxContainer>
      </Parent>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        limit={3}
        rtl={document.dir === "rtl"}
        pauseOnFocusLoss
        draggable={true}
        pauseOnHover
        toastStyle={{ backgroundColor: "black" }}
      />
    </Container>
  );
};

const Parent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const Spacer = styled.div`
  height: 10px;
`;

export const Container = styled.form`
  max-width: 100vw;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-image: url("./icons/signin/signin-background.jpg");

  padding: 160px 0 60px;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background-image: url("./icons/signin/singer.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100px;
    left: 47%;
    z-index: 1;
    @media (max-width: 1025px) {
      content: none;
    }
  }
`;
export const StyledBoxContainer = styled(BoxContainer)`
  margin-left: 4vw;
  margin-right: auto;
  padding: 20px 10px;
  min-height: 650px;
  @media (min-width: 280px) {
    margin: 0;
    width: 90%;
  }
  @media (min-width: 641px) {
    width: 80%;
  }
  @media (min-width: 1024px) {
    width: 45%;
    margin-left: 4vw;
    margin-right: auto;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  line-height: 66px;
  text-align: center;
  color: #ffffff;
  @media (min-width: 280px) {
    font-size: 32px;
  }
  @media (min-width: 641px) {
    font-size: 42px;
  }
  @media (min-width: 1024px) {
    font-size: 52px;
  }
`;
export const AnchorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 20px;
`;
export const SubTitle = styled.h6`
  align-self: center;
  color: ${({ theme }) => theme.color};
`;
export const Anchor = styled.a`
  color: #1d69a7;
  cursor: pointer;
  padding: 2px;
  margin-left: 0.5vw;
  text-decoration: underline;
`;
export const InputContainer = styled.div`
  margin-top: 3vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled(MuzeButton)`
  padding: 10px 0;
  margin-top: 1rem;
  @media (min-width: 280px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 40%;
  }
`;
export const Text = styled.div`
  color: ${({ theme }) => theme.color};
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 2vh;
  @media (min-width: 280px) {
    height: 8%;
  }
  @media (min-width: 641px) {
    height: 4vh;
  }
  @media (min-width: 1024px) {
    height: 8%;
  }
  gap: 26px;
`;
export const IconWrapper = styled(MuzeButton)`
  background: #ffffff;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
`;
export default Signin;
