import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "./cookieManager";
import { useDispatch } from "react-redux";
import { signout } from "../redux/services/user/userSlice";

// Protected Route Wrapper for routes that needs authentication to access
const ProtectedRoute = ({ children }) => {
  // navigate hook for routing
  const navigate = useNavigate();
  // dispatch hook for dispatching actions to the redux store
  const dispatch = useDispatch();
  useEffect(() => {
    // if user not authorized log the session out
    if (!getAuthToken()) {
      dispatch(signout());
      navigate("/signin", { replace: true });
    }
  }, []);
  return children;
};
// adding children for props to accept
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ProtectedRoute;
