import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";

const LineWrapper = ({ children, theStyle }) => {
  return (
    <StyledLineWrapper data-testid="line-wrapper" style={theStyle}>
      <LeftLine data-testid="left-line"></LeftLine>
      {children}
      <RightLine data-testid="right-line"></RightLine>
    </StyledLineWrapper>
  );
};

LineWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  lineStyle: PropTypes.string,
  theStyle: PropTypes.object,
};

const StyledLineWrapper = styled.div`
  color: ${({ theme }) => theme.color};
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* targeting mobile devices */
  @media (min-width: 280px) {
    margin-top: 40px;
  }
`;
const LeftLine = styled.div`
  width: 30%;
  height: 1.2vh;
  border-bottom: 3px solid white;
  margin: 0 1vw 8px 0;
  border-radius: 1px;
`;
const RightLine = styled(LeftLine)`
  margin: 0 0 8px 1vw;
`;
export default LineWrapper;
