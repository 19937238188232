import React from 'react';
import { components } from 'react-select';
import { PropTypes } from 'prop-types';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

Option.propTypes = {
  props: PropTypes.object,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
};

export default Option;
