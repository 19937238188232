import React from "react";
import { useSelector } from "react-redux";
import GenreCard from "../../../components/cards/GenreCard";
import styled from "styled-components";

const Genres = () => {
  const searchedUser = useSelector((state) => state.user.searchedUser);

  const img =
    "https://www.befunky.com/images/prismic/1f427434-7ca0-46b2-b5d1-7d31843859b6_funky-focus-red-flower-field-after.jpeg?auto=avif,webp&format=jpg&width=863";
  return (
    <div>
      {searchedUser ? (
        <Container>
          {searchedUser.genres?.map((genre) => (
            <GenreCard key={genre.id} genre={genre.name} genreImage={img} />
          ))}
        </Container>
      ) : (
        <Text>No Genres yet</Text>
      )}
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 20px;
`;
export default Genres;
