// Libraries
import React from "react";
import { PropTypes } from "prop-types";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGetSearchedUserDetailsMutation } from "../../redux/services/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setSearchedUserDetails } from "../../redux/services/user/userSlice";
import ReactTimeAgo from "react-time-ago";

const PostCard = ({ img, userId, cover, className, onClickHandler, name, isProfile, date }) => {
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getSearchedUser] = useGetSearchedUserDetailsMutation();
  const handleClick = () => {
    getSearchedUser({
      profileId: userId,
    }).then((res) => {
      if (userId !== user?.pk) {
        dispatch(setSearchedUserDetails(res.data["Profile Details"]));
        navigate("/users");
      } else {
        navigate("/profile");
      }
    });
  };

  return (
    <StyledCardContainer>
      {!isProfile && (
        <CardHeader>
          <CardLogoContainer onClick={() => handleClick()}>
            <CardLogo src={img} />
          </CardLogoContainer>
          <CardInfoContainer>
            <Text>{name}</Text>
            <Timespan>
              <ReactTimeAgo date={date} locale="en-US" />
            </Timespan>
          </CardInfoContainer>
        </CardHeader>
      )}
      <StyledCard
        style={{
          backgroundImage: `url(${cover})`,
          direction: "ltr",
        }}
        className={className}
        whileHover={{ scale: 1.05 }}
        onClick={onClickHandler}
        id="postCard__link"
      />
    </StyledCardContainer>
  );
};

PostCard.propTypes = {
  img: PropTypes.string,
  postId: PropTypes.number,
  userId: PropTypes.number,
  cover: PropTypes.string,
  className: PropTypes.string,
  onClickHandler: PropTypes.func,
  onClickLike: PropTypes.func,
  name: PropTypes.string,
  isProfile: PropTypes.bool,
  date: PropTypes.any,
};
const StyledCardContainer = styled.div`
  width: 31%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Timespan = styled.p`
  font-size: 20px;
  color: #4d4d4d;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  width: 100%;
  transform: translate(-20px, -5px);
`;
const CardLogoContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const CardLogo = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
`;
const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.color};
  font-weight: 600;
  font-size: 24px;
`;
const StyledCard = styled(motion.div)`
  height: 500px;
  padding: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  border: 8px solid #202020;
  border-radius: 25px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  transition: all ease 200ms;
`;
export default PostCard;
