import React, { useState } from "react";
import styled from "styled-components";
import icon from "../../../../../icons/Vector.png";
import PropTypes from "prop-types";

const MessagesChat = ({ isActive, onClick, chat }) => {
  const [isOnline] = useState(false);

  // Status Socket
  // useEffect(() => {
  //     const socket = new WebSocket(
  //         "wss://muzenetwork.azurewebsites.net/ws/status"
  //     );

  //     socket.addEventListener("open", (event) => {
  //         console.log("WebSocket connection opened:", event);
  //     });

  //     socket.addEventListener("status", (event) => {
  //         const data = JSON.parse(event.data);
  //         if (data.type === "status") {
  //             setIsOnline(data.status);
  //         }
  //     });

  //     socket.addEventListener("error", (error) => {
  //         console.error("WebSocket encountered an error:", error);
  //     });

  //     return () => {
  //         socket.close();
  //     };
  // }, []);
  const defaultProfile =
    "https://muzefirststorage.blob.core.windows.net/usercontainer/img/img-1687160459492";
  return (
    <Box isActive={isActive} onClick={onClick}>
      <Avatar image={chat?.userImageUrl || defaultProfile} isOnline={isOnline} />
      <InfoBox>
        <Title firstName={chat?.firstName} lastName={chat?.lastName} />
        <Time />
        <Icon />
      </InfoBox>
    </Box>
  );
};

// Start Mini Components
const Avatar = ({ image, isOnline }) => {
  return (
    <AvatarBox>
      <img
        src={image}
        alt="avatar"
        style={{
          width: 35,
          height: 35,
          borderRadius: 50,
        }}
      />
      {isOnline ? <OnlineDot /> : <OfflineDot />}
    </AvatarBox>
  );
};

const Title = ({ firstName, lastName }) => {
  return (
    <TitleBox>
      <TitleHeading>
        {firstName} {lastName}
      </TitleHeading>
      <TitleText></TitleText>
    </TitleBox>
  );
};
const Time = () => {
  return (
    <TimeBox>
      <TimeHeading></TimeHeading>
      <TimeText></TimeText>
    </TimeBox>
  );
};
const Icon = () => {
  return (
    <IconBox>
      <img src={icon} />
    </IconBox>
  );
};
// End Mini Components

// Props Validation
MessagesChat.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  chat: PropTypes.object,
};

Avatar.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
};

Title.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default MessagesChat;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #0d0d0d;
  filter: ${({ isActive }) => (isActive ? "drop-shadow(0px 0px 8px #b7b9ff)" : "none")};
  cursor: pointer;
`;
const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 12px;
`;

const AvatarBox = styled.div`
    padding: 5px;
    padding-bottom: 0;
    border-radius: 50%;
    // border: 1px solid #b7b9ff;
    width: 35px
    height: 35px;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
      background: linear-gradient(332deg, rgba(230, 230, 242, 0), rgba(225, 225, 240, 0.41), rgb(207, 207, 230));
      width: 40px;
      height: 40px;
      z-index: -1;
      border-radius: 50%;
    }
`;

const OnlineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #16e933;
  position: absolute;
  top: 0px;
  right: -2px;
  box-shadow: 0px 4px 10px 0px #17e933;
`;

const OfflineDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc; // Choose a color for offline state
  position: absolute;
  top: 0px;
  right: -2px;
  box-shadow: 0px 4px 10px 0px #ccc; // Choose a color for offline state
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const TitleHeading = styled.h1`
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #c8c8c8;
`;

const TitleText = styled.h1`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #a1a1a1;
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  flex: 1;
  margin-left: 25px;
`;

const TimeHeading = styled.h1`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
`;

const TimeText = styled.h1`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
