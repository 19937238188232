// Libraries
import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import FileDropBox from "./FileDropBox";
import CoverDropBox from "./CoverDropBox";
// Components

const DropBox = ({ setFile, setCover }) => {
    return (
        <Container>
            <FileDropBox setFile={setFile} data-testid="file-dropbox" />
            <CoverDropBox setCover={setCover} data-testid="cover-dropbox" />
        </Container>
    );
};

DropBox.propTypes = {
    setFile: PropTypes.func,
    setCover: PropTypes.func,
};
const Container = styled.div`
    height: 60vh;
    display: flex;
    align-items: start;
    justify-content: space-around;
    background: #0d0d0d;
    border: 1px dashed #454545;
    border-radius: 13px;
`;

export default DropBox;
