import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signout, tokenrefresh } from "./services/user/userSlice";
import { getAuthToken, getRefreshToken } from "../services/cookieManager";
import { history } from "../App";

// configuring the baseQuery options and headers

export const baseUrlForCollab = "https:// ";
const baseQuery = fetchBaseQuery({
  // Production
  baseUrl: "https://muzenetworkproduction.azurewebsites.net/",
  //   Development
  // baseUrl: "https://muzenetworktest.azurewebsites.net/",
  prepareHeaders: async (headers) => {
    const accessToken = await getAuthToken();
    if (await accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

// configuring the refresh-token logic
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // try the request
  let result = await baseQuery(args, api, extraOptions);
  // if request failed with unauthorized error
  if (result.error && result.error.status === 401) {
    if (getAuthToken()) {
      // try to refresh token
      const refreshResult = await baseQuery(
        {
          url: "/auth/api/token/refresh/",
          method: "post",
          body: { refresh: getRefreshToken() },
        },
        api,
        extraOptions
      );
      // if token refreshed
      if (refreshResult.data) {
        // store the new token
        api.dispatch(
          tokenrefresh({
            accessToken: refreshResult.data.access,
            refreshToken: refreshResult.data.refresh,
          })
        );
        // retry the initial request
        result = await baseQuery(args, api, extraOptions);
      } else {
        // else end the current user session
        api.dispatch(signout());
        history.replace("/");
      }
    }
  }
  return result;
};

export default baseQueryWithReauth;
